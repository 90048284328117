export const forceActive = (toMatchPathname, match, { pathname }) => {
  if (match) return true;
  return toMatchPathname === pathname;
};

export const routesPath = {
  dashboard: '/dashboard',
  contacts: '/contacts',
  contactAdd: '/contacts/add',
  customerPayment: '/paymentsmade',
  customerPaymentAdd: '/paymentsmade/add',
  items: '/items',
  itemAdd: '/items/add',
  itemDetail: '/items/:id',
  itemAdjustment: '/item-adjustments',
  itemAdjustmentAdd: '/item-adjustments/add',
  itemGroups: '/item-groups',
  itemGroupsAdd: '/item-groups/add',
  returns: '/salesreturns',
  returnsAdd: '/salesreturns/add',
  creditNote: '/creditnotes',
  creditNoteAdd: '/creditnotes/add',
  salesOrders: '/salesorders',
  salesOrderAdd: '/salesorders/add',
  packages: '/packages',
  packageAdd: '/packages/add',
  invoices: '/invoices',
  invoiceAdd: '/invoices/add',
  purchaseOrders: '/purchaseorders',
  purchaseOrderAdd: '/purchaseorders/add',
  bills: '/bills',
  billAdd: '/bills/add',
  salesreturns: '/salesreturns',
  myAccount: '/myaccount',
  packagesplan: '/packagesplan',
  manageOrg: '/oc/organization/choose',
  //returnreceives: '/returnreceives',
  creditnotes: '/creditnotes',
  reportsDash: {
    path: '/reports',
  },
  reports: [
    {
      title: 'Overview',
      path: '/reports',
    },
    {
      title: 'Inventory',
      isSubtitle: true,
    },
    {
      title: 'Inventory Details',
      path: '/reports/inventorydetails',
    },
    {
      title: 'Inventory Valuation Summary',
      path: '/reports/inventoryvaluation',
    },
    {
      title: 'FIFO Cost Lot Tracking',
      path: '/reports/fifo',
    },
    {
      title: 'Warehouse Report',
      path: '/reports/warehouse',
    },
    {
      title: 'Committed Stock Details',
      path: '/reports/committedstock',
    },
    {
      title: 'Product Sales Report',
      path: '/reports/productsales',
    },
    {
      title: 'Active Purchase Orders Report',
      path: '/reports/activepurchaseorders',
    },
    {
      title: 'Sales',
      isSubtitle: true,
    },
    {
      title: 'Sales Order Report',
      path: '/reports/salesorder',
    },
    {
      title: 'Invoice Report',
      path: '/reports/invoice',
    },
    {
      title: 'Payments Received',
      path: '/reports/paymentsreceived',
    },
    {
      title: 'Packing Report',
      path: '/reports/packing',
    },
    {
      title: 'Sales Return Report',
      path: '/reports/salesreturn',
    },
    {
      title: 'Purchase',
      isSubtitle: true,
    },
    {
      title: 'Purchase Order Report',
      path: '/reports/purchaseorder',
    },
    {
      title: 'Receive Report',
      path: '/reports/receive',
    },
    {
      title: 'Bill Report',
      path: '/reports/bill',
    },
    {
      title: 'Payments Made Report',
      path: '/reports/paymentsmade',
    },
    {
      title: 'Sales by Customer',
      path: '/reports/customer',
    },
    {
      title: 'Sales by Item',
      path: '/reports/salesbyitem',
    },
    {
      title: 'Sales by Sales Person',
      path: '/reports/salesbysalesperson',
    },
    {
      title: 'Order fulfillment by Item',
      path: '/reports/orderfulfillmentbyitem',
    },
  ],
  organization: '/useraccess',
  organizationAdd: '/organization/add',
  settings: '/settings',
  tax: '/taxes',
  taxAdd: '/taxes/add',
  taxEdit: '/taxes/edit',
  deliveryMethod: '/deliverymethods',
  deliveryMethodAdd: '/deliverymethods/add',
  deliveryMethodEdit: '/taxes/edit',
  warehouses: '/warehouses',
  warehouseAdd: '/warehouses/add',
  warehouseEdit: '/warehouse/edit',
  inventory: '/accounting/account',
  inventoryAdd: '/accounting/account/new',
  currencies: '/currencies',
  currencyAdd: '/currencies/add',
  currencyEdit: '/currencies/update',
  preference: '/preference',
  numberPreferences: '/numberPreferences',
  integrations: '/integrations',
  storeDetails: '/integrations/store',
  storeDetailsHistory: '/integrations/sync-history',
  paymentMethod: '/paymentmethods',
  chartOfAccount: '/setting/account',
};

export const hasSubtring = (paths, path) => {
  for (let index in paths) {
    if (path.includes(paths[index])) return true;
  }

  return false;
};
export const hasSubtringReports = (paths, path) => {
  for (let index in paths) {
    if (path.includes(paths[index].path)) return true;
  }

  return false;
};
