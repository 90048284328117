import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, ErrorMessage } from 'formik';
import {
  MdCheck,
  MdArrowForward,
  MdArrowBack,
  MdClose as CloseIcon,
} from '../../../Common/Icons';

import {
  handleCity,
  handleStreet,
  handlePhone,
  imagePathS3,
  restRequest,
  getObjFromLS,
  setObjInLS,
  checkError,
  confirmationAlert,
} from '../../../Helpers';
import {
  required,
  ValidateRequired,
  ValidateName,
  ValidateMax50,
  ValidateMax20,
  ValidateMax55,
  ValidateMax60,
  ValidateMax100,
  ValidateMax200,
  ValidateName45,
  ValidateWebsite,
  ValidateMax1000,
} from '../../../Helpers/Validator';
import { getErrorByKey } from '../FormHelper';
import {
  facebook as facebookUrl,
  twitter as twitterUrl,
  instagram as instagramUrl,
  SALUTATIONS,
} from '../../../Constants';
import { Field, Form, ErrorFocus, FormSection, FormAction } from '../';
import FileUploader from '../../FileUploader/FileUploader';
import EmailInfo from '../../../Assets/General/EmailInfo';
import { validateContactForm } from '../../../Helpers/FormValidator';
import { CustomModal, ImageCropper, ImagePreview } from '../../../Components';
import { MdHelpOutline } from '../../../Common/Icons';
var valid = false;
const formEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstName: required(ValidateName45),
    lastName: ValidateName45,
    displayName: required(ValidateName),
    companyName: ValidateMax200,
    website: ValidateWebsite.nullable(),
    currencyID: ValidateRequired,
    // email: ValidateEmail.nullable(),
    // email: ValidateContactEmail.nullable(),
    department: ValidateMax50.nullable(),
    designation: ValidateMax50.nullable(),
    facebook: ValidateMax55.nullable(),
    twitter: ValidateMax55.nullable(),
    instagram: ValidateMax55.nullable(),
    skype: ValidateMax55.nullable(),
    workPhone: ValidateMax20.nullable(),
    workPhone_secondary: ValidateMax20.nullable(),
    notes: ValidateMax1000.nullable(),
    mobile: ValidateMax20.nullable(),
    mobile_secondary: ValidateMax20.nullable(),
    license: ValidateMax60.nullable(),

    billingAddress: Yup.object().shape({
      attention: ValidateMax100.nullable(),
      street: ValidateMax200.nullable(),
      city: ValidateMax100.nullable(),
      state: ValidateMax100.nullable(),
      phone: ValidateMax20.nullable(),
      zipCode: ValidateMax50.nullable(),
      fax: ValidateMax20.nullable(),
    }),
    shippingAddress: Yup.object().shape({
      attention: ValidateMax100.nullable(),
      street: ValidateMax200.nullable(),
      city: ValidateMax100.nullable(),
      state: ValidateMax100.nullable(),
      phone: ValidateMax20.nullable(),
      zipCode: ValidateMax50.nullable(),
      fax: ValidateMax20.nullable(),
    }),
  }),
  mapPropsToValues: ({ contact }) => ({
    ...contact,
    createDisplayName: '',
    showImageCropModal: false,
    displayName: contact?.displayNameEdit?.id,
    isContactImageDeleted: false,
  }),
  mapPropsToStatus: ({ status }) => ({
    ...status,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const ContactForm = (props) => {
  const {
    values,
    updateTab,
    tab,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    // custom props
    inModal,
    close,
    history,
    populateCountryStates,
    errors,
    submitCount,
    // touched,
    type,
    handlePrompt = () => {},
    handleUpdate = () => {},
    updateoverAll,
  } = props;
  const [staticVal, setStaticValues] = useState({});
  const [names, setNames] = useState({});
  const [confirmSaceEeryWhere, setConfirmsaveEveryWhere] = useState(false);

  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, tab, staticVal]);

  const handleAlert = () => {
    let { checkout } = validateContactForm(
      {
        ...values,
        // tab,
        type,
        ...(type === 'edit' && {
          email: (values?.email?.length > 0 && email[0] != null) || '',
        }),
      },
      { ...staticVal, displayNames: names }
    );
    if (!checkout && !inModal) {
      props.setPrompt();
    } else if (!checkout && inModal) {
      handlePrompt();
    } else {
      if (inModal) handlePrompt(false);
      props.setPrompt(false);
    }
  };

  useEffect(() => {
    setStaticValues({
      ...values,
      // tab,
      type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    billingAddress,
    displayName,
    companyName,
    firstName,
    lastName,
    salutation,
    // displayNames,
    email,
    dupDisplayNames,
    displayNameForEdit,
    createDisplayName,
  } = values;

  const changePage = (e) => {
    let bilAd = e.billingAddress || {};
    let shipAd = e.shippingAddress || {};
    if (e.twitter || e.skype || e.notes || e.instagram || e.facebook) {
      return 3;
    } else if (
      bilAd.attention ||
      bilAd.city ||
      bilAd.fax ||
      bilAd.phone ||
      shipAd.attention ||
      shipAd.city ||
      shipAd.fax ||
      shipAd.phone
    ) {
      return 2;
    } else if (
      e.companyName ||
      e.displayName ||
      e.email ||
      e.firstName ||
      e.lastName ||
      e.mobile ||
      e.mobile_secondary ||
      e.website ||
      e.workPhone ||
      e.workPhone_secondary
    ) {
      return 1;
    }
  };

  useEffect(() => {
    updateTab(changePage(errors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);

  useEffect(() => {
    let contactNamesArr = [];
    // if (type === 'edit') {
    //   contactNamesArr = [...displayNameForEdit];
    // } else {
    contactNamesArr = dupDisplayNames ? [...dupDisplayNames] : [];
    // }
    if (salutation)
      contactNamesArr.push({
        id: contactNamesArr.length + 1,
        label: `${salutation} ${firstName} ${lastName}`,
      });

    if (firstName)
      contactNamesArr.push({
        id: contactNamesArr.length + 1,
        label: `${firstName} ${lastName}`,
      });

    if (lastName)
      contactNamesArr.push({
        id: contactNamesArr.length + 1,
        label: `${lastName}${firstName ? ',' : ''} ${firstName}`,
      });
    if (companyName) {
      contactNamesArr.push({
        id: contactNamesArr.length + 1,
        label: `${companyName}`,
      });
    }

    if (createDisplayName !== '') {
      contactNamesArr.push({
        id: contactNamesArr.length + 1,
        label: createDisplayName,
      });
      setFieldValue(
        'displayName',
        contactNamesArr.find((dn) => dn.label === createDisplayName).id
      );
    }

    //Following code removes duplicate from array of objects
    let result = contactNamesArr.reduce((unique, o) => {
      if (!unique.some((obj) => obj.label === o.label)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setFieldValue('displayNames', result);
    setNames(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    salutation,
    displayName,
    companyName,
    firstName,
    lastName,
    createDisplayName,
  ]);

  useEffect(() => {
    type === 'edit' && changeEmail(email, setFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTotalValueForZero = () => {
    return (
      <CustomModal
        style={{ marginTop: '5%' }}
        showModal={confirmSaceEeryWhere}
        title="Confirmation"
        renderActions={false}
        onClose={() => setConfirmsaveEveryWhere(false)}
        size="small"
      >
        <div className="d-flex confirmation-desc">
          <div className="confirmation-inner d-flex">
            <label className="checkbox--option-label">
              <input
                onChange={(event) => handleUpdate(event.target.checked)}
                checked={updateoverAll}
                type="checkbox"
                id="Click_checkbox_SameAsBilling"
              />
              <span>
                <MdCheck />
              </span>
              <p>Do you want to update below fields for previous records? </p>
            </label>
          </div>
        </div>
        <div className="confirmation-block">
          <ul className="confirmation-listing">
            <li>
              <b> Display Name </b>
            </li>
          </ul>
          <div
            style={{
              color: 'grey ',
              marginTop: '15px',
            }}
            className="confirmation-desc"
          >
            *{' '}
            <u>
              <i>This will effect only un-processed records.</i>
            </u>
          </div>
        </div>

        <br />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <button
            type="button"
            className="action-buttons--cancel cancel-btn float-left mr_3"
            onClick={() => setConfirmsaveEveryWhere(false)}
          >
            {' '}
            Cancel{' '}
          </button>
          {/* &nbsp; */}
          <button
            type="button"
            className="action-buttons--submit next-btn"
            onClick={() => {
              handleUpdate(updateoverAll);
              setConfirmsaveEveryWhere(false);
              handleSubmit();
            }}
          >
            Save
          </button>
        </div>
      </CustomModal>
    );
  };
  return (
    <>
      {checkTotalValueForZero()}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          type === 'edit' &&
          Object.entries(errors).length === 0 &&
          values.contactTypeId === 2
            ? setConfirmsaveEveryWhere(true)
            : handleSubmit();
        }}
        className="contact-form"
        type={inModal && 'modal'}
      >
        {tab === 1 && (
          <FormSection title="Basic Info">{renderBasicInfo(props)}</FormSection>
        )}
        {tab === 2 && (
          <Fragment>
            <FormSection title="Billing Address">
              {renderBillingAddress(props)}
            </FormSection>
            <FormSection
              title="Shipping Address"
              className="margin-top-lg"
              rightContent={() => (
                <label className="checkbox--option-label">
                  <input
                    onChange={(event) =>
                      sameAsBilling(
                        event,
                        billingAddress,
                        setFieldValue,
                        populateCountryStates
                      )
                    }
                    type="checkbox"
                    id="Click_checkbox_SameAsBilling"
                  />
                  <span>
                    <MdCheck />
                  </span>
                  Same as Billing
                </label>
              )}
            >
              {renderShippingAddress(props)}
            </FormSection>
          </Fragment>
        )}
        {tab === 3 && (
          <Fragment>
            <FormSection title="Social" className="float-left w-100">
              {renderSocialFields(props)}
            </FormSection>

            <FormSection title="Others" className="float-left w-100 others">
              {renderOtherFields(props)}
            </FormSection>
          </Fragment>
        )}

        <FormAction
          disabled={isSubmitting}
          setPrompt={props.setPrompt}
          onCancel={() => {
            let dataFromLS = getObjFromLS('module');
            let redirectUrl = '';
            if (dataFromLS && dataFromLS.moduleName === 'contacts') {
              redirectUrl = dataFromLS.queryParam;
            }
            if (inModal) {
              let response;
              let { checkout } = validateContactForm({
                ...values,
                tab,
              });
              if (!checkout) {
                response = confirmationAlert();
              }
              if (response || checkout) {
                close();
              }
            } else if (
              window.location.pathname.includes('add') ||
              window.location.pathname.includes('edit')
            ) {
              history.push('/contacts' + redirectUrl);
            } else {
              setObjInLS('module', { ...dataFromLS, action: null });
              history.push('/r');
            }
          }}
          marginFromRight={0}
          additionalFields={
            tab === 1
              ? [
                  {
                    label:
                      type === 'add'
                        ? 'Add Address'
                        : type === 'edit'
                        ? 'Edit Address'
                        : 'Add Address',
                    onClick: () => updateTab(tab + 1),
                    icon: () => <MdArrowForward />,
                    place: 'front',
                  },
                ]
              : tab === 2
              ? [
                  {
                    label: 'Back',
                    onClick: () => updateTab(tab - 1),
                    icon: () => <MdArrowBack />,
                    place: 'behind',
                  },
                  {
                    label:
                      type === 'add'
                        ? 'Add Other Details'
                        : type === 'edit'
                        ? 'Edit Other Details'
                        : 'Add Other Details',
                    onClick: () => updateTab(tab + 1),
                    icon: () => <MdArrowForward />,
                    place: 'front',
                  },
                ]
              : [
                  {
                    label: 'Back',
                    onClick: () => updateTab(tab - 1),
                    icon: () => <MdArrowBack />,
                    place: 'behind',
                  },
                ]
          }
        />
        <ErrorFocus />
      </Form>
    </>
  );
};

const renderBasicInfo = ({
  values: {
    currentImage,
    contactImage,
    salutation,
    firstName,
    lastName,
    displayName,
    companyName,
    contactTypeId,
    department,
    designation,
    website,
    invoiceTermId,
    currencyID,
    workPhone,
    license,
    sellerPermit,
    mobile_secondary,
    workPhone_secondary,
    mobile,
    email,
    displayNames,
    showImageCropModal,
  },
  updateTab,
  tab,
  status: { isUniqueDisplayName },
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setStatus,
  type,
  currencyDB,
  InvoiceTerms,
  currencyEditable,
  inModal,
  // showImageCropModal,
  ...props
}) => {
  const handleDepartmentChange = (departValue) => {
    let depart = departValue.target.value;
    if (depart.length > 0) {
      let departString = depart;
      if (departString.length > 25) {
        departString = departString.slice(0, 25);
      }
      depart = departString;
    }
    setFieldValue('department', depart);
  };

  const handleDesignationChange = (designationValue) => {
    let design = designationValue.target.value;
    if (design.length > 0) {
      let designString = design;
      if (designString.length > 25) {
        designString = designString.slice(0, 25);
      }
      design = designString;
    }
    setFieldValue('designation', design);
  };

  const handleFiles = (files) => {
    const file = files[0];
    if (!file) {
      props.handleToast('Invalid file/file size.', 'error');
      return;
    }

    let updatedFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFieldValue('contactImage', file, ...updatedFiles);
    setFieldTouched('contactImage');
    setFieldValue('showImageCropModal', true);

    setFieldValue('isContactImageDeleted', false);
  };

  const handleDeletePhoto = () => {
    setFieldValue('isContactImageDeleted', true);
    setFieldValue('contactImage', '');
    setFieldValue('currentImage', '');
    setFieldValue('base84Image', '');
  };

  return (
    <Fragment>
      <div className="contact-form form-field">
        <div className="inner-contact-form-picture">
          <div className="form-image">
            <FileUploader
              accept="image/*"
              onDrop={handleFiles}
              maxSize={1 * 1000 * 1000}
              circular
              camIcon={true}
            />
            {(contactImage || currentImage) && (
              <>
                {/* <div className="position-relative"> */}
                {/* <div className="theekhai">
                    <div
                      className="image-block"
                      style={{
                        backgroundImage: contactImage
                          ? `url(${contactImage.preview})`
                          : `url(${imagePathS3(currentImage)})`,
                      }}
                    />
                  </div> */}
                <div className="profile_image">
                  <ImagePreview
                    images={contactImage ? contactImage : [currentImage]}
                    onDeleteImage={handleDeletePhoto}
                  />
                </div>
                {/*  UI DEV Required  Ticket SIQ-4544*/}
                {/* <button
                    className="cross-btn-set button--image-delete"
                    onClick={handleDeletePhoto}
                  >
                    <CloseIcon />
                  </button> */}
                {/* </div> */}
              </>
            )}
            {ImageCropModal(
              props,
              setFieldValue,
              showImageCropModal,
              contactImage
            )}
          </div>
          <label className="form-label contact-add-profile">
            Add Profile Picture
          </label>
        </div>
        <div className="radio-feilds">
          <Field
            className="radio-field display-flex-row"
            type="radio"
            name="Contact Type"
            required
          >
            <div
              className="radio-container"
              onChange={(e) => {
                handleChange(e);
                setFieldValue('customerTypeChange', e.target.checked);
              }}
            >
              <div className="radio-btn">
                <input
                  autoFocus={true}
                  type="radio"
                  name="contactTypeId"
                  id="customer"
                  value="2"
                  defaultChecked={+contactTypeId === 2}
                  disabled={inModal || type === 'edit'}
                  readOnly
                />
                <label htmlFor="customer">Customer</label>
                <span className="radio-circle" />
              </div>

              <div className="radio-btn">
                <input
                  type="radio"
                  name="contactTypeId"
                  id="vendor"
                  value="1"
                  defaultChecked={+contactTypeId === 1}
                  disabled={inModal || type === 'edit'}
                  readOnly
                />
                <label htmlFor="vendor">Vendor</label>
                <span className="radio-circle" />
              </div>
            </div>
          </Field>
        </div>

        <input name="contactImage" className="input-zero" />
        <ErrorMessage
          name="contactImage"
          component="div"
          className="form--error-message"
        />
      </div>
      <div className="form-fields-container d-flex flex-wrap  position-relative w-100">
        <Field
          className="flex-25"
          name="Salutation"
          id="click_Salutation_dropdown"
          value={salutation}
          options={SALUTATIONS}
          autoFocus={true}
          dropdownValue="value"
          dropdownLabel="label"
          handleChange={(option) => {
            setFieldValue('salutation', option ? option.value : '');
            // setFieldValue('displayName', '');
          }}
          type="dropdown"
        />
        <Field
          className="flex-25"
          name="First Name"
          value={firstName}
          id="firstName"
          required
          handleChange={(value) => {
            setFieldValue('firstName', value.target.value);
            // setFieldValue('displayName', '');
          }}
          onBlur={handleBlur}
          error={touched.firstName && errors.firstName}
        />
        <Field
          className="flex-25"
          name="Last Name"
          size="large"
          value={lastName}
          id="lastName"
          handleChange={(value) => {
            setFieldValue('lastName', value.target.value);
            // setFieldValue('displayName', '');
          }}
          onBlur={handleBlur}
          error={touched.lastName && errors.lastName}
        />

        <Field
          className="flex-25"
          size="large"
          name="Company Name"
          value={companyName}
          id="companyName"
          handleChange={(value) => {
            setFieldValue('companyName', value.target.value);
          }}
          onBlur={handleBlur}
          error={touched.companyName && errors.companyName}
        />
        <Field
          className="flex-25"
          size="large"
          name="Display Name"
          type="dropdown"
          dropdownValue="id"
          //clearable={false}
          options={displayNames}
          creatable={true}
          dropdownLabel="label"
          handleChange={(option) => {
            if (
              option &&
              displayNames.find((dn) => dn.label === option.label)
            ) {
              setFieldValue('createDisplayName', '');
            }
            if (option === null) {
              setFieldValue('displayName', '');
              setFieldValue('createDisplayName', '');
            } else if (option.__isNew__ === true) {
              setFieldValue('createDisplayName', option.value);
              setFieldValue('displayName', option.value);
            } else {
              setFieldValue('displayName', option.value);
            }
          }}
          id="displayName"
          value={displayName}
          //autoComplete="off"
          required
          onBlur={handleBlur}
          error={
            (touched.displayName && errors.displayName) ||
            (!isUniqueDisplayName && (
              <div className="unique-warning">
                *given display name is not unique
              </div>
            ))
          }
        />

        <Field
          className="flex-25"
          name="Department"
          size="large"
          value={department}
          id="department"
          type="text"
          //handleChange={handleChange}
          handleChange={(value) => handleDepartmentChange(value)}
          onBlur={handleBlur}
          error={touched.department && errors.department}
          maxLength={25}
        />

        <Field
          className="flex-25"
          size="large"
          name="Designation"
          value={designation}
          id="designation"
          //handleChange={handleChange}
          handleChange={(value) => handleDesignationChange(value)}
          onBlur={handleBlur}
          error={touched.designation && errors.designation}
        />

        <Field
          className="flex-25"
          size="large"
          name="Currency"
          id="currencyID"
          value={currencyID}
          options={currencyDB}
          dropdownValue="id"
          dropdownLabel="name"
          handleChange={(option) =>
            setFieldValue('currencyID', option ? option.value : '')
          }
          onBlur={() => setFieldTouched('currencyID', true)}
          type="dropdown"
          disabled={!currencyEditable}
          required
          error={touched.currencyID && errors.currencyID}
        />

        <Field
          className="flex-25"
          size="large"
          name="License No./ Permit No."
          value={license}
          id="license"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.license && errors.license}
        />
        {+contactTypeId === 2 && (
          <Field
            size="large"
            className="flex-25"
            name="Invoice Term"
            value={invoiceTermId}
            options={InvoiceTerms}
            dropdownValue="id"
            dropdownLabel="name"
            handleChange={(option) =>
              setFieldValue('invoiceTermId', option ? option.value : '')
            }
            type="dropdown"
          />
        )}
      </div>
      <div className="Heading--forms"> Contact Info </div>
      <div className="form-fields-container d-flex flex-wrap  position-relative w-100">
        <Field
          className="flex-25"
          // type="phone"
          size="large"
          name="Phone (Primary)"
          value={workPhone}
          id="workPhone"
          handleChange={(value) =>
            setFieldValue('workPhone', handlePhone(value, 20))
          }
          //handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.workPhone && errors.workPhone}
        />

        <Field
          className="flex-25"
          // type="phone"
          size="large"
          name="Phone (Secondary)"
          value={workPhone_secondary}
          id="workPhone_secondary"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('workPhone_secondary', handlePhone(value, 20))
          }
          onBlur={handleBlur}
          error={touched.workPhone_secondary && errors.workPhone_secondary}
        />

        <Field
          name="Mobile (Primary)"
          size="large"
          className="flex-25"
          // type="phone"
          value={mobile}
          id="mobile"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('mobile', handlePhone(value, 20))
          }
          onBlur={handleBlur}
          error={touched.mobile && errors.mobile}
        />

        <Field
          name="Mobile (Secondary)"
          size="large"
          className="flex-25"
          // type="phone"
          value={mobile_secondary}
          id="mobile_secondary"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('mobile_secondary', handlePhone(value, 20))
          }
          onBlur={handleBlur}
          error={touched.mobile_secondary && errors.mobile_secondary}
        />
        <div className="position-relative flex-25 form-field contact-email">
          <Field
            className="flex-100 position-relative"
            name="Email"
            size="large"
            value={email}
            id="email"
            handleChange={(e) => changeEmail(e.target.value, setFieldValue)}
            onBlur={handleBlur}
            error={
              (touched.email && errors.email) || (valid && 'Invalid Email')
            }
          />
          <div
            title="Use comma (,) to seperate multiple email ids. e.g john@gmail.com,tina@gmail.com"
            className="contact_email_field"
          >
            <EmailInfo className="contact_email_helper" />
          </div>
        </div>
        <Field
          className="flex-25"
          name="Website"
          size="large"
          value={website}
          id="website"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.website && errors.website}
        />
      </div>
    </Fragment>
  );
};

const renderBillingAddress = ({
  values: {
    billingAddress: {
      attention,
      street,
      city,
      state,
      zipCode,
      countryID,
      fax,
      phone,
    },
  },
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  countryDB,
  billingAddressCountryState,
  populateCountryStates,
}) => {
  if (zipCode?.toString()?.length > 15) {
    zipCode = zipCode?.toString();
    zipCode = zipCode?.slice(0, 15);
  }

  return (
    <Fragment>
      <div className="form-fields-container d-flex flex-wrap  position-relative w-100 contact-billing">
        <div className="form-tooltip flex-25 position-relative d-flex">
          <Field
            autoFocus={true}
            className="flex-100"
            size="large"
            name="Attention"
            value={attention}
            id="billingAddress.attention"
            // placeholder="Contact Person Name, Designation"
            handleChange={handleChange}
            onBlur={handleBlur}
            error={getErrorByKey(errors, touched, 'billingAddress.attention')}
          />
          <span className="icon_div">
            <MdHelpOutline
              className="material-icon blue-icon help"
              style={{ marginRight: '1.7rem' }}
            />
            <div className="tooltip_tooltip">
              <p>Contact Person Name, Designation</p>
            </div>
          </span>
        </div>
        <Field
          className="flex-25"
          size="large"
          name="Street Address"
          value={street}
          id="billingAddress.street"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('billingAddress.street', handleStreet(value, 200))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'billingAddress.street')}
        />

        <Field
          className="flex-25"
          name="City"
          size="large"
          value={city}
          id="billingAddress.city"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('billingAddress.city', handleCity(value, 100))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'billingAddress.city')}
        />

        <Field
          className="flex-25"
          size="large"
          name="State"
          value={state}
          id="billingAddress.state"
          handleChange={(value) =>
            setFieldValue('billingAddress.state', handleCity(value, 100))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'billingAddress.state')}
        />

        <Field
          className="flex-25"
          name="Zip Code"
          value={zipCode || ''}
          size="large"
          id="billingAddress.zipCode"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'billingAddress.zipCode')}
        />

        <Field
          className="flex-25"
          type="dropdown"
          name="Country"
          value={countryID || 230}
          size="large"
          options={countryDB}
          //disabled={true}
          clearable={false}
          dropdownLabel="country_name"
          dropdownValue="id"
          handleChange={(option) => {
            setFieldValue(
              'billingAddress.countryID',
              option ? option.value : null
            );
            setFieldValue(
              'billingAddress.countryId_updated',
              option ? true : false
            );
            //populateCountryStates(option ? option.value : null, 'billing')
          }}
        />

        <Field
          className="flex-25"
          name="Fax"
          size="large"
          //type="phone"
          value={fax}
          id="billingAddress.fax"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('billingAddress.fax', handlePhone(value, 20))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'billingAddress.fax')}
        />

        <Field
          className="flex-25"
          //type="phone"
          size="large"
          name="Phone"
          value={phone}
          id="billingAddress.phone"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('billingAddress.phone', handlePhone(value, 20))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'billingAddress.phone')}
        />
      </div>
    </Fragment>
  );
};

const renderShippingAddress = ({
  values: {
    billingAddress,
    shippingAddress: {
      attention,
      street,
      city,
      state,
      zipCode,
      countryID,
      fax,
      phone,
    },
  },
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  countryDB,
  shippingAddressCountryState,
  populateCountryStates,
}) => {
  if (zipCode?.toString()?.length > 15) {
    zipCode = zipCode?.toString();
    zipCode = zipCode?.slice(0, 15);
    // zipCode=parseInt(zipCode)
  }
  return (
    <Fragment>
      <div className="form-fields-container d-flex flex-wrap  position-relative w-100 shipping-area">
        <div className="form-tooltip flex-25 position-relative d-flex">
          <Field
            className="flex-100"
            size="large"
            name="Attention"
            value={attention}
            id="shippingAddress.attention"
            // placeholder="Contact Person Name, Designation"
            handleChange={handleChange}
            onBlur={handleBlur}
            error={getErrorByKey(errors, touched, 'shippingAddress.attention')}
          />
          <span className="icon_div">
            <MdHelpOutline
              className="material-icon blue-icon help"
              style={{ marginRight: '1.7rem' }}
            />
            <div className="tooltip_tooltip">
              <p>Contact Person Name, Designation</p>
            </div>
          </span>
        </div>
        <Field
          className="flex-25"
          size="large"
          name="Street Address"
          value={street}
          id="shippingAddress.street"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('shippingAddress.street', handleStreet(value, 200))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'shippingAddress.street')}
        />

        <Field
          className="flex-25"
          size="large"
          name="City"
          value={city}
          id="shippingAddress.city"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('shippingAddress.city', handleCity(value, 100))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'shippingAddress.city')}
        />

        <Field
          className="flex-25"
          size="large"
          name="State"
          value={state}
          id="shippingAddress.state"
          handleChange={(value) =>
            setFieldValue('shippingAddress.state', handleCity(value, 100))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'shippingAddress.state')}
        />

        <Field
          className="flex-25"
          name="Zip Code"
          size="large"
          value={zipCode}
          id="shippingAddress.zipCode"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'shippingAddress.zipCode')}
        />
        <Field
          className="flex-25"
          type="dropdown"
          name="Country"
          size="large"
          value={countryID}
          options={countryDB}
          //disabled={true}
          clearable={false}
          dropdownLabel="country_name"
          dropdownValue="id"
          handleChange={(option) => {
            setFieldValue(
              'shippingAddress.countryID',
              option ? option.value : null
            );
            setFieldValue(
              'shippingAddress.countryId_updated',
              option ? true : false
            );
            //populateCountryStates(option ? option.value : null, 'shipping')
          }}
        />

        <Field
          className="flex-25"
          name="Fax"
          size="large"
          //type="phone"
          value={fax}
          id="shippingAddress.fax"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('shippingAddress.fax', handlePhone(value, 20))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'shippingAddress.fax')}
        />

        <Field
          className="flex-25"
          //type="phone"
          size="large"
          name="Phone"
          value={phone}
          id="shippingAddress.phone"
          //handleChange={handleChange}
          handleChange={(value) =>
            setFieldValue('shippingAddress.phone', handlePhone(value, 20))
          }
          onBlur={handleBlur}
          error={getErrorByKey(errors, touched, 'shippingAddress.phone')}
        />
      </div>
    </Fragment>
  );
};

const renderSocialFields = ({
  values: { facebook, twitter, skype, instagram },
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  return (
    <Fragment>
      <div className="form-fields-container d-flex flex-wrap  position-relative w-100 contacts-socials">
        <Field
          autoFocus={true}
          className="flex-25"
          name="Facebook"
          size="large"
          value={facebook}
          id="facebook"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.facebook && errors.facebook}
          bottomText={`${facebookUrl}/${facebook}`}
        />

        <Field
          className="flex-25"
          name="Twitter"
          size="large"
          value={twitter}
          id="twitter"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.twitter && errors.twitter}
          bottomText={`${twitterUrl}/${twitter}`}
        />

        <Field
          className="flex-25"
          name="Skype"
          size="large"
          value={skype}
          id="skype"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.skype && errors.skype}
          bottomText={`@${skype}`}
        />

        <Field
          className="flex-25"
          name="Instagram"
          size="large"
          value={instagram}
          id="instagram"
          handleChange={handleChange}
          onBlur={handleBlur}
          error={touched.instagram && errors.instagram}
          bottomText={`${instagramUrl}/${instagram}`}
        />
      </div>
    </Fragment>
  );
};

const renderOtherFields = ({
  values: { notes },
  handleChange,
  errors,
  sales,
  handleBlur,
  company_name,
  touched,
}) => {
  return (
    <div
      style={{ marginBottom: '1%' }}
      className="form-fields-container d-flex flex-wrap  position-relative"
    >
      <Field
        size="large"
        name="Notes"
        className="textarea-field flex-50"
        value={notes}
        id="notes"
        type="textarea"
        handleChange={handleChange}
        error={touched.notes && errors.notes}
      />
    </div>
  );
};

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestType,
      requestUrl,
      history,
      successMessage,
      handleToast,
      onSubmit,
      close,
      inModal,
      updateTab,
      errors,
      type,
      // CountryStatesCombo,
      setPrompt,
      updateoverAll,
    },
  }
) => {
  if (valid) {
    setSubmitting(false);
    return;
  }
  setPrompt(false);
  let data = new FormData();
  data.append('salutation', values?.salutation ?? '');
  data.append('first_name', values?.firstName ?? '');
  data.append('last_name', values?.lastName ?? '');
  data.append('company_name', values?.companyName ?? '');
  data.append(
    'display_name',
    values?.displayNames.find((dn) => dn.id === values?.displayName).label
  );
  data.append('email', values?.email ?? '');
  data.append('work_phone', values?.workPhone ?? '');
  data.append('workPhone_secondary', values?.workPhone_secondary ?? '');
  data.append('mobile_secondary', values?.mobile_secondary ?? '');
  data.append('license', values?.license ?? '');
  data.append('work_phone_secondary', values?.workPhone_secondary ?? '');
  data.append('mobile_secondary', values?.mobile_secondary ?? '');
  data.append('license_no', values?.license ?? '');
  // data.append('permit_no', values?.sellerPermit)
  data.append('permit_no', null);
  data.append('sales', values?.sales ?? '');
  data.append(
    'invoice_term_id',
    values?.contactTypeId === 2 ? values?.invoiceTermId : null
  );
  data.append('mobile', values?.mobile ?? '');
  data.append('designation', values?.designation ?? '');
  data.append('department', values?.department ?? '');
  data.append('website', values?.website ?? '');
  data.append('contact_type_id', values?.contactTypeId ?? '');
  data.append('currency_id', values?.currencyID ?? '');
  data.append('bill_attention', values?.billingAddress.attention ?? '');
  data.append('bill_street', values?.billingAddress.street ?? '');
  data.append('bill_city', values?.billingAddress.city ?? '');
  data.append('bill_state', values?.billingAddress.state ?? '');
  data.append(
    'bill_zip_code',
    restrictToFifteen(values?.billingAddress.zipCode ?? '')
  );
  data.append(
    'bill_country_id',
    values?.billingAddress.countryID ? values?.billingAddress.countryID : 230
  );
  data.append('bill_fax', values?.billingAddress.fax ?? '');
  data.append('bill_phone', values?.billingAddress.phone ?? '');
  data.append('ship_attention', values?.shippingAddress.attention ?? '');
  data.append('ship_street', values?.shippingAddress.street ?? '');
  data.append('ship_city', values?.shippingAddress.city ?? '');
  data.append('ship_state', values?.shippingAddress.state ?? '');
  data.append('updateOverAll', updateoverAll);
  data.append(
    'ship_zip_code',
    restrictToFifteen(values?.shippingAddress.zipCode)
  );
  data.append(
    'ship_country_id',
    values?.shippingAddress.countryID ? values?.shippingAddress.countryID : 230
  );
  data.append('ship_fax', values?.shippingAddress.fax ?? '');
  data.append('ship_phone', values?.shippingAddress.phone ?? '');
  data.append('custom_fields', values?.customFields || {});
  data.append('facebook', values?.facebook ?? '');
  data.append('twitter', values?.twitter ?? '');
  data.append('skype', values?.skype ?? '');
  data.append('instagram', values?.instagram ?? '');
  data.append('note', values?.notes ?? '');
  data.append('type', type);
  // data.append('photo', values?.contactImage);
  data.append('base84Image', values.base84Image ?? '');

  data.append('isContactImageDeleted', values?.isContactImageDeleted);
  if (type === 'clone' && values?.currentImage) {
    data.append('cloneUrl', values?.currentImage);
  }

  if (!values?.contactImage) {
    data.delete('photo');
  }
  restRequest(requestType, requestUrl, data)
    .then((res) => {
      successMessage();
      if (inModal) {
        onSubmit(res);
        // close();
      } else {
        let dataFromLS = getObjFromLS('module');
        let obj = {
          ...dataFromLS,
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: '',
          urlPath: '/contacts',
          moduleName: 'contacts',
        };
        if (type === 'add' || type === 'clone')
          obj = {
            ...obj,
            nextId: null,
            prevId: null,
            id: res.data.id,
          };
        setObjInLS('module', obj);
        return history.push('/r');
      }
    })
    .catch((error) => {
      checkError(error);
      handleToast(error, 'error');
      setSubmitting(false);
    });
};

const restrictToFifteen = (zipCode) => {
  if (!zipCode === null || zipCode?.match(/^ *$/) !== null) return '';
  return zipCode?.toString().slice(0, 15);
};

const changeEmail = (vale, setFieldValue) => {
  valid = false;
  var emails = vale?.replace(/\s/g, '').split(',');
  var regex =
    //eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  for (var i = 0; i < emails?.length; i++) {
    if (emails[i] === '' || !regex.test(emails[i])) {
      valid = true;
    }
  }
  setFieldValue('email', emails);
  if (vale?.length === 0) return (valid = false);
};

// NOTE: check properly later: populateCountryStates function
const sameAsBilling = (
  event,
  billingAddress,
  setFieldValue
  // populateCountryStates
) => {
  const isChecked = event.target.checked;
  if (isChecked) {
    //populateCountryStates(billingAddress.countryID, 'shipping')
    setFieldValue('shippingAddress', billingAddress);
    setFieldValue('sameAsBillingAdress', isChecked);
  } else {
    setFieldValue('shippingAddress', {
      attention: '',
      street: '',
      city: '',
      state: '',
      zipCode: '',
      countryID: 230,
      fax: '',
      phone: '',
    });
  }
};
const ImageCropModal = (values, setFieldValue, showModal, contactImage) => {
  var base64Images = [];

  return (
    <CustomModal
      className="upload_img_popup"
      showModal={showModal}
      title="Adjust Image"
      renderActions={false}
      onClose={() => setFieldValue('showImageCropModal', false)}
    >
      <ImageCropper
        inModal
        updatedFiles={[contactImage]}
        type="add"
        onSubmitModal={(updatedFiles, croppedImage) => {
          updatedFiles[updatedFiles.length - 1].preview = croppedImage;
          updatedFiles.forEach((el) => {
            base64Images.push(el.preview);
            setFieldValue('base84Image', base64Images);
          });
          setFieldValue('contactImage', updatedFiles);
          setFieldValue('showImageCropModal', false);
        }}
        closeModal={() => setFieldValue('showImageCropModal', false)}
      />
    </CustomModal>
  );
};

export default formEnhancer(ContactForm);
