import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import {
  Loader,
  Switch,
  RadioContainer,
  CustomModal,
  ListTableImageColumn,
  Radio,
} from '../../';
import {
  restRequest,
  findByValue,
  handleExtraDescription,
  checkError,
  getOverallTaxDetails,
  getObjFromLS,
  setObjInLS,
  getFilteredItem,
  confirmationAlert,
  GetDateString,
} from '../../../Helpers';
import AddContact from '../../../Pages/Contact/AddContact';
import './BillsForm.css';
import {
  ValidateRequired,
  ValidateMax15,
  ValidateMax50,
  required,
  moreThanZero,
  ValidateEqual0,
  moreThanMinus,
  ValidateMax255,
  ValidateMax10Billion,
  ValidateMax100Number,
  ValidateNumber,
  ValidateMax1000,
} from '../../../Helpers/Validator';
import { validateBillsForm } from '../../../Helpers/FormValidator';
import { validate, getError, formSubmit } from '../FormHelper';
import {
  ItemForm,
  Field,
  ErrorFocus,
  Form,
  AddItem,
  FormAction,
  BulkAddIssuedPurchaseOrders,
} from '../';
import { MdDragIndicator } from '../../../Common/Icons';
import { Draggable } from 'react-beautiful-dnd';
import DragDropItems from '../../../Common/DragDrop';

import { getDiscountOptions } from '../../../Constants';
import { NewPurchaseOrderIcon } from '../../../Assets/Navigation/NavigationIcons';
// import { emptyItem } from '../../../Api/Invoice/Utils';
import Customer from '../../../Pages/Contact/ContactDetails/index';
import {
  NewContactIcon,
  NewBillIcon,
} from '../../../Assets/Navigation/NavigationIcons';
import { ToolTip } from '../../../Common';
import TableContent from '../../ListTable/TableContant';

let flagOnce = 0;
let totalAmount = 0;
var loadingSelectItem = false;
let totalConfirmCheck = false;
// const skipButton = localStorage.getItem('skipBtnoldAccount');

const getYupValidationSchema = (values) => {
  return Yup.object().shape({
    vendorId: required(ValidateNumber),
    billNo: required(ValidateMax50),
    orderNo: ValidateMax15,
    note: ValidateMax255,
    termsAndConditions: values.hasTermsAndConditions
      ? ValidateMax1000.nullable()
      : ValidateEqual0.nullable(),
    billDate: ValidateRequired.nullable(),
    dueDate: ValidateRequired.nullable(),
    items: required(
      Yup.array()
        .of(
          Yup.object().shape({
            name: ValidateRequired,
            quantity: required(
              moreThanZero(ValidateMax10Billion).integer('Invalid Value')
            ),
            unitPrice: required(moreThanMinus(ValidateMax10Billion)),
            accountId: required(ValidateNumber),
          })
        )
        .min(1, 'At least one item is required')
    ),
    transactionDiscount:
      values.transactionDiscountType === 'percent'
        ? ValidateMax100Number
        : ValidateMax10Billion,
    // discountLimitation : values.transactionDiscountType === 'string' ?
    adjustment: ValidateMax10Billion,
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ bill }) => ({
    ...bill,
    orderNo: bill.orderNo ? bill.orderNo : '',
    note: bill.note ? bill.note : '',
  }),
  mapPropsToStatus: (props) => ({
    itemsList: props.itemsList,
    emptyItem: props.emptyItem,
    issuedPurchaseOrdersList: props.issuedPurchaseOrdersList,
    orgissuePurchaseOrderList: props.issuedPurchaseOrdersList,
    showIssuedPurchaseOrdersListModal: false,
    showContactCreateModal: false,
    showItemModal: false,
    selectedIssuedPurchaseOrderIds: [],
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});
const BillForm = (props) => {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setStatus,
    setValues,
    isSubmitting,
    errors,
    touched,
    // custom props
    status,
    history,
    onClose,
    emptyItem,
    vendorIdTransaction,
    vendorsList,
    inModal,
    type,
    previousPurchasePrices,
    checkDirectBill,
    warehousesList,
    billType,
    billItemsLength,
    purchaseOrderId,
    handleToast,
    cofirmState,
    handleTotalCheck,
    handleBillPrompt,
    indirectBill,
    currency,
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [staticVal, setStaticValues] = useState({});
  const [itemsList, setItemsList] = useState([]);
  const [showConfirmRemoveItemModal, setShowConfirmRemoveItemModal] =
    useState(false);
  const [CurrentVendor, setCurrentVendor] = useState({});
  const [po_bills_loading, set_po_bills_loading] = useState(false);
  const [skipButton, setskipButton] = useState(
    localStorage.getItem('skipBtnoldAccount')
  );
  useEffect(() => {
    setskipButton(JSON.parse(localStorage.getItem('skipBtnoldAccount')));
  }, [skipButton]);
  useEffect(() => {
    handleAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAlert = () => {
    let { checkout } = validateBillsForm(values, {
      ...staticVal,
      items: itemsList,
    });
    if (!checkout) {
      props.setPrompt();
      handleBillPrompt();
    } else {
      props.setPrompt(false);
      handleBillPrompt(false);
    }
  };

  const { billNo, orderNo, billDate, dueDate, vendorId, items, poId } = values;

  const fetchIssuedPurchaseOrders = (vendorId) => {
    set_po_bills_loading(true);
    restRequest(
      'get',
      `bills/create/billable-issued-purchaseorders/from-contacts/${vendorId}`
    )
      .then((res) => {
        set_po_bills_loading(false);
        setStatus({
          ...status,
          issuedPurchaseOrdersList: res,
          orgissuePurchaseOrderList: res,
          selectedIssuedPurchaseOrderIds: [],
        });
      })
      .catch((error) => {
        set_po_bills_loading(false);
        checkError(error);
      });
  };

  let primaryWarehouse = null;
  warehousesList.forEach(function (warehouse) {
    if (warehouse.is_primary) {
      primaryWarehouse = warehouse.id;
    }
  });

  const [loader, showLoader] = useState(false);
  const handleIssuedPurchaseOrdersListOnSubmit = (
    selectedPurchaseOrdersList
  ) => {
    showLoader(true);
    // Remove first blank field when adding items in bulk
    if (values.items[0] && values.items[0].id === null) {
      values.items.shift();
    }
    const selectedItems = [];
    const selectedIssuedPurchaseOrderIds = [];
    for (let purchaseOrder of selectedPurchaseOrdersList) {
      selectedIssuedPurchaseOrderIds.push(purchaseOrder.id);
      for (let purchaseOrderItemDetail of purchaseOrder.purchase_order_item_details) {
        const preparedItem = {
          id: purchaseOrderItemDetail.item_id,
          dragDropID: GetDateString(),
          purchaseOrderItemDetailId:
            purchaseOrderItemDetail.purchase_order_item_detail_id,
          warehouseId: purchaseOrderItemDetail.purchase_order_item_warehouse_id,
          name: purchaseOrderItemDetail.item_name,
          images: purchaseOrderItemDetail.item_images,
          sku: purchaseOrderItemDetail.item_sku,
          unit: purchaseOrderItemDetail.item_unit,
          unitPrice: purchaseOrderItemDetail.purchase_order_item_unit_price,
          accountId: purchaseOrderItemDetail.account_id,
          quantity: purchaseOrderItemDetail.quantity_billable_without_received,
          maxBillableQuantity:
            purchaseOrderItemDetail.quantity_billable_without_received,
          extra_description: purchaseOrderItemDetail.extra_description,
          tax: {
            id: purchaseOrderItemDetail.tax
              ? purchaseOrderItemDetail.tax.id
              : null,
            rate: purchaseOrderItemDetail.tax
              ? purchaseOrderItemDetail.tax.rate
              : 0,
            name: purchaseOrderItemDetail.tax
              ? purchaseOrderItemDetail.tax.name
              : '',
          },
          taxAmount: purchaseOrderItemDetail.purchase_order_item_tax_id
            ? getTaxAmount(
                purchaseOrderItemDetail.quantity_billable_without_received,
                purchaseOrderItemDetail.purchase_order_item_unit_price,
                purchaseOrderItemDetail.tax.rate
              )
            : 0,
          total: getItemTotalAmount(
            purchaseOrderItemDetail.quantity_billable_without_received,
            purchaseOrderItemDetail.purchase_order_item_unit_price
          ),
        };

        selectedItems.push(preparedItem);
      }
    }

    let promises = [];
    selectedItems.forEach((item) => {
      promises.push(
        restRequest(
          'get',
          `purchaseorders/previouspurchaseprice?vendor_id=${vendorId}&item_id=${
            item.id ? item.id : item.value
          }`
        )
      );
    });
    Promise.all(promises)
      .then((promisRes) => {
        selectedItems.forEach((item, index) => {
          selectedItems[index] = {
            ...item,
            previousPurchasePrice: promisRes[index] ? promisRes[index] : 0,
          };
        });
        var remainingIssuedPurchaseOrdersList =
          status.issuedPurchaseOrdersList.filter(
            (purchaseOrder) =>
              !selectedIssuedPurchaseOrderIds.includes(purchaseOrder.id)
          );

        setStatus({
          ...status,
          showIssuedPurchaseOrdersListModal: false,
          issuedPurchaseOrdersList: remainingIssuedPurchaseOrdersList,
          selectedIssuedPurchaseOrderIds: [
            ...status.selectedIssuedPurchaseOrderIds,
            ...selectedIssuedPurchaseOrderIds,
          ],
          // showIssuedPurchaseOrdersListModal: false
        });
        updateissuedPO(0);
        showLoader(false);

        /*setStatus({
    ...status,
    showIssuedPurchaseOrdersListModal: false
  })*/
        setFieldValue('items', [...items, ...selectedItems]);
      })
      .catch((error) => {
        checkError(error);
      });
    setShowAddItemButton(true);
  };

  const closeIssuedPurchaseOrdersListModal = () => {
    setStatus({ ...status, showIssuedPurchaseOrdersListModal: false });
    updateissuedPO(0);
  };

  var billFakeItem = false;

  const renderAddItem = () => {
    const { items } = values;
    items.forEach((item) => {
      if (item.id === null || item.value === null) {
        billFakeItem = true;
      }
    });
    if (billFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          itemsId="click_DB_anotherItem_btn"
          onClick={() => setFieldValue('items', [...items, status.emptyItem])}
        />
      );
    }
  };
  const handleTermsAndConditions = (event) => {
    let selectedValue = event.target.value;
    if (selectedValue === 'true') {
      setFieldValue('hasTermsAndConditions', !!selectedValue);
    } else {
      setFieldValue('hasTermsAndConditions', !!!selectedValue);
    }
  };

  const renderTermsAndConditions = () => {
    return (
      <div className="form-field flex-50 p-0">
        <Field
          className="radio-field radio_buttons terms-po"
          type="radio"
          labelStyle={true}
          name="Add Terms & Conditions?"
          id="yes"
        >
          <RadioContainer>
            <Radio
              className="inline-field"
              id="yes"
              value={true}
              name="Yes"
              onChange={handleTermsAndConditions}
              checked={values.hasTermsAndConditions}
            />
            <Radio
              className="inline-field"
              id="no"
              value={false}
              name="No"
              onChange={handleTermsAndConditions}
              checked={!values.hasTermsAndConditions}
            />
          </RadioContainer>
        </Field>
        <div>
          {values.hasTermsAndConditions && (
            <Field
              className="w-100"
              type="textarea"
              value={values.termsAndConditions}
              id="termsAndConditions"
              required={true}
              handleChange={handleChange}
              error={touched.termsAndConditions && errors.termsAndConditions}
            />
          )}
        </div>
      </div>
    );
  };
  const issuedPurchaseOrdersListModal = () => {
    return (
      <CustomModal
        showModal={status.showIssuedPurchaseOrdersListModal}
        title={
          issuedPO !== 0
            ? `Issued Purchase Orders (${issuedPO} selected)`
            : 'Issued Purchase Orders'
        }
        Icon={NewPurchaseOrderIcon}
        modaltitle="Issue_PO_modal"
        renderActions={false}
        onClose={() => closeIssuedPurchaseOrdersListModal()}
      >
        <BulkAddIssuedPurchaseOrders
          purchaseOrdersList={status.issuedPurchaseOrdersList}
          onSubmit={(selectedPurchaseOrdersList) =>
            handleIssuedPurchaseOrdersListOnSubmit(selectedPurchaseOrdersList)
          }
          handleToast={handleToast}
          onClose={() => closeIssuedPurchaseOrdersListModal()}
          updateissuedPO={updateissuedPO}
          loader={loader}
        />
      </CustomModal>
    );
  };
  const [issuedPO, updateissuedPO] = useState(0);
  // const [checkTotalVal, setCheckTotalVal] = useState(false);
  const [, setShowMergeModal] = useState(false);
  const [showAddItemButton, setShowAddItemButton] = useState(
    type === 'edit' && checkDirectBill === false ? true : false
  );

  const handleVendorChange = (option) => {
    const { selectedIssuedPurchaseOrderIds = [] } = status;
    if (selectedIssuedPurchaseOrderIds.length > 0) {
      setShowConfirmRemoveItemModal(true);
      setStatus({ ...status, newVendorId: option?.value });
      return;
    }
    if (option && option.value === 'createNew') {
      setStatus({ ...status, showContactCreateModal: true });
    } else {
      setFieldValue('vendorId', option ? option.value : null);
      // if (!option) {
      //   setStatus({
      //     ...status,
      //     issuedPurchaseOrdersList:
      //       (status.issuedPurchaseOrdersList.length = 0),
      //   });
      // }

      if (option && values.items[0].id !== null) {
        values.items.map((newItem, index) =>
          getPreviousSellingPrice(
            option.value,
            newItem,
            status,
            setStatus,
            null
          ).then((updatedNewItem) => {
            values.items[index] = updatedNewItem;
            values.vendorId = option.value;
            setValues({ ...values });
          })
        );
      } else {
        setVendorName(option ? option.label : null);
        setValues({
          ...values,
          // items: [emptyItem],
          vendorId: option ? option.value : null,
        });
        setStatus({
          ...status,
          issuedPurchaseOrdersList: [],
        });
      }

      if (option) fetchIssuedPurchaseOrders(option.value);
    }
  };
  function closeModal() {
    setStatus({
      ...status,
      showContactCreateModal: false,
      showItemModal: false,
    });
  }
  const handlePrompt = (status = true) => setShowPrompt(status);

  const contactCreateModal = () => {
    return (
      <CustomModal
        size="large"
        showModal={status.showContactCreateModal}
        title="Add Contact"
        customWidth="contact-modal-width"
        renderActions={false}
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <AddContact
          type="purchaseOrder"
          inModal
          close={closeModal}
          onSubmit={(res) => handleContactCreateSubmit(res.data)}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const handleContactCreateSubmit = (newVendor) => {
    const old_obj = {
      id: newVendor.id,
      display_name: newVendor.display_name,
    };
    setVendorName(newVendor.display_name);

    const { id: value, display_name: label, ...rest } = old_obj;

    const new_Vendor = { value, label, ...newVendor };

    // vendorsList.push(new_Vendor);
    setValues({
      ...values,
      // items: [emptyItem],
      vendorId: new_Vendor ? new_Vendor.id : null,
    });
    setCurrentVendor(new_Vendor);
    closeModal();
  };

  const newItemModal = () => {
    return (
      <CustomModal
        size="large"
        customWidth="item-modal-width"
        renderActions={false}
        showModal={status.showItemModal}
        title="Add New Item"
        onClose={closeModal}
        showPrompt={showPrompt}
      >
        <ItemForm
          inModal
          type="add"
          onSubmitModal={(newItem) => {
            //updateItemList(newItem.data)
            let ItemsIndex = values.items.length - 1;
            handleCreateItemSubmit(newItem.data, ItemsIndex);
            setStatus({
              ...status,
              showItemModal: false,
            });
          }}
          modalSource={false}
          closeModal={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const handleCreateItemSubmit = async (newCreatedItem, index) => {
    loadingSelectItem = true;
    var newAddedItem = {
      id: newCreatedItem.id,
      dragDropID: GetDateString(),
      purchaseOrderItemDetailId: null,
      warehouseId: primaryWarehouse,
      name: newCreatedItem.name,
      images: newCreatedItem.images,
      sku: newCreatedItem.sku,
      accountId: parseInt(newCreatedItem.purchase_account_id),
      quantity: 1,
      inventory_type: newCreatedItem.inventory_type,
      maxBillableQuantity: null,
      unit: newCreatedItem.unit,
      unitPrice: newCreatedItem.purchase_unit_price,
      upc: newCreatedItem.upc,
      mpn: newCreatedItem.mpn,
      ean: newCreatedItem.ean,
      isbn: newCreatedItem.isbn,
      tax: {
        id: newCreatedItem.tax_id ? parseInt(newCreatedItem.tax.id) : null,
        rate: newCreatedItem.tax_id ? newCreatedItem.tax.rate : 0,
        name: newCreatedItem.tax_id ? newCreatedItem.tax.name : '',
      },
      taxAmount: 0,
    };
    status.itemsList.push(newAddedItem);
    const newItem = {
      ...newAddedItem,
      quantity: items[index].quantity,
      warehouseId: primaryWarehouse,
      previousPurchasePrice: 0,
    };
    setShowAddItemButton(true);
    //let updatedNewItem = await getPreviousSellingPrice(vendorId, newItem, status, setStatus, newAddedItem.id)
    setFieldValue(`items[${index}]`, { ...newItem });
    if (billType === 'edit' && checkDirectBill !== false) {
      setStatus({
        ...status,
        itemsList: status.itemsList.filter(
          (item) => item.billItemDetailId !== newAddedItem.billItemDetailId
        ),
      });
      return;
    }
    if (newAddedItem.inventory_type === 'noninventory' || purchaseOrderId)
      setStatus({
        ...status,
        itemsList: status.itemsList.filter(
          (item) => item.id !== newAddedItem.id
        ),
      });
    loadingSelectItem = false;
  };

  useEffect(() => {
    parseInt(vendorIdTransaction) &&
      setFieldValue('vendorId', parseInt(vendorIdTransaction));
    return () => {
      setShowMergeModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.items.length === 0) {
      setShowAddItemButton(false);
    }
    // else setShowAddItemButton(true);
  }, [values.items]);

  useEffect(() => {
    if (purchaseOrderId && !values.items.length) {
      setShowAddItemButton(false);
    } else if (indirectBill && billItemsLength === values?.items?.length) {
      setShowAddItemButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.items]);

  useEffect(() => {
    let editableItems = [...values.items];
    values.items.forEach((singleItem, index) => {
      editableItems[index] = {
        ...singleItem,
        previousPurchasePrice: previousPurchasePrices[index]
          ? previousPurchasePrices[index]
          : 0,
      };
    });
    setItemsList(editableItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPurchasePrices]);

  useEffect(() => {
    values.items.forEach((item) => {
      if (
        item.id === null &&
        window.location.pathname.includes('purchaseorders')
      ) {
        values.items.pop();
      }
    });
    return () => (flagOnce = 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTotalValueForZero = () => {
    return (
      <CustomModal
        style={{ marginTop: '5%' }}
        // showModal={checkTotalVal}
        showModal={cofirmState}
        title="Confirm Bill"
        renderActions={false}
        // onClose={() => setCheckTotalVal(false)}
        onClose={() => handleTotalCheck(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <p>Your total bill amount is Zero. Please confirm to continue.</p>

          <button
            type="button"
            className="action-buttons--submit next-btn"
            onClick={() => {
              handleTotalCheck(false);
              totalConfirmCheck = true;
              handleSubmit();
            }}
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    );
    // }
  };
  const handleDeleteConfirmSaleOrderItems = () => {
    let { items = [] } = values;
    let { newVendorId } = status;
    const itemResult = items.filter((item) => !item.purchaseOrderItemDetailId);
    if (newVendorId) {
      fetchIssuedPurchaseOrders(newVendorId);
    }

    setStatus({
      ...status,
      issuedPurchaseOrdersList: [],
      selectedIssuedPurchaseOrderIds: [],
    });

    setFieldValue('items', itemResult?.length ? itemResult : [emptyItem]);
    setFieldValue('vendorId', newVendorId);
    setShowConfirmRemoveItemModal(false);
  };

  const renderConfirmRemoveItemModal = () => {
    const { items = [] } = values;
    const purchaseOrderitemList = items.filter((item) => item.id);
    return (
      <>
        <CustomModal
          showModal={showConfirmRemoveItemModal}
          title={`Confirm Delete Sale Order Items`}
          onClose={() => {
            setShowConfirmRemoveItemModal(false);
          }}
          onSubmit={handleDeleteConfirmSaleOrderItems}
        >
          <div className="confirm-popup-main">
            <p>
              Are you sure you want to delete the confirm sale orders items?
            </p>
            <ul className="confirm-popup">
              {purchaseOrderitemList.map((purchaseOrderitem) => (
                <li key={purchaseOrderitem.id}>{purchaseOrderitem.name}</li>
              ))}
            </ul>
          </div>
        </CustomModal>
      </>
    );
  };
  let submitFlag = true;

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showPendBillModal, setShowPendBillModal] = useState(false);
  const [vendorName, setVendorName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const handleCustomerModal = (id) => {
    setCustomerId(id);
    setShowCustomerModal(true);
  };
  const handleDetailModal = (id) => {
    setCustomerId(id);
    setShowPendBillModal(true);
  };
  const contactDeatilModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showCustomerModal}
        title="View Contact"
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => setShowCustomerModal(false)}
        linkTo={`contacts/${customerId && customerId}`}
      >
        <Customer id={customerId && customerId} inModal={true} />
      </CustomModal>
    );
  };

  const PendBillDetailModal = () => {
    return (
      <CustomModal
        size="large"
        className=" view_pending_popup"
        showModal={showPendBillModal}
        title={`View ${vendorName} Pending Bills`}
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewBillIcon}
        renderActions={false}
        onClose={() => setShowPendBillModal(false)}
      >
        <TableContent
          url={`bills/unpaid/${customerId}`}
          inModal={true}
          showdetail="Bills"
          currency={currency.symbol}
        />
      </CustomModal>
    );
  };
  return (
    <Fragment>
      <div
        className={`${
          inModal
            ? 'create_bill_enchancer bill-modal'
            : ' uiux-bills-container create_bill_enchancer'
        }`}
      >
        {contactCreateModal()}
        {newItemModal()}
        {issuedPurchaseOrdersListModal()}
        {checkTotalValueForZero()}
        {contactDeatilModal()}
        {PendBillDetailModal()}
        {renderConfirmRemoveItemModal()}
        <Form
          className="float-left w-100"
          onSubmit={(event) => {
            if (submitFlag === false) {
              event.preventDefault();
              setShowMergeModal(true);
            } else {
              formSubmit(event, items, setFieldValue, handleSubmit);
            }
          }}
        >
          <div className="form-fields-container d-flex flex-wrap bills-fields-container position-relative">
            <div className="field-container invoice-fields-container position-relative flex-25 form-field">
              <Field
                id="vendorId"
                size="large"
                name="Vendor Name"
                autoFocus={true}
                type="DynamicPaginationDropdown"
                newCust={CurrentVendor}
                editCust={values.vendorObj}
                value={vendorId}
                disabled={inModal || (billType === 'edit' && poId)}
                addButton={true}
                required
                handleChange={(option) => {
                  handleVendorChange(option);
                }}
                error={touched.vendorId && errors.vendorId}
                placeholder="Select Vendor"
                alphabetSort
                onBlur={() => {
                  setFieldTouched('vendorId', true);
                }}
                api="bills/contact"
              />
              {!inModal && vendorId && (
                <div className="details-sec text-left d-flex space-between">
                  <div className="details-sec text-left">
                    <button
                      type="button"
                      className="view-details-btn-1 pt-8"
                      onClick={() => handleDetailModal(vendorId)}
                    >
                      {' '}
                      Pending Bills
                    </button>
                  </div>
                  <div className="details-sec text-right">
                    <button
                      type="button"
                      className="view-details-btn-1 pt-8"
                      onClick={() => handleCustomerModal(vendorId)}
                    >
                      {' '}
                      View Details
                    </button>
                  </div>
                </div>
              )}
            </div>
            <Field
              size="large"
              name="Bill No."
              id="billNo"
              value={billNo}
              required
              handleChange={handleChange}
              // disabled={billType=== 'edit'}
              disabled
              onBlur={handleBlur}
              error={touched.billNo && errors.billNo}
              className="flex-25"
            />

            <Field
              size="large"
              name="Order No."
              id="orderNo"
              value={orderNo ? orderNo : ''}
              handleChange={handleChange}
              onBlur={handleBlur}
              error={touched.orderNo && errors.orderNo}
              className="flex-25"
            />

            <Field
              size="large"
              type="date"
              name="Bill Date"
              id="billDate"
              value={billDate}
              minDate={values.orderDate}
              handleChange={(date) => {
                if (!date) return;
                setFieldValue('billDate', date);
                if (dueDate < date) {
                  setFieldValue('dueDate', date);
                }
              }}
              onBlur={() => setFieldTouched('billDate')}
              required
              error={touched.billDate && errors.billDate}
              className="flex-25 date-canalder-g"
            />

            <Field
              size="large"
              type="date"
              name="Due Date"
              id="dueDate"
              value={dueDate}
              minDate={billDate}
              handleChange={(date) => {
                setFieldValue('dueDate', date);
              }}
              onBlur={() => setFieldTouched('dueDate')}
              required
              error={touched.dueDate && errors.dueDate}
              className="flex-25"
            />
            <div className="d-flex w-100 tablet-align flex-wrap">
              <Field
                size="large"
                type="textarea"
                name="Notes"
                value={values.note}
                id="note"
                handleChange={handleChange}
                onBlur={handleBlur}
                error={touched.note && errors.note}
                className="flex-50"
              />
              {renderTermsAndConditions()}
            </div>
          </div>
          <div
            className="form-field form-table--container float-left w-100"
            style={{ marginRight: '0' }}
          >
            <label className="form-label">Select Items</label>
            {loadingSelectItem && <Loader />}
            {renderSelectedItemsTable(
              props,
              billType,
              setShowAddItemButton,
              skipButton
            )}
            {showAddItemButton && renderAddItem()}

            {billType === 'add' && (
              <AddItem
                visible={!!status.issuedPurchaseOrdersList.length}
                onClick={() =>
                  setStatus({
                    ...status,
                    showIssuedPurchaseOrdersListModal: true,
                  })
                }
                label={
                  po_bills_loading
                    ? 'loading...'
                    : `Add ${status.issuedPurchaseOrdersList.length} Issued Purchase Orders`
                }
                inline={true}
              />
            )}

            {renderFooter(props)}
          </div>

          <FormAction
            marginFromRight={0}
            type="button"
            onSubmit={() => {
              // setCheckTotalVal(true);
              handleSubmit();
            }}
            disabled={isSubmitting}
            onCancel={() => {
              let dataFromLS = getObjFromLS('module');
              let redirectUrl = '';
              if (dataFromLS && dataFromLS.moduleName === 'bills') {
                redirectUrl = dataFromLS.queryParam;
              }
              if (dataFromLS.moduleName === 'contacts') {
                history.push('/contacts');
                return;
              }
              if (inModal) {
                let response;
                let { checkout } = validateBillsForm(values, {
                  ...staticVal,
                  items: itemsList,
                });
                if (!checkout) response = confirmationAlert();
                if (response || checkout) onClose();
              } else if (
                window.location.pathname.includes('add') ||
                window.location.pathname.includes('edit')
              ) {
                history.push('/bills' + redirectUrl);
              } else {
                setObjInLS('module', { ...dataFromLS, action: null });
                history.push('/r');
              }
            }}
          />
          <ErrorFocus />
        </Form>
      </div>
    </Fragment>
  );
};

const handleArrowsKey = (e, index, fieldname, values) => {
  if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
    const element = document.getElementById(`items[${index + 1}].${fieldname}`);
    setTimeout(() => {
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }, 100);
  }
};
const handleArrowUpKey = (e, index, fieldname, values) => {
  if (e.key === 'ArrowUp' && index - 1 >= 0) {
    const element = document.getElementById(`items[${index - 1}].${fieldname}`);
    element.select();
    element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
  }
};

const renderSelectedItemsTable = (
  {
    values: { items, vendorId, objectId },
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    handleBlur,
    accountsList,
    currency,
    taxList,
    warehousesList,
    status,
    setStatus,
    purchaseOrderId,
    checkDirectBill,
    indirectBill,
    values,
    skipButton,
    ...props
  },
  billType,
  setShowAddItemButton
) => {
  const { itemsList } = status;
  // This Converts the String to array for Images in order for  ListTableImageColumn to Work
  if (flagOnce === 0) {
    items.forEach((item) => {
      if (item.images) {
        item.images = item.images.split(' ');
      }
    });
    flagOnce = 1;
  }

  const handleOnDragEnd = (result, newitems, setFieldValue) => {
    if (!result.destination) return;
    const items = Array.from(newitems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('items', items);
  };

  let primaryWarehouse = null;
  warehousesList.forEach(function (warehouse) {
    if (warehouse.is_primary) {
      primaryWarehouse = warehouse.id;
    }
  });
  const onChangeValueHandler = (val, index, currentItems) => {
    let newVal = handleExtraDescription(val, 254);
    let itemsList = [...currentItems];
    let currentItem = itemsList[index];
    itemsList[index] = {
      ...currentItem,
      extra_description: newVal,
    };
    setFieldValue('items', itemsList);
  };
  // let yo = JSON.parse(localStorage.getItem('yo')) || [];
  return (
    <FieldArray
      name="items"
      render={() => {
        return (
          <div className="so-tbletabs">
            <table
              className={`list-table form-focus form-table border horizontal-items-table bill bill-table-width create_package ${
                purchaseOrderId === null ? 'purchase_field' : ''
              }`}
            >
              {/* <table className="list-table form-table border horizontal-items-table bill bill-table-width"> */}
              <thead>
                <tr>
                  <th> Items</th>
                  <th> Account</th>
                  <th> Warehouse</th>
                  <th> Qty. to Bill</th>
                  {purchaseOrderId && <th> Qty. to be Billed</th>}
                  <th> Price</th>
                  <th> Tax</th>
                  <th className="right-align"> Total Amount</th>
                </tr>
              </thead>
              <DragDropItems
                handleOnDragEnd={(result) =>
                  handleOnDragEnd(result, items, setFieldValue)
                }
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.dragDropID}
                    draggableId={item.dragDropID}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        key={index}
                        className="table--row"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <td className={item.id ? '' : 'no-padding'}>
                          <div className="__dragDrop">
                            <div
                              {...provided.dragHandleProps}
                              className={
                                items.length >= 2
                                  ? '__dragDrop_iconParent'
                                  : '__dragDrop_iconParent notDisplay'
                              }
                              tabIndex="-1"
                            >
                              <MdDragIndicator />
                            </div>
                            <div className="__drag-righ">
                              {item.id ? (
                                <>
                                  <ListTableImageColumn
                                    name={
                                      item.name
                                        ? item.name
                                        : item.label.props?.children[1].props
                                            ?.children[0]
                                    }
                                    sku={item.sku}
                                    images={item.images}
                                    id={item.id ? item.id : item.value}
                                    showModalUponClick={
                                      purchaseOrderId ? false : true
                                    }
                                    value={item.extra_description}
                                    index={index}
                                    currentitems={items}
                                    showExtraDescription={true}
                                    onChangeValue={onChangeValueHandler}
                                  />
                                </>
                              ) : (
                                <>
                                  {!props.inModal && !indirectBill ? (
                                    <Field
                                      id="itemId"
                                      autoFocus={true}
                                      addButton={!checkDirectBill}
                                      alphabetSort
                                      type="DynamicPaginationDropdown"
                                      api="bills/items"
                                      value={item.id ? item.id : item.value}
                                      withImage={true}
                                      disabled={!vendorId}
                                      placeholder={
                                        vendorId
                                          ? 'Select Item'
                                          : 'Please select Vendor'
                                      }
                                      searchKeys={[
                                        'name',
                                        'sku',
                                        'upc',
                                        'mpn',
                                        'ean',
                                        'isbn',
                                      ]}
                                      clearable={false}
                                      itemsDropDown={true}
                                      handleChange={(option) =>
                                        handleSelectedItem(
                                          option,
                                          items,
                                          index,
                                          setFieldValue,
                                          status,
                                          setStatus,
                                          billType,
                                          setShowAddItemButton,
                                          purchaseOrderId,
                                          checkDirectBill,
                                          vendorId,
                                          objectId,
                                          primaryWarehouse
                                        )
                                      }
                                      error={getError(
                                        errors,
                                        touched,
                                        `items[${index}].name`
                                      )}
                                      onBlur={() =>
                                        setFieldTouched(
                                          `items[${index}].name`,
                                          true
                                        )
                                      }
                                    />
                                  ) : (
                                    <Field
                                      id="itemId"
                                      autoFocus={true}
                                      addButton={!checkDirectBill}
                                      alphabetSort
                                      type="PaginationDropdown"
                                      value={item.id}
                                      withImage={true}
                                      options={itemsList}
                                      dropdownValue="id"
                                      dropdownLabel="name"
                                      disabled={!vendorId}
                                      placeholder={
                                        vendorId
                                          ? 'Select Item'
                                          : 'Please select Vendor'
                                      }
                                      searchKeys={[
                                        'name',
                                        'sku',
                                        'upc',
                                        'mpn',
                                        'ean',
                                        'isbn',
                                      ]}
                                      clearable={false}
                                      itemsDropDown={true}
                                      onInputChange={(option) => {
                                        inputChange(
                                          itemsList,
                                          option,
                                          items,
                                          index,
                                          setFieldValue,
                                          status,
                                          setStatus,
                                          billType,
                                          setShowAddItemButton,
                                          purchaseOrderId,
                                          checkDirectBill,
                                          vendorId,
                                          objectId,
                                          primaryWarehouse
                                        );
                                      }}
                                      handleChange={(option) =>
                                        handleSelectedItem(
                                          option,
                                          items,
                                          index,
                                          setFieldValue,
                                          status,
                                          setStatus,
                                          billType,
                                          setShowAddItemButton,
                                          purchaseOrderId,
                                          checkDirectBill,
                                          vendorId,
                                          objectId,
                                          primaryWarehouse
                                        )
                                      }
                                      error={getError(
                                        errors,
                                        touched,
                                        `items[${index}].name`
                                      )}
                                      onBlur={() =>
                                        setFieldTouched(
                                          `items[${index}].name`,
                                          true
                                        )
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="no-padding">
                          {renderAccountSelect(
                            items,
                            item,
                            index,
                            setFieldValue,
                            handleBlur,
                            accountsList,
                            errors,
                            touched,
                            getError,
                            skipButton
                          )}
                        </td>

                        <td className="no-padding">
                          <Field
                            id="warehouseId"
                            type="dropdown"
                            placeholder="Select"
                            value={
                              item.warehouseId
                                ? item.warehouseId
                                : primaryWarehouse
                            }
                            options={warehousesList}
                            dropdownValue="id"
                            dropdownLabel="name"
                            clearable={false}
                            disabled={
                              item.purchaseOrderItemDetailId ||
                              item.inventory_type === 'noninventory'
                            }
                            required
                            handleChange={(option) => {
                              setFieldValue(
                                `items[${index}].warehouseId`,
                                option ? option.value : null
                              );
                              setFieldValue('warehouse_update', true);
                            }}
                          />
                        </td>

                        <td className="right-align no-padding bill_error">
                          <Field
                            size="small"
                            type="number"
                            value={item?.quantity}
                            decimalScale={6}
                            id={`items[${index}].quantity`}
                            allowNegative={false}
                            thousandSeparator=","
                            onValueChange={(value) =>
                              handleItemQuantityChange(
                                value,
                                index,
                                items,
                                setFieldValue
                              )
                            }
                            error={getError(
                              errors,
                              touched,
                              `items[${index}].quantity`
                            )}
                            onBlur={handleBlur}
                            onKeyDown={(e) =>
                              handleArrowsKey(e, index, 'quantity', values)
                            }
                            onKeyUp={(e) =>
                              handleArrowUpKey(e, index, 'quantity', values)
                            }
                          />

                          {/* <div className="table-extra-data right-align">
                        <div style={{ 'margin-top': '1.3rem' }}>
                          { item.maxBillableQuantity ? 'Quantity to bill: ' : '' }{item.maxBillableQuantity} {item.unit}
                        </div>
                      </div>*/}
                        </td>
                        {purchaseOrderId && (
                          <td className="right-align no-padding">
                            <div className="float-left w-100">
                              {item.maxBillableQuantity}
                            </div>{' '}
                            <div
                              className="float-left w-100 ft_10 table-extra-data"
                              style={{ paddingRight: '0' }}
                            >
                              {item.unit}
                            </div>
                          </td>
                        )}

                        <td className="right-align no-padding bill_error">
                          <Field
                            size="small"
                            type="number"
                            value={item?.unitPrice}
                            decimalScale={6}
                            id={`items[${index}].unitPrice`}
                            prefix={currency.symbol}
                            allowNegative={false}
                            thousandSeparator=","
                            onValueChange={(value) =>
                              handleItemPriceChange(
                                value,
                                index,
                                items,
                                setFieldValue
                              )
                            }
                            error={getError(
                              errors,
                              touched,
                              `items[${index}].unitPrice`
                            )}
                            onBlur={handleBlur}
                            onKeyDown={(e) =>
                              handleArrowsKey(e, index, 'unitPrice', values)
                            }
                            onKeyUp={(e) =>
                              handleArrowUpKey(e, index, 'unitPrice', values)
                            }
                          />

                          {!!item.previousPurchasePrice && (
                            <ToolTip
                              content="Click to set this price"
                              className="bill-prev-purchase"
                            >
                              <div className="table-extra-data right-align">
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleChangeprevPrice(
                                      items,
                                      setFieldValue,
                                      item?.previousPurchasePrice,
                                      index
                                    )
                                  }
                                >{`Prev Purchase: ${currency.symbol}${item.previousPurchasePrice}`}</div>
                              </div>
                            </ToolTip>
                          )}
                        </td>

                        <td className="no-padding">
                          <Field
                            size="small"
                            type="dropdown"
                            placeholder="Select"
                            value={item.tax?.id}
                            options={taxList}
                            dropdownValue="id"
                            dropdownLabel="name"
                            handleChange={(option) =>
                              handleTaxChange(
                                option,
                                index,
                                items,
                                setFieldValue
                              )
                            }
                          />
                        </td>

                        <td
                          style={{ textAlign: 'right' }}
                          className="list-table--amount right-align"
                        >
                          <span>
                            {currency.symbol}
                            {parseFloat(
                              getItemTotalAmount(item.quantity, item.unitPrice)
                            ).toFixed(2)}
                          </span>
                          <button
                            type="button"
                            disabled={
                              items.length === 1 && items[0].id === null
                            }
                            className="close-btn button"
                            onClick={() =>
                              handleRemoveItem(
                                index,
                                items,
                                item,
                                setFieldValue,
                                status,
                                setStatus,
                                billType,
                                setShowAddItemButton,
                                purchaseOrderId,
                                props.emptyItem
                              )
                            }
                          >
                            <DeleteIcon className="bin" />
                          </button>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </DragDropItems>
            </table>
          </div>
        );
      }}
    />
  );
};

const renderFooter = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  currency,
}) => {
  const handleAdjustmentChange = (adjustmentValue) => {
    let adjustment = adjustmentValue.floatValue;
    if (adjustment > 0) {
      let adjustmentString = adjustment.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (adjustmentString.length > 10) {
        adjustmentString = adjustmentString.slice(0, 10);
      }
      //adjustment = parseFloat(adjustmentString).toFixed(2)
      adjustment = adjustmentString;
    }
    setFieldValue('adjustment', adjustment);
  };

  return (
    <div
      className="float-left w-100 mt_3 summary_table"
      style={{ paddingRight: '0%' }}
    >
      <hr className="margin float-left w-100"></hr>
      <div className="float-left w-70 p_10"></div>
      <div className="float-left w-30 p_10 pr-0">
        <div className="float-left w-100 so-mg-1">
          <span className="float-left">Sub Total </span>
          <span className="float-right bold">{`${currency.symbol}${parseFloat(
            getSubtotal(values.items)
          ).toFixed(2)}`}</span>
        </div>

        {/* <hr className='margin' /> */}
        <div className="float-left w-100">
          {renderTotalTax(values.items, currency.symbol)}
        </div>
        <div className="float-left w-100">
          {renderTransactionLevelDiscount(
            values,
            currency,
            setFieldValue,
            errors,
            touched,
            handleBlur
          )}
        </div>

        <div className="float-left w-100">
          <span className="float-left pr">Adjustment</span>
          <Field
            type="number"
            className="float-right bold discount_switch_summary_table"
            value={parseFloat(values.adjustment).toFixed(2)}
            decimalScale={2}
            placeholder={`${currency.symbol}0.00`}
            allowNegative
            id="adjustment"
            prefix={currency.symbol}
            thousandSeparator=","
            //onValueChange={value => setFieldValue('adjustment', value.floatValue)}
            onValueChange={(value) => handleAdjustmentChange(value)}
            onBlur={handleBlur}
            error={touched.adjustment && errors.adjustment}
            removeValueOnClick
          />
        </div>
      </div>

      <hr className="margin float-left w-100" />
      <div className="float-left w-100">
        <div className="float-left w-70 p_10"></div>
        <div className="baseline float-left w-30 px_10">
          <span className="semi-bold float-left pr_total">Total</span>
          <span className="total-price--value float-right mr-1">{`${
            currency.symbol
          }${parseFloat(getOrderTotal(values)).toFixed(2)}`}</span>
        </div>
      </div>
      <hr className="margin float-left w-100" />
      <hr className="margin float-left w-100" />
    </div>
  );
};

const renderTotalTax = (items, currencySymbol) => {
  let overallTax = getOverallTaxDetails(items);
  return overallTax.map((tax, index) => (
    <div key={index} className="float-left w-100 so-mg-1">
      <span>{`${tax.name} (${tax.rate}%)`}</span>
      <span className="float-right bold">{`${currencySymbol}${parseFloat(
        tax.total
      ).toFixed(2)}`}</span>
    </div>
  ));
};

const renderTransactionLevelDiscount = (
  { transactionDiscountType, transactionDiscount },
  currency,
  setFieldValue,
  errors,
  touched,
  handleBlur
) => {
  const handleItemDiscountChange = (value) => {
    let discount = value.floatValue;
    if (discount > 0) {
      let discountString = discount.toString();
      if (transactionDiscountType === 'percent') {
        let sliceStrLen = discountString.includes('.') ? 5 : 3;
        if (discountString.length > sliceStrLen) {
          discountString = discountString.slice(0, sliceStrLen);
        }
        discount = parseFloat(discountString);
      } else {
        if (discountString.length > 10) {
          discountString = discountString.slice(0, 10);
        }
        discount = parseFloat(discountString);
      }
    }
    setFieldValue('transactionDiscount', discount);
  };
  return (
    <div className="float-left w-100">
      <span className="float-left pr">Discount</span>

      <Field
        type="number"
        className="float-right bold discount_switch_summary_table"
        value={parseFloat(transactionDiscount).toFixed(2)}
        decimalScale={2}
        id="transactionDiscount"
        rightData={
          <Switch
            options={getDiscountOptions(currency)}
            value={transactionDiscountType}
            handleChange={(value) => {
              setFieldValue('transactionDiscountType', value.value);
              if (transactionDiscountType === 'percent') {
                setFieldValue('transaction_discount_type', true);
              } else {
                setFieldValue('transaction_discount_type', false);
              }
            }}
          />
        }
        allowNegative={false}
        thousandSeparator=","
        onValueChange={(value) => handleItemDiscountChange(value)}
        onBlur={handleBlur}
        error={touched.transactionDiscount && errors.transactionDiscount}
        removeValueOnClick
      />
    </div>
  );
};

const renderAccountSelect = (
  currentItems,
  item,
  index,
  setFieldValue,
  handleBlur,
  accountsList,
  errors,
  touched,
  getError,
  skipButton
) => {
  return (
    <Field
      size="medium"
      type="dropdown"
      dropdownValue="id"
      dropdownLabel="title"
      value={item?.accountId}
      placeholder="Select Account"
      options={
        skipButton
          ? accountsList?.expense
          : item?.inventory_type === 'inventory'
          ? accountsList?.inventory
          : accountsList?.expense
      }
      handleChange={(option) =>
        option
          ? handleChangeAccount(
              option.value,
              currentItems,
              item,
              index,
              setFieldValue
            )
          : null
      }
      error={getError(errors, touched, `items[${index}].accountId`)}
      onBlur={handleBlur}
      clearable={false}
    />
  );
};
const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      requestType,
      requestUrl,
      bill,
      successMessage,
      handleToast,
      onSubmit,
      history,
      type,
      warehousesList,
      inModal,
      handleTotalCheck,
      setPrompt,
      onClose,
    },
  }
) => {
  // requestType1 = requestType
  const total = getOrderTotal(values);
  if (parseInt(total) === 0 && !totalConfirmCheck) {
    handleTotalCheck();
    setSubmitting(false);
    return;
  }
  let inActiveWarehouse = values.items.some((item) => {
    return warehousesList.find(
      (list) => list.is_active === 0 && list.id === item.warehouseId
    );
  });
  if (inActiveWarehouse === true) {
    setSubmitting(false);
    return handleToast('Cannot use inactive warehouse', 'error');
  }
  restRequest(requestType, requestUrl, {
    bill_no: values.billNo,
    order_no: values.orderNo,
    vendor_id: values.vendorId,
    note: values.note,
    bill_date: values.billDate && values.billDate.format('YYYY-MM-DD 00:00:00'),
    due_date: values.dueDate && values.dueDate.format('YYYY-MM-DD 23:59:59'),
    terms_and_condition: values.hasTermsAndConditions
      ? values.termsAndConditions
      : '',
    items: values.items.map((item, index) => ({
      id: item.id,
      sort_order: index,
      item_name: item.name,
      purchase_order_item_detail_id: item.purchaseOrderItemDetailId,
      bill_item_detail_id: item.billItemDetailId,
      warehouse_id: item.warehouseId,
      account_id: item.accountId,
      quantity: item.quantity,
      rate: item.unitPrice,
      extra_description: item.extra_description ? item.extra_description : null,
      tax_id: item?.tax?.id,
    })),
    discount_type: values.transactionDiscountType,
    discount_transaction_level: values.transactionDiscount || 0,
    adjustment: values.adjustment,
  })
    .then((res) => {
      setPrompt(false);
      totalConfirmCheck = false;
      handleTotalCheck(false);
      let dataFromLS = getObjFromLS('module');
      if (dataFromLS === null) {
        let obj = {
          prevId: null,
          id: null,
          nextId: null,
          moduleName: 'bills',
          urlPath: '/bills',
          queryParam: window.location.search,
        };
        setObjInLS('module', obj);
        dataFromLS = getObjFromLS('module');
        history.push('/r');
      }
      if (inModal) {
        successMessage();
        // onSubmit()
        // return
        // let dataFromLS = getObjFromLS('module')
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'purchaseorders',
          queryParam: `${
            dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
          }bills=true`,
        });
        // history.go();
        if (dataFromLS?.moduleName) history.push('/r');
        else history.push('/purchaseorders');
        sessionStorage.setItem('fullscreen', false);
        return;
      }
      successMessage();
      if (
        window.location.pathname.includes('add') ||
        window.location.pathname.includes('edit')
      ) {
        // sessionStorage.setItem('fullscreen', false)
        // let dataFromLS = getObjFromLS('module')
        // setObjInLS('module', {...dataFromLS,moduleName:"bills", action: type === 'add' ? 'add' : 'justedit', queryParam: "" , })
        if (type === 'add') {
          setObjInLS('module', {
            ...dataFromLS,
            urlPath: '/bills',
            moduleName: 'bills',
            action: type === 'add' ? 'add' : 'justedit',
            queryParam: '',
          });
        } else if (type === 'edit') {
          setObjInLS('module', {
            ...dataFromLS,
            urlPath: '/bills',
            moduleName: 'bills',
            action: type === 'add' ? 'add' : 'justedit',
            id: bill.objectId,
            queryParam: dataFromLS.queryParam ? dataFromLS.queryParam : '',
          });
        }
        history.push('/r');
        sessionStorage.setItem('fullscreen', false);
        // onSubmit()
      } else {
        // let dataFromLS = getObjFromLS('module')
        setObjInLS('module', {
          ...dataFromLS,
          moduleName: 'bills',
          action: type === 'add' ? 'add' : 'justedit',
          queryParam: '',
        });
        return history.push('/r');
      }
    })
    .catch((error) => {
      checkError(error);
      handleToast(error, 'error');
      setSubmitting(false);
    });
  // } else {
  //   setSubmitting(false);
  // }
};

const handleItemQuantityChange = (
  values,
  index,
  currentItems,
  setFieldValue
) => {
  let quantity = values.floatValue;
  if (quantity > 0) {
    let quantString = quantity.toString();

    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    if (quantString.length > 10) {
      quantString = quantString.slice(0, 10);
    }
    quantity = parseInt(quantString);
  }
  let itemsList = [...currentItems];
  let currentItem = itemsList[index];
  itemsList[index] = {
    ...currentItem,
    quantity,
    taxAmount: !!currentItem?.tax?.id
      ? getTaxAmount(quantity, currentItem.unitPrice, currentItem.tax.rate)
      : 0,
    total: getItemTotalAmount(quantity, currentItem.unitPrice),
  };

  setFieldValue('items', itemsList);
};

const handleChangeprevPrice = (items, setFieldValue, prevPrice, index) => {
  let itemList = [...items];
  let currentItem = itemList[index];
  itemList[index] = {
    ...currentItem,
    unitPrice: prevPrice,
  };
  setFieldValue('items', itemList);
};

const handleItemPriceChange = (values, index, currentItems, setFieldValue) => {
  let unitPrice = values.floatValue;
  if (unitPrice > 0) {
    let unitPriceString = unitPrice.toString();

    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    if (unitPriceString.length > 10) {
      unitPriceString = unitPriceString.slice(0, 10);
    }
    unitPrice = parseFloat(unitPriceString);
  }
  let itemsList = [...currentItems];
  let currentItem = itemsList[index];
  itemsList[index] = {
    ...currentItem,
    unitPrice,
    taxAmount: !!currentItem?.tax?.id
      ? getTaxAmount(currentItem.quantity, unitPrice, currentItem.tax.rate)
      : 0,
    total: getItemTotalAmount(currentItem.quantity, unitPrice),
  };
  setFieldValue('items', itemsList);
};

const handleChangeAccount = (
  value,
  currentItems,
  item,
  index,
  setFieldValue
) => {
  let billItems = [...currentItems];
  billItems[index] = { ...item, accountId: value };
  setFieldValue('items', billItems);
};
const getPreviousSellingPrice = async (
  contactId,
  item,
  status,
  setStatus,
  objectId
) => {
  // return item

  if (!contactId) {
    return item;
  } else {
    let updatedItem = { ...item };
    setStatus({
      ...status,
      loadingItems: true,
    });
    let POPURL = null;
    if (objectId !== null) {
      POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${contactId}&item_id=${
        item.id ? item.id : item.value
      }&object=bill&object_id=${objectId}`;
    } else {
      POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${contactId}&item_id=${
        item.id ? item.id : item.value
      }`;
    }

    await restRequest('get', POPURL).then((previousPrice) => {
      updatedItem = {
        ...item,
        previousPurchasePrice: previousPrice ? previousPrice : 0,
      };
    });
    return updatedItem;
  }
};

const inputChange = async (
  itemsList,
  val,
  items,
  index,
  setFieldValue,
  status,
  setStatus,
  billType,
  setShowAddItemButton,
  purchaseOrderId,
  checkDirectBill,
  vendorId,
  objectId,
  primaryWarehouse
) => {
  var item = getFilteredItem(val, itemsList);
  if (item && item.length === 1) {
    handleSelectedItem(
      item[0],
      items,
      index,
      setFieldValue,
      status,
      setStatus,
      billType,
      setShowAddItemButton,
      purchaseOrderId,
      checkDirectBill,
      vendorId,
      objectId,
      primaryWarehouse
    );
  }
};

const handleSelectedItem = async (
  option,
  items,
  index,
  setFieldValue,
  status,
  setStatus,
  billType,
  setShowAddItemButton,
  purchaseOrderId,
  checkDirectBill,
  vendorId,
  objectId,
  primaryWarehouse
) => {
  if (option.value === 'createNew') {
    setStatus({ ...status, showItemModal: true });
  } else {
    loadingSelectItem = true;
    let newItem = {
      ...option,
      accountId:
        option.inventory_type === 'inventory'
          ? option?.inventory_account_id
          : option?.purchase_account_id,
      dragDropID: GetDateString(),
      quantity: items[index]?.quantity,
      warehouseId: primaryWarehouse,
      unitPrice: option?.purchase_unit_price || option?.unitPrice,
      previousPurchasePrice: 0,
    };

    // const updatedItems = [...Object.assign([...items], { [index]: newItem })];
    setShowAddItemButton(true);
    // setFieldValue('items', updatedItems)
    let updatedNewItem = await getPreviousSellingPrice(
      vendorId,
      newItem,
      status,
      setStatus,
      objectId
    );
    loadingSelectItem = false;

    // const updatedItems = [...Object.assign([...items], { [index]: newItem })]
    setFieldValue(`items[${index}]`, { ...updatedNewItem });

    if (billType === 'edit' && checkDirectBill !== false) {
      setStatus({
        ...status,
        itemsList: status.itemsList.filter(
          (item) => item.billItemDetailId !== option.billItemDetailId
        ),
      });
      return;
    }
    // fixed this for SIQ-3631 : Bill: Unable to add Untracked item again while adding Direct bill.
    //if (option.inventory_type === "noninventory" || purchaseOrderId)
    if (purchaseOrderId) {
      setStatus({
        ...status,
        itemsList: status.itemsList.filter(
          (item) =>
            item.purchaseOrderItemDetailId !== option.purchaseOrderItemDetailId
        ),
      });
      loadingSelectItem = false;
    }
  }
};

const handleTaxChange = (option, index, currentItems, setFieldValue) => {
  let itemsList = [...currentItems];
  let currentItem = itemsList[index];
  if (option === null) {
    itemsList[index] = {
      ...currentItem,
      tax: {
        id: null,
        name: '',
        rate: 0,
      },
      taxAmount: 0,
    };
  } else {
    itemsList[index] = {
      ...currentItem,
      tax: {
        id: option.id,
        name: option.name,
        rate: option.rate,
      },
      taxAmount: getTaxAmount(
        currentItem.quantity,
        currentItem.unitPrice,
        option.rate
      ),
    };
  }
  setFieldValue('items', itemsList);
};

const deletePurchaseOrderFromList = (option = {}, status, setStatus) => {
  let { purchaseOrderItemDetailId = null, id = '' } = option;
  let modifiledSalesOrderList = status.orgissuePurchaseOrderList;
  modifiledSalesOrderList = modifiledSalesOrderList.filter((purchaseOrder) => {
    return (
      id === purchaseOrder.purchase_order_item_details[0].item_id &&
      purchaseOrderItemDetailId ===
        purchaseOrder.purchase_order_item_details[0]
          .purchase_order_item_detail_id
    );
  });
  setStatus({
    ...status,
    issuedPurchaseOrdersList: [
      ...modifiledSalesOrderList,
      ...status.issuedPurchaseOrdersList,
    ],
    selectedIssuedPurchaseOrderIds: [],
    selectedIssuedPurchaseOrderIds:
      status.selectedIssuedPurchaseOrderIds.filter(
        (id) => id !== purchaseOrderItemDetailId
      ),
  });
};

const handleRemoveItem = (
  removeIndex,
  items,
  option,
  setFieldValue,
  status,
  setStatus,
  billType,
  setShowAddItemButton,
  purchaseOrderId,
  emptyItem
) => {
  deletePurchaseOrderFromList(option, status, setStatus);
  const getitemid = items.map((item) => {
    return item.id;
  });
  items.length === 1 && items.push(emptyItem);
  let updatedItems = items.filter((_, index) => index !== removeIndex);

  setShowAddItemButton(true);
  setFieldValue('items', updatedItems);
  if (billType === 'edit') {
    if (status.itemsList.find((item) => item.sku === option.sku)) {
      return;
    } else {
      setStatus({
        ...status,
        itemsList: [...status.itemsList, option],
      });
      return;
    }
  }
  if (
    (option.id != null && option.inventory_type === 'noninventory') ||
    purchaseOrderId
  ) {
    setStatus({
      ...status,
      itemsList: [...status.itemsList, option],
    });
  }
};

export const getTaxAmount = (quantity, unitPrice, rate) => {
  return parseFloat(((quantity * unitPrice * rate) / 100).toFixed(2));
};

export const getItemTotalAmount = (quantity, unitPrice) => {
  let total = quantity * unitPrice;
  // totalAmount=total
  return parseFloat(total.toFixed(2)) || 0;
};

export const getSubtotal = (itemsList) => {
  return parseFloat(
    itemsList
      .reduce(
        (total, item) =>
          total + getItemTotalAmount(item.quantity, item.unitPrice),
        0
      )
      .toFixed(2)
  );
};

const getTotalTax = (items) => {
  let overallTax = getOverallTaxDetails(items);
  return overallTax.reduce((total, tax) => {
    return total + tax.total;
  }, 0);
};

const getOrderTotal = ({
  items,
  adjustment = 0,
  transactionDiscountType,
  transactionDiscount = 0,
}) => {
  let subTotal = getSubtotal(items);
  let taxTotal = getTotalTax(items);

  if (transactionDiscountType === 'percent') {
    let totalTransactionDiscount = (subTotal * transactionDiscount) / 100;
    totalAmount = parseFloat(
      parseFloat(subTotal) +
        parseFloat(taxTotal) +
        +adjustment -
        parseFloat(totalTransactionDiscount)
    ).toFixed(2);
    return parseFloat(totalAmount).toFixed(2);
  } else if (transactionDiscountType === 'amount') {
    totalAmount = parseFloat(
      parseFloat(subTotal) +
        parseFloat(taxTotal) +
        +adjustment -
        (parseFloat(transactionDiscount) >= 0 &&
          parseFloat(transactionDiscount))
    ).toFixed(2);
    return parseFloat(totalAmount).toFixed(2);
  } else {
    totalAmount = parseFloat(
      parseFloat(subTotal) + parseFloat(taxTotal) + +adjustment
    ).toFixed(2);
    return parseFloat(totalAmount).toFixed(2);
  }
};

export default formEnhancer(BillForm);
