import React, { Component, Fragment } from 'react';

import SearchableDropdown from '../SearchableDropdown/SearchableDropdown';
import FormSection from '../Form/FormSection/FormSection';
import FormAction from '../Form/FormAction';
import MatchingTable from '../Table/MatchingTable';
import Loader from '../../Components/Loader/Loader';

class AddFields extends Component {
  state = {
    options: this.props.options,
    csvLabels: this.props.csvLabels,
    csvFile: this.props.csvFile,
    dropdownOptions: {},
    disableNext: false,
    importType: this.props.importType,
    isLoading: false,
  };

  componentDidMount() {
    this.setState(
      {
        csvLabels: this.trimCSVLabels(),
      },
      () => {
        this.setDropdownOptions();
      }
    );
  }

  trimCSVLabels() {
    return this.state.csvLabels.map((csvLabel) => csvLabel.trim());
  }

  setDropdownOptions() {
    let { options, csvLabels, importType } = this.state;
    let tempDropdownOptions = {};
    var selected = null;
    for (let option of options) {
      if (importType !== 'zoho') {
        if (importType !== 'quickbookc' && importType !== 'quickbookr') {
          if (csvLabels.includes(option.label)) {
            tempDropdownOptions[option.label] = option.label;
          } else {
            tempDropdownOptions[option.label] = null;
          }
        } else {
          if (csvLabels[0] === 'Product/Service Name') {
            if (importType === 'quickbookc' || importType === 'quickbookr') {
              if (option.label === 'Item Name') {
                tempDropdownOptions[option.label] = csvLabels[0];
              }

              if (option.label === 'Unit') {
                tempDropdownOptions[option.label] = 'Piece';
              }

              if (option.label === 'Selling Price') {
                tempDropdownOptions[option.label] = 'Sales Price / Rate';
              }

              if (option.label === 'Sales Account') {
                tempDropdownOptions[option.label] = 'Income Account';
              }

              if (option.label === 'Purchase Price') {
                tempDropdownOptions[option.label] = 'Purchase Cost';
              }

              if (option.label === 'Purchase Account') {
                tempDropdownOptions[option.label] = 'Expense Account';
              }

              /*if (option.label === "Opening Stock Value") {
                tempDropdownOptions[option.label] = "Purchase Cost"
              }*/

              if (option.label === 'Reorder Level') {
                tempDropdownOptions[option.label] = 'Reorder Point';
              }

              if (option.label === 'Opening Stock') {
                tempDropdownOptions[option.label] = 'Quantity On Hand';
              }

              if (csvLabels.includes(option.label)) {
                tempDropdownOptions[option.label] = option.label;
              } /*else{
                tempDropdownOptions[option.label] = null
              }*/
            }
          } else {
            if (importType === 'quickbookc' || importType === 'quickbookr') {
              if (
                option.label === 'First Name' ||
                option.label === 'Display Name' ||
                option.label === 'Billing Attention' ||
                option.label === 'Shipping Attention'
              ) {
                tempDropdownOptions[option.label] = csvLabels[0];
              }

              if (option.label === 'Currency Code') {
                tempDropdownOptions[option.label] = 'US Dollar';
              }

              if (option.label === 'Email') {
                tempDropdownOptions[option.label] = 'Email';
              }

              if (option.label === 'Contact Type') {
                tempDropdownOptions[option.label] = csvLabels[0];
              }
            }

            if (importType === 'quickbookc') {
              if (option.label === 'Company Name') {
                tempDropdownOptions[option.label] = 'Company';
              }
              if (option.label === 'Note') {
                tempDropdownOptions[option.label] = 'Notes';
              }

              if (
                option.label === 'Work Phone' ||
                option.label === 'Mobile' ||
                option.label === 'Billing Phone' ||
                option.label === 'Shipping Phone'
              ) {
                tempDropdownOptions[option.label] = 'Phone';
              }

              if (
                option.label === 'Billing Street' ||
                option.label === 'Shipping Street'
              ) {
                tempDropdownOptions[option.label] = 'Street Address';
              }

              if (
                option.label === 'Billing City' ||
                option.label === 'Shipping City'
              ) {
                tempDropdownOptions[option.label] = 'City';
              }

              if (
                option.label === 'Billing State' ||
                option.label === 'Shipping State'
              ) {
                tempDropdownOptions[option.label] = 'State';
              }

              if (
                option.label === 'Billing Country' ||
                option.label === 'Shipping Country'
              ) {
                tempDropdownOptions[option.label] = 'Country';
              }

              if (
                option.label === 'Billing Zip Code' ||
                option.label === 'Shipping Zip Code'
              ) {
                tempDropdownOptions[option.label] = 'Postal Code';
              }
            } else {
              if (option.label === 'Company Name') {
                tempDropdownOptions[option.label] = csvLabels[0];
              }

              if (
                option.label === 'Work Phone' ||
                option.label === 'Mobile' ||
                option.label === 'Billing Phone' ||
                option.label === 'Shipping Phone'
              ) {
                tempDropdownOptions[option.label] = 'Phone Numbers';
              }

              if (option.label === 'Billing Street') {
                tempDropdownOptions[option.label] = 'Billing Address';
              }

              if (option.label === 'Shipping Street') {
                tempDropdownOptions[option.label] = 'Shipping Address';
              }
            }
          }
        }
      } else {
        if (csvLabels.includes(option.label)) {
          tempDropdownOptions[option.label] = option.label;
        } else {
          //var selected = null;
          csvLabels.forEach((val) => {
            if (val.match(option.label)) {
              selected = val;
            }
          });
          if (selected !== null) {
            tempDropdownOptions[selected] = selected;
          } else {
            tempDropdownOptions[option.label] = null;
          }
        }
        //console.log("option.label", option.label);
        if (option.label === 'Work Phone') {
          tempDropdownOptions[option.label] = 'Phone';
        }

        if (tempDropdownOptions['Contact Type'] === null) {
          tempDropdownOptions['Contact Type'] = 'Supplier';
        }
      }
    }
    //console.log('tempDropdownOptions', tempDropdownOptions);
    this.setState({ dropdownOptions: tempDropdownOptions });
  }

  handleFields = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    // this.setState({
    //   disableNext: true
    // })
    this.props.handleImportFields(this.state.dropdownOptions);
    let result = !Object.values(this.state.dropdownOptions).some(
      (o) => o === null || o === ''
    );
    if (result === false) {
      this.setState({
        disableNext: false,
        isLoading: false,
      });
    }
  };

  handleDropdownChange(label, option) {
    if (option !== null) {
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          [label]: option.value,
        },
      });
    } else {
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          [label]: null,
        },
      });
    }
  }

  renderFileName() {
    return (
      <section>
        <div className="subtitle">Selected File</div>
        <div>{this.state.csvFile.name}</div>
      </section>
    );
  }

  renderContent() {
    let { options, csvLabels, dropdownOptions, importType } = this.state;
    return (
      <section>
        <div className="subtitle">Field Mapping Table</div>
        <div>
          Fields were automatically matched with the imported file headers
        </div>

        <MatchingTable
          headers={['SeeBiz Inventory Fields', 'Imported File Headers']}
          data={options.map((option, key) => {
            //console.log("dropdownOptions", csvLabels)
            const data1 = (
              <div>
                <span>{option.label}</span>
                {option.required && <span className="red"> *</span>}
              </div>
            );
            var selectSet = null;
            var data2 = null;
            var label = '';
            if (
              (importType === 'quickbookc' || importType === 'quickbookr') &&
              option.label === 'Contact Type'
            ) {
              if (this.state.csvLabels[0] === 'Customer') {
                data2 = (
                  <SearchableDropdown
                    size="large"
                    state="Customer"
                    options={['Customer']}
                    value="Customer"
                    disabled={true}
                    label="label"
                    handleChange={(dropdownOption) =>
                      this.handleDropdownChange(option.label, dropdownOption)
                    }
                  />
                );
              } else {
                data2 = (
                  <SearchableDropdown
                    size="large"
                    state="Supplier"
                    options={['Supplier']}
                    value="Supplier"
                    disabled={true}
                    label="label"
                    handleChange={(dropdownOption) =>
                      this.handleDropdownChange(option.label, dropdownOption)
                    }
                  />
                );
              }
            } else if (option.label === 'Contact Type') {
              var state = null;
              label = option.label;
              csvLabels.forEach(function (lab) {
                if (label.includes(lab)) {
                  state = lab;
                  return false;
                }
              });

              if (state !== null) {
                data2 = (
                  <SearchableDropdown
                    size="large"
                    state={state}
                    options={csvLabels}
                    value="value"
                    disabled={true}
                    label="label"
                    handleChange={(dropdownOption) =>
                      this.handleDropdownChange(option.label, dropdownOption)
                    }
                  />
                );
              } else if (state === null) {
                data2 = (
                  <SearchableDropdown
                    size="large"
                    state="Supplier"
                    options={['Supplier']}
                    value="Supplier"
                    disabled={true}
                    label="label"
                    handleChange={(dropdownOption) =>
                      this.handleDropdownChange(option.label, dropdownOption)
                    }
                  />
                );
              }
            } else if (
              (importType === 'quickbookc' || importType === 'quickbookr') &&
              option.label === 'Currency Code'
            ) {
              data2 = (
                <SearchableDropdown
                  size="large"
                  state="US Dollar"
                  options={['US Dollar']}
                  value="US Dollar"
                  disabled={true}
                  label="label"
                  handleChange={(dropdownOption) =>
                    this.handleDropdownChange(option.label, dropdownOption)
                  }
                />
              );
            } else {
              var statesel = null;
              var Type = null;
              csvLabels.forEach(function (Sellabel) {
                if (Sellabel.match('Customer')) {
                  Type = 'Customer';
                  return false;
                } else if (Sellabel.match('Supplier')) {
                  Type = 'Supplier';
                  return false;
                }
              });
              if (option.label === 'Company Name') {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  statesel = Type;
                  selectSet = 1;
                }
              } else if (
                option.label === 'Display Name' ||
                option.label === 'First Name' ||
                option.label === 'Billing Attention' ||
                option.label === 'Shipping Attention'
              ) {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  statesel = Type;
                  selectSet = 1;
                }
              } else if (
                option.label === 'Billing Street' ||
                option.label === 'Shipping Street'
              ) {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (label.includes('Billing')) {
                      if (lab.includes('Billing Address')) {
                        statesel = lab;
                        selectSet = 1;
                        return false;
                      }
                    } else if (label.includes('Shipping')) {
                      if (lab.includes('Shipping Address')) {
                        statesel = lab;
                        selectSet = 1;
                        return false;
                      }
                    }
                  });
                }
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('Address')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  });
                }
              } else if (option.label === 'Mobile') {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('Mobile')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  });
                }
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('Phone')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  });
                }
              } else if (
                option.label === 'Work Phone' ||
                option.label === 'Billing Phone' ||
                option.label === 'Shipping Phone'
              ) {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('Phone')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  });
                }
              } else if (option.label === 'Note') {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('Notes')) {
                      statesel = 'Notes';
                      selectSet = 1;
                      return false;
                    }
                  });
                }
              } else if (option.label === 'Email') {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('Email')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  });
                }
              } else if (option.label === 'Skype') {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('Skype')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  });
                }
              } else if (
                option.label === 'Billing Zip Code' ||
                option.label === 'Shipping Zip Code'
              ) {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
                csvLabels.forEach(function (lab) {
                  if (label.includes('Billing')) {
                    if (lab.includes('Billing Code')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  } else if (label.includes('Shipping')) {
                    if (lab.includes('Shipping Code')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  }
                });
                if (statesel === null) {
                  csvLabels.forEach(function (lab) {
                    if (lab.includes('code')) {
                      statesel = lab;
                      selectSet = 1;
                      return false;
                    }
                  });
                }
              } else {
                label = option.label;
                csvLabels.forEach(function (lab) {
                  if (label.includes(lab)) {
                    statesel = lab;
                    selectSet = 1;
                    return false;
                  }
                });
              }

              if (statesel === null) {
                statesel = dropdownOptions[option.label];
              }
              if (
                csvLabels.includes(option.label) === true ||
                selectSet !== null
              ) {
                data2 = (
                  <SearchableDropdown
                    size="large"
                    state={statesel}
                    options={csvLabels}
                    disabled={true}
                    value="value"
                    label="label"
                    handleChange={(dropdownOption) =>
                      this.handleDropdownChange(option.label, dropdownOption)
                    }
                  />
                );
              } else {
                data2 = (
                  <SearchableDropdown
                    size="large"
                    state={statesel}
                    options={csvLabels}
                    value="value"
                    label="label"
                    handleChange={(dropdownOption) =>
                      this.handleDropdownChange(option.label, dropdownOption)
                    }
                  />
                );
              }
            }
            return { data1, data2 };
          })}
        />
      </section>
    );
  }

  render() {
    return this.state.isLoading ? (
      <Fragment>
        <div className="import_summary_loader">
          <Loader />{' '}
          <div>
            Please wait while{' '}
            {window.location.pathname.includes('contacts') && 'contacts'}{' '}
            {window.location.pathname.includes('items') && 'items'} are being
            mapped...
          </div>
        </div>
      </Fragment>
    ) : (
      <FormSection title="Map Fields">
        <form onSubmit={this.handleFields}>
          {this.renderFileName()}
          {this.renderContent()}
          <FormAction
            submitLabel="Next"
            cancelLabel="Go Back"
            disabled={this.state.disableNext}
            onCancel={() => this.props.previousStep()}
          />
        </form>
      </FormSection>
    );
  }
}

export default AddFields;
