import React, { Component, Fragment } from 'react';
import queryString from 'query-string';

import { CreditNoteIcon } from '../../Assets/Navigation/NavigationIcons';
import { restRequest } from '../../Helpers/RequestHelper';
import GenerateOptions from '../../Helpers/ListHelper/GenerateOptions';
import { routesPath } from '../Navigation/NavigationUtils';
import getStatusColor from '../../Helpers/GetStatusColor';
import CreditNoteDetails from '../../Pages/CreditNote/CreditNoteDetails/index';
import AddCreditNote from '../../Pages/CreditNote/AddCreditNote';
import EditCreditNote from '../../Pages/CreditNote/EditCreditNote';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../Helpers/LocalStorage';
import SalesOrderImage from '../../Components/Onboarding/Diagrams/SalesOrderImage';
import SalesOrderFlowDiagram from '../../Components/Onboarding/Diagrams/SalesReturnFlowDiagram';

import Alert from '../../Components/Alert/Alert';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import CustomModal from '../../Components/CustomModal/CustomModal';
import ListTable from '../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../Components/SearchBar/SearchResult';
import Onboarding from '../../Components/Onboarding/InitialOnboardingScreen';
import EditIcon from '../../Assets/General/EditIcon';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import MarkAsConfirmedIcon from '../../Assets/General/MarkAsOpenIcon';
import PDFViewer from '../../Components/PDFViewer/PDFViewer';
import Export from '../../Components/Export/Export';
import ExportIcon from '../../Assets/General/ExportIcon';
import PdfIcon from '../../Assets/General/PdfIcon';

import './CreditNote.css';
import EmailIcon from '../../Assets/General/EmailIcon';
import { checkError } from '../../Helpers/AuthHelper';
import { AccessDenied } from '../../Components';

class SalesReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditNotesId: null,
      loading: true,
      data: [],
      dataExpanded: [],

      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 0,

      markAsVoidModal: false,
      markAsVoidId: '',

      markAsDraftModal: false,
      markAsDraftId: '',

      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',

      dropshipBackorderModal: false,
      dropshipBackorderType: '',
      dropshipBackorderData: [],

      pdf: null,
      showPDF: false,
      search: '',
      showExportModal: false,
      start: '',
      end: '',
      contactId: '',
      contactName: null,
      accessDenied: false,

    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'draft',
      label: 'Draft',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'draft', page: 1 }),
    },
    {
      id: 'open',
      label: 'Open',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'open', page: 1 }),
    },
    {
      id: 'partially applied',
      label: 'PRTL Applied', //hide this no scenario handled
      type: 'link',
      onClick: () =>
        this.generateRoute({ filter: 'partially applied', page: 1 }),
    },
    {
      id: 'consumed',
      label: 'Consumed',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'consumed', page: 1 }),
    },
  ];

  sortOptions = [
    {
      id: 'credit_note_date',
      label: 'Credit Notes Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('credit_note_date'),
        }),
    },
    {
      id: 'invoice_no',
      label: 'Invoice No',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('invoice_no') }),
    },
    {
      id: 'display_name',
      label: 'Customer Name',
      type: 'link',
      onClick: () =>
        this.generateRoute({
          ...this.prepareSortFilter('display_name'),
        }),
    },
    {
      id: 'status',
      label: 'Status',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('status') }),
    },
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    {
      id: 'updated_at',
      label: 'Last Modified Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('updated_at') }),
    },
  ];

  importExportOptions = [
    {
      label: 'Export Credit Notes',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () =>
        !this.hasViewPermission('creditnotes') ||
        JSON.parse(localStorage.getItem('exportBtn')),
    },
  ];

  componentDidMount() {
    document.title = 'Credit Notes';
    this.fetchDataWithQueryString();
  }

  print(id) {
    this.setState({ loading: true });
    restRequest('get', `creditnotes/${id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();
        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 100);

        this.setState({ loading: false });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search, start, end, contactId } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'draft':
      case 'open':
      case 'closed':
      case 'void':
      case 'partially applied':
      case 'consumed':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'credit_note_date':
      case 'invoice_no':
      case 'display_name':
      case 'status':
      case 'created_at':
      case 'updated_at':
        sort = query.sort;
        break;
      case 'contact':
        sort = 'display_name';
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  fetchData = async () => {
    this.setState({ loading: true });

    const currentFilter =
      this.state.currentFilter === 'all' ? '' : this.state.currentFilter;
    var url = `creditnotes?page=${this.state.currentPage}&filter=${currentFilter}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${this.state.search}`;
    const dateQuery = queryString.parse(this.props.location.search);
    if (dateQuery.start || dateQuery.end) {
      if (this.state.start && this.state.end) {
        url = `${url}&start=${this.state.start}&end=${this.state.end}`;
      }
    }
    if (this.state.contactId) {
      url = `${url}&contactId=${this.state.contactId}`;
    }
    await restRequest('get', url)
      .then((res) => {
        let obj = {
          prevId: null,
          id: res.credit_notes.data[0] && res.credit_notes.data[0].id,
          nextId: res.credit_notes.data[1] && res.credit_notes.data[1].id,
          moduleName: 'creditnotes',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'creditnotes') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.credit_notes.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.credit_notes.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.credit_notes.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.credit_notes.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'creditnotes' &&
          (dataFromLS.queryParam !== window.location.search ||
            localStorage.getItem('org_switch') === 'true')
        ) {
          if (res.credit_notes.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res?.credit_notes.data?.length) {
          setObjInLS('module', obj);
          sessionStorage.setItem('fullscreen', true);
        }

        this.setState(
          {
            loading: false,
            data: this.prepareDataForTableCollapsed(
              res.credit_notes.data,
              res.currency
            ),
            dataExpanded: this.prepareDataForTable(
              res.credit_notes.data,
              res.currency
            ),
            totalPage: res.credit_notes.last_page,
            totalRecords: res.credit_notes.total,
          },
          () => {
            this.setState({ mainLoading: false });
          }
        );
        if (this.state.contactId) {
          this.setState({
            contactName: res.credit_notes.data[0].display_name,
          });
        }
      })
      .catch((error) => {
        if (error && error.response.status === 403) {
          this.setState({ accessDenied: true, mainLoading: false });
        }
        this.props.handleToast(error, 'error');
        checkError(error);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  prepareDataForTable(data, currency) {
    return data.map((creditnotes) => ({
      id: creditnotes.id,
      Date: {
        id: 'credit_note_date',
        sortable: true,
        getValue: function () {
          return creditnotes[this.id];
        },
      },
      'Credit Note': {
        id: 'credit_note_no',
        sortable: true,
        getValue: function () {
          return creditnotes[this.id];
        },
      },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return creditnotes[this.id];
        },
      },
      'Invoice No.': {
        id: 'invoice_no',
        sortable: true,
        getValue: function () {
          return creditnotes[this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        hideStatusColor: true,
        getValue: function () {
          return (
            <span className={getStatusColor(creditnotes[this.id])}>
              {' '}
              {creditnotes[this.id] === 'partially applied'
                ? 'PRTL Applied'
                : creditnotes[this.id]}
            </span>
          );
        },
      },

      'Credits Issued': {
        id: 'issued_credits',
        //sortable: true,
        getValue: function () {
          return `${currency}${parseFloat(creditnotes[this.id]).toFixed(2)}`;
        },
      },
      Balance: {
        id: 'balance',
        //sortable: true,
        getValue: function () {
          return `${currency}${parseFloat(creditnotes[this.id]).toFixed(2)}`;
        },
      },
      additional: {
        status: creditnotes.status,
        backorderStatus: creditnotes.backorder_status,
        dropshipStatus: creditnotes.dropship_status,
        invoiceStatus: creditnotes.invoice_status,
        packageStatus: creditnotes.package_status,
        cloneStatus: creditnotes.clone_status,
      },
    }));
  }

  prepareDataForTableCollapsed(data, currency) {
    return data.map((creditnotes) => ({
      id: creditnotes.id,
      Date: {
        id: 'credit_note_date',
        sortable: true,
        getValue: function () {
          return creditnotes[this.id];
        },
      },
      Customer: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return creditnotes[this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        hideStatusColor: true,
        getValue: function () {
          return (
            <span className={getStatusColor(creditnotes[this.id])}>
              {' '}
              {creditnotes[this.id] === 'partially applied'
                ? 'PRTL Applied'
                : creditnotes[this.id]}
            </span>
          );
        },
      },
      Balance: {
        id: 'balance',
        //sortable: true,
        getValue: function () {
          return `${currency}${creditnotes[this.id]}`;
        },
      },
      additional: {
        status: creditnotes.status,
        backorderStatus: creditnotes.backorder_status,
        dropshipStatus: creditnotes.dropship_status,
        invoiceStatus: creditnotes.invoice_status,
        packageStatus: creditnotes.package_status,
        cloneStatus: creditnotes.clone_status,
      },
    }));
  }

  close = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  openPdf(id) {
    this.setState({ showPDF: true });
    restRequest('get', `creditnotes/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => {
        checkError(error);
      });
  }
  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'creditnotes',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ creditNotesId: id });
  }
  markAsConfirmed = (id) => {
    this.setState({ processing: true });
    restRequest('put', `creditnotes/${id}/confirmed`)
      .then(() => {
        this.setState({ processing: false });
        this.props.handleToast(
          'Credit Note has been marked as open.',
          'success'
        );
        this.updateCheckedState();
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  };

  bulkMarkAsConfirmed(ids) {
    this.setState({ processing: true });
    restRequest('put', 'creditnotes/confirmed', {
      ids: ids,
    })
      .then((res) => {
        this.setState({ processing: false });
        this.props.handleToast(res.message, 'success');
        this.updateCheckedState();
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ processing: false });
        checkError(error, this.props.handleToast);
      });
  }

  // check status whether its active or not
  checkStatus = (status, id = false) => {
    const currentId = id ? id : this.state.bulkSelectedData[0];
    return (
      this.state.data.find((one) => {
        return one.id === currentId;
      }).additional.status === status
    );
  };

  markAsVoid = () => {
    restRequest('put', `creditnotes/${this.state.markAsVoidId}/void`)
      .then(() => {
        this.props.handleToast(
          'Credit Note has been marked as void.',
          'success'
        );
        this.fetchData();
        this.updateCheckedState();
        this.closeMarkAsVoidModal();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.closeMarkAsVoidModal();
      });
  };

  markAsDraft = () => {
    restRequest('put', `creditnotes/${this.state.markAsDraftId}/draft`)
      .then(() => {
        this.props.handleToast(
          'Credit Note has been marked as draft.',
          'success'
        );
        this.fetchData();
        this.updateCheckedState();
        this.closeConvertToDraftModal();
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.closeConvertToDraftModal();
      });
  };

  openMarkAsVoidModal = (id) => {
    this.setState({
      markAsVoidModal: true,
      markAsVoidId: id,
    });
  };

  closeMarkAsVoidModal = () => {
    this.setState({
      markAsVoidModal: false,
    });
  };

  renderMarkAsVoidModal() {
    return (
      <CustomModal
        showModal={this.state.markAsVoidModal}
        title="Confirm to Mark as Void"
        onClose={this.closeMarkAsVoidModal}
        onSubmit={() => this.markAsVoid()}
      >
        Are you sure you want to mark this Credit Note as void?
      </CustomModal>
    );
  }

  openConvertToDraftModal = (id) => {
    this.setState({
      markAsDraftModal: true,
      markAsDraftId: id,
    });
  };

  closeConvertToDraftModal = () => {
    this.setState({
      markAsDraftModal: false,
    });
  };

  renderConvertToDraftModal() {
    return (
      <CustomModal
        showModal={this.state.markAsDraftModal}
        title="Confirm to Mark as Draft"
        onClose={this.closeConvertToDraftModal}
        onSubmit={() => this.markAsDraft()}
      >
        Are you sure you want to mark this Credit Note as draft?
      </CustomModal>
    );
  }

  hidePDFModal = () => {
    this.setState({
      showPDF: false,
    });
  };

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Credit Notes"
        Icon={ExportIcon}
        onClose={this.closeExportModal}
        renderActions={false}
      >
        <Export
          name="Credit Notes"
          onCancel={this.closeExportModal}
          submitURL="creditnotes/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  exportCurrentView = () => {
    restRequest('post', `creditnotes/exportSelected`, {
      ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `CreditNotes.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`Credit Note exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  renderBulkDropshipBackorderModal = () => (
    <CustomModal
      size="large"
      showModal={this.state.dropshipBackorderModal}
      title={`${
        this.state.dropshipBackorderType === 'dropship'
          ? 'Dropship'
          : 'Backorder'
      }`}
      renderActions={false}
      onClose={this.closeDropshipBackorderModal}
    ></CustomModal>
  );

  populateConditionalOptions(id, isVoid, isConfirmed) {
    const hasEditPermission = this.hasPermission('creditnotes', 'Edit');
    const options = [];

    if (!hasEditPermission) return options;
    if (!isConfirmed && !isVoid) {
      options.push({
        label: 'Mark as Open',
        onClick: () => this.markAsConfirmed(id),
        disabled: (id) =>
          this.checkStatus('open', id) ||
          this.checkStatus('void', id) ||
          this.checkStatus('consumed', id) ||
          this.checkStatus('partially applied', id),
      });
    }
    if (isVoid) {
      options.push({
        label: 'Convert to draft',
        onClick: () => this.openConvertToDraftModal(id),
      });
    }
    if (!isVoid) {
      options.push({
        label: 'Mark as Void',
        onClick: () => this.openMarkAsVoidModal(id),
      });
    }
    return options;
  }

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    let isConfirmed;
    this.state.data.forEach((status) => {
      if (status.additional.status !== 'open') {
        isConfirmed = false;
      }
    });
    const isVoid = this.checkStatus('void');
    const checkedId = this.state.bulkSelectedData[0];
    const options = this.populateConditionalOptions(
      checkedId,
      isVoid,
      isConfirmed
    );
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'creditnotes',
      'All'
    );
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={!isVoid && hasEditPermission}
          to={`/creditnotes/edit/${checkedId}`}
          type="edit"
        />
        <Button
          handleClick={() => this.openPdf(checkedId)} /// in progress. working on it.
          type="pdf"
        />
        <Button
          enable={hasDeletePermission}
          handleClick={() =>
            this.openDeleteModal(
              checkedId,
              this.props.forceRedirect,
              this.props.forceRedirectFlag
            )
          }
          type="delete"
        />
        <Button handleClick={() => this.exportCurrentView()} type="export" />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  populateConditionalOptionsBulkSelect() {
    // const hasEditPermission = this.hasPermission('creditnotes', 'Edit');
    const options = [];
    let confirmFlag = true;
    options.push(
      {
        label: 'Mark as Open',
        onClick: () => this.bulkMarkAsConfirmed(this.state.bulkSelectedData),
        disabled: () => confirmFlag,
      },
      {
        label: 'Export Selected',
        onClick: () => this.exportCurrentView(),
        disabled: () => !this.hasViewPermission('creditnotes'),
      }
    );
    return options;
  }

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const [, , hasDeletePermission] = this.hasPermission('creditnotes', 'All');
    const options = this.populateConditionalOptionsBulkSelect();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  isOrderEditable(id) {
    return false;
  }

  prepareRowOptions() {
    // const hasEditPermission = this.hasPermission('creditnotes', 'Edit');
    const hasCreatePermission = this.hasPermission('creditnotes', 'Create');
    const rowOptions = [
      {
        label: 'Mark as Open',
        icon: MarkAsConfirmedIcon,
        onClick: (id) => this.markAsConfirmed(id),
        disabled: (id) =>
          this.checkStatus('consumed', id) ||
          this.checkStatus('open', id) ||
          this.checkStatus('void', id) ||
          this.checkStatus('partially applied', id),
      },
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.props.history.push(`creditnotes/edit/${id}`),
        disabled: (id) =>
          this.checkStatus('consumed', id) ||
          this.isOrderEditable(id) ||
          this.checkStatus('void', id) ||
          this.checkStatus('partially applied', id),
      },
      {
        label: 'View PDF',
        icon: PdfIcon,
        onClick: (id) => this.openPdf(id),
      },
      {
        label: 'Email',
        icon: EmailIcon,
        onClick: (id) => this.props.history.push(`/creditnotes/email/${id}`),
        disabled: () => !hasCreatePermission,
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: (id) => this.openDeleteModal(id),
        disabled: (id) =>
          this.checkStatus('consumed', id) ||
          this.checkStatus('partially applied', id),
      },
    ];

    const permissions = this.hasPermission('creditnotes', 'All');

    const allowedRowOptions = GenerateOptions(permissions, rowOptions, [
      'Mark as Open',
      'Mark as Void',
    ]);

    return allowedRowOptions;
  }
  renderTable() {
    const {
      data,
      bulkSelectedData,
      dataExpanded,
      sortFilter,
      orderBy,
      accessDenied,
      //loading,
      mainLoading,
    } = this.state;
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {

        return (
          accessDenied ?
            <AccessDenied />
            :
          <Onboarding
            name="Credit Notes"
            Image={SalesOrderImage}
            FlowDiagram={SalesOrderFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="400"
                src="https://www.youtube.com/embed/TSi9nZJIAl0"
                title="YouTube video player"
                loading="lazy"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={CreditNoteIcon}
            headerTitle="Credit Notes"
            title="Manage Credit Notes Activity"
            description="Create, customize and manage your credit note"
            //buttonLabel="Create Credit Note"
            //buttonLink="/creditnotes/add"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    return (
      <>
        <ListTable
          className={
            fullscreen === 'true'
              ? 'creditnote_listing Cn-listing-main'
              : 'expanded_view_creditnote'
          }
          data={fullscreen === 'true' ? dataExpanded : data}
          selectedData={bulkSelectedData}
          rowOptions={this.prepareRowOptions()}
          linkTo="/creditnotes"
          preferenceLink="/preference/SaleReturnPolicyPreference"
          preference="Sale Return Preferences"
          sortFilter={sortFilter}
          orderBy={orderBy}
          loading={mainLoading}
          generateRoute={this.generateRoute}
          updateCheckedState={this.updateCheckedState}
          handleCheckAll={this.handleCheckAll}
          handleCheckSingle={this.handleCheckSingle}
          getID={this.getID}
          moduleName="creditnotes"
          totalRecords={this.state.totalRecords}
        />
      </>
    );
  }

  onClickHandler = () => {
    this.setState({ contactId: '', contactName: '' });
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'creditnotes') {
      setObjInLS('module', {
        ...dataFromLS,
        queryParam: '?filter=all&sort=created_at&page=1&orderBy=desc&view=20',
      });
      this.props.history.push('/r');
    } else {
      this.props.history.push(routesPath.creditnotes);
    }
  };

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.creditnotes}
        >
          <SearchResult
            criterias={['Credit Note No', 'Invoice No', 'Customer Name']}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Credit Notes"
          linkTo={routesPath.creditnotes}
          crossIcon={false}
          //onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        {this.renderExportModal()}
        {this.renderMarkAsVoidModal()}
        {this.renderConvertToDraftModal()}
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.close}
          pdf={this.state.pdf}
        />
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(
  SalesReturn,
  {
    // tableIcon:'creditNote_table_icon',
    tableIcon: 'credit_general_module_icon',
    name: 'Credit Notes',
    deleteName: 'Credit Note',
    Icon: CreditNoteIcon,
    baseUrl: 'creditnotes',
    redirectUrl: '/creditnotes',
    buttonUrl: '/creditnotes/add',
    buttonLabel: 'New Credit Note',
    showNewButton: false,
    routeName: 'creditnotes',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'creditnotes',
  },
  CreditNoteDetails,
  AddCreditNote,
  EditCreditNote
);
