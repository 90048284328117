import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { differenceBy } from 'lodash-es';
import { restRequest, checkError } from '../../../Helpers';
import Loader from '../../../Components/Loader/Loader';
import ItemGroupEnhancer from './ItemGroupEnhancer';
import PlanExceeded from '../../PlanExceeded/PlanExceeded';
import ItemGroupEnhancerStepTwo from './ItemGroupEnhancerStepTwo';
import './ItemForm.css';

class ItemGroupForm extends Component {
  type = this.props.type; // add, edit,clone

  state = {
    item: {
      type: 'goods',
      name: '',
      unit: '',
      attributesArr: [{ attribute: '', variations: { options: [] } }],
      optionsArr: [{ options: [] }],
      manufacturer: '',
      description: '',
      brand: '',
      salesAccountID: null,
      purchaseAccountID: null,
      warehouses: [],
      images: [],
      uploadImages: [], // images to upload
      base84Image: [],
    },
    purchaseAccounts: [],
    salesAccounts: [],
    vendorsList: [],
    currency: {},
    // taxList: [],
    warehousesList: [],
    suggestions: [],
    attributeSuggestions: [],
    allWarehouses: [],
    units: [],
    stepOne: true,
    attributes: [],
    trackInventory: true,
    loading: true,
    planExceeded: null,
    submitButtonText: 'Save & Next',
    showPrompt: false,
  };
  emptyItem = {
    id: null,
    name: '',
    sku: '',
    ean: '',
    isbn: '',
    warehouses: [],
    upc: '',
    unit: '',
    costPrice: 0,
    sellingPrice: 0,
    reorderPoint: '',
  };
  handleTrackInventory = () => {
    this.setState((state) => ({ trackInventory: !state.trackInventory }));
  };

  componentDidMount() {
    this.fetchData();
    this.props.inModal
      ? sessionStorage.setItem('fullscreen', true)
      : sessionStorage.setItem('fullscreen', false);
    sessionStorage.setItem('once', false);
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  fetchData = async () => {
    this.setState({ loading: true });

    switch (this.type) {
      case 'edit':
        await this.fetchDetails('edit');
        break;
      case 'clone':
        await this.fetchDetails('clone');
        break;
      case 'add':
      default:
        await this.fetchCreateData();
    }
    this.setState({ loading: false });
  };

  emptyWarehouse = {
    id: null,
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    openingStock: null,
    openingStockValue: null,
    is_active: 1,
  };

  getWarehouse(warehouseList) {
    if (!warehouseList.length) return this.emptyWarehouse;

    const primaryWarehouse = warehouseList.find(
      (warehouse) => warehouse.is_primary
    );
    return {
      id: primaryWarehouse.id,
      name: primaryWarehouse.name,
      address: primaryWarehouse.address,
      city: primaryWarehouse.city,
      state: primaryWarehouse.state,
      country: primaryWarehouse.country,
      zipCode: primaryWarehouse.zip_code,
      openingStock: null,
      openingStockValue: null,
      is_active: 1,
    };
  }

  fetchCreateData() {
    return restRequest('get', 'itemgroups/create')
      .then((res) => {
        this.setState((state) => ({
          currency: res.base_currency,
          allWarehouses: res.ActiveWarehouses,
          salesAccounts: res.income_account,
          purchaseAccounts: res.expense_account,
          item: {
            ...state.item,
            salesAccountID: res.income_account[0]?.id,
            purchaseAccountID: res.expense_account[0]?.id,
            warehouses: [this.getWarehouse(res.ActiveWarehouses)],
          },
          vendorsList: res.vendors,
          taxList: res.taxes,
          warehousesList: differenceBy(
            res.ActiveWarehouses,
            [this.getWarehouse(res.ActiveWarehouses)],
            'id'
          ),
          units: res.item_units,
          attributes: res.attributes,
        }));
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
      });
  }

  fetchDetails(reqUrl) {
    return restRequest('get', `itemgroups/${this.props.id}/${reqUrl}`)
      .then((res) => {
        let itemGroup = res.item_group_details;
        this.setState({
          currency: res.base_currency,
          salesAccounts: res.income_account,
          purchaseAccounts: res.expense_account,
          item: {
            ...this.state.item,
            name: itemGroup.name,
            unit: itemGroup?.unit || '',
            manufacturer: itemGroup?.manufacturer || '',
            description: itemGroup.description ? itemGroup.description : '',
            brand: itemGroup.brand,
            salesUnitPrice: itemGroup.sales_unit_price,
            taxID: itemGroup.tax_id,
            purchaseUnitPrice: itemGroup.purchase_unit_price,
            images: itemGroup.images,
            uploadImages: [],
            base84Image: [],
            salesAccountID: itemGroup.sales_account_id,
            purchaseAccountID: itemGroup?.purchase_account_id,
            warehouses: [this.getWarehouse(res.ActiveWarehouses)],
          },
          taxList: res.taxes,
          units: res.item_units,
          warehousesList: res.ActiveWarehouses,
          submitButtonText:
            res.editStocks && res?.item_group_details?.items.length !== 0
              ? 'Save & Next'
              : 'Save',
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
        this.setState({
          planExceeded: error.response && error.response.data.status,
          loading: false,
        });
      });
  }

  submitRequestUrl() {
    switch (this.type) {
      case 'edit':
        return `itemgroups/${this.props.id}`;
      case 'add':
      case 'clone':
      default:
        return 'itemgroups';
    }
  }

  successMessage = () => {
    switch (this.type) {
      case 'edit':
        this.props.handleToast('Item Group has been updated', 'success');
        break;
      case 'clone':
        this.props.handleToast('Item Group has been Cloned', 'success');
        break;
      case 'add':
      default:
        this.props.handleToast('Item Group added successfully', 'success');
        break;
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getSuggestions = (value) => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue?.length;

    return inputLength === 0
      ? this.state.units
      : this.state.units.filter((unit) =>
          unit.name.toLowerCase().includes(inputValue)
        );
  };

  removeSuggestion = (name) => {
    const units = this.state.units.filter((unit) => unit.name !== name);
    this.setState({ units });
    restRequest('delete', `itemunits/${name}`).catch((error) => {
      checkError(error, this.props.handleToast);
    });
  };

  onSuggestionsFetchRequestedAttribute = ({ value }) => {
    this.setState({
      attributeSuggestions: this.getSuggestionsAttribute(value),
    });
  };

  onSuggestionsClearRequestedAttribute = () => {
    this.setState({ attributeSuggestions: [] });
  };
  updateStep = () => {
    this.setState({ stepOne: false });
  };
  getSuggestionsAttribute = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? this.state.attributes
      : this.state.attributes.filter((attribute) =>
          attribute.name.toLowerCase().includes(inputValue)
        );
  };

  removeSuggestionAttribute = (name) => {
    const attributes = this.state.attributes.filter(
      (unit) => unit.name !== name
    );
    this.setState({ attributes });
    restRequest('delete', `itemunits/${name}`).catch((error) => {
      checkError(error, this.props.handleToast);
    });
  };

  handlePrompt = (status = true) => this.setState({ showPrompt: status });

  renderItemForm() {
    let {
      item,
      currency,
      salesAccounts,
      purchaseAccounts,
      vendorsList,
      taxList,
      stepOne,
      warehousesList,
      trackInventory,
      submitButtonText,
    } = this.state;

    return (
      <>
        <Prompt
          when={this.state.showPrompt}
          message="Are you sure you want to leave page while changes made?"
        />
        {stepOne ? (
          <ItemGroupEnhancer
            // inModalItem={inModal}
            // onSubmitItem={onSubmitModal}
            // closeItem={closeModal}
            type={this.type}
            item={item}
            emptyItem={this.emptyItem}
            allWarehouses={this.state.allWarehouses}
            emptyWarehouse={this.emptyWarehouse}
            currency={currency}
            updateStep={this.updateStep}
            salesAccounts={salesAccounts}
            purchaseAccounts={purchaseAccounts}
            submitButtonText={submitButtonText}
            vendorsList={vendorsList}
            taxList={taxList}
            warehousesList={warehousesList}
            requestUrl={this.submitRequestUrl()}
            successMessage={this.successMessage}
            trackInventory={trackInventory}
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            attributeSuggestions={this.state.attributeSuggestions}
            onSuggestionsFetchRequestedAttribute={
              this.onSuggestionsFetchRequestedAttribute
            }
            onSuggestionsClearRequestedAttribute={
              this.onSuggestionsClearRequestedAttribute
            }
            removeSuggestion={this.removeSuggestion}
            handleTrackInventory={this.handleTrackInventory}
            setPrompt={this.handlePrompt}
            {...this.props}
          />
        ) : (
          <ItemGroupEnhancerStepTwo
            type={this.type}
            item={item}
            stepOne={stepOne}
            emptyItem={this.emptyItem}
            allWarehouses={this.state.allWarehouses}
            emptyWarehouse={this.emptyWarehouse}
            currency={currency}
            salesAccounts={salesAccounts}
            purchaseAccounts={purchaseAccounts}
            vendorsList={vendorsList}
            taxList={taxList}
            warehousesList={warehousesList}
            requestUrl={this.submitRequestUrl()}
            successMessage={this.successMessage}
            trackInventory={trackInventory}
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            attributeSuggestions={this.state.attributeSuggestions}
            onSuggestionsFetchRequestedAttribute={
              this.onSuggestionsFetchRequestedAttribute
            }
            onSuggestionsClearRequestedAttribute={
              this.onSuggestionsClearRequestedAttribute
            }
            removeSuggestion={this.removeSuggestion}
            handleTrackInventory={this.handleTrackInventory}
            setPrompt={this.handlePrompt}
            {...this.props}
          />
        )}
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.planExceeded === false) return <PlanExceeded />;
    else return this.renderItemForm();
  }
}

export default withRouter(ItemGroupForm);
