import React, { PureComponent } from 'react';
import { Calendar, DateRangePicker, DateRange } from 'react-date-range';

import { MdClose, MdExpandMore } from '../../Common/Icons';
import getDateFormat from '../../Helpers/Date/GetDateFormat';
import { staticRanges } from './StaticRanges';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './CustomDatePicker.css';
import {
  // getOrganizationDate,
  getOrganizationTimeZone,
} from '../../Helpers/Date/OrganizationDate';
import timezone from 'moment-timezone';

const DatePickerRange = ({ isDashboard, ...props }) =>
  isDashboard ? <DateRange {...props} /> : <DateRangePicker {...props} />;

class CustomDatePicker extends PureComponent {
  datePickerRef = React.createRef();

  state = {
    showDatePicker: false,
    dateRangeFlag: 0,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
    this.props.fromDashboard && this.handleDatePickerToggle();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    if (
      this.datePickerRef.current &&
      !this.datePickerRef.current.contains(event.target)
    ) {
      if (this.state.showDatePicker) this.handleDatePickerToggle();
    }
  };

  handleDatePickerToggle = () => {
    this.setState((state) => ({
      showDatePicker: !state.showDatePicker,
      dateRangeFlag: 0,
    }));
  };

  handleDateRangeChange = (payload) => {
    if (payload.selection.key === 'static') {
      this.handleDatePickerToggle();
    } else {
      this.setState(
        (state) => ({
          dateRangeFlag: state.dateRangeFlag + 1,
        }),
        () => this.handleDateRangeFlagCheck()
      );
    }
    this.props.onChange(payload);
  };

  handleDateRangeFlagCheck() {
    if (this.state.dateRangeFlag === 2) {
      this.setState({
        dateRangeFlag: 0,
        showDatePicker: false,
      });
    }
  }

  handleDateChange = (date) => {
    this.props.onChange(date);
    this.handleDatePickerToggle();
  };

  renderDateRangePicker() {
    var utc = timezone
      .tz(new Date(), 'US/Eastern')
      .utcOffset(new Date().getTimezoneOffset())._d;
    return (
      <span
        ref={this.datePickerRef}
        className={`inline ${this.props.className}`}
      >
        {!this.props.fromDashboard && (
          <label
            className="section-header--dropdown-title"
            htmlFor="date-range-picker"
          >
            Date Range:
          </label>
        )}
        <button
          className="date-picker--button "
          id="date-range-picker"
          onClick={this.handleDatePickerToggle}
        >
          <span>
            {this.props.ranges.selection.startDate.format(getDateFormat())}
          </span>
          <span className="margin-horizontal-sm">to</span>
          <span>
            {this.props.ranges.selection.endDate.format(getDateFormat())}
          </span>
          <MdExpandMore />
        </button>
        <div
          className={`date-range-picker ${
            this.state.showDatePicker ? 'date-picket-show' : ''
          } date-picker-main ${
            this.props.inModal && this.props.inModal
              ? 'date-picker-inmodal'
              : ''
          }`}
          style={{ display: this.state.showDatePicker ? 'inherit' : 'none' }}
        >
          <DatePickerRange
            ranges={[this.props.ranges.selection]}
            onChange={this.handleDateRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={this.props.months || 2}
            className={'PreviewArea'}
            direction="horizontal"
            inputRanges={[]}
            dateDisplayFormat={getDateFormat()}
            staticRanges={staticRanges}
            //maxDate={new Date()}
            //maxDate={new Date(this.props.ranges.selection.endDate)}
            maxDate={timezone.tz(utc, getOrganizationTimeZone())._d}
            isDashboard={this.props.fromDashboard}
          />
          {!this.props.fromDashboard && (
            <button
              className="button--border calander-btn"
              type="button"
              onClick={this.handleDatePickerToggle}
            >
              <MdClose className="material-icon" /> Close
            </button>
          )}
        </div>
      </span>
    );
  }

  renderSingleDatePicker() {
    return (
      <div ref={this.datePickerRef} className="inline">
        <label className="section-header--dropdown-title" htmlFor="date-picker">
          Date:
        </label>
        <div className="date-picker--container">
          <button
            className="date-picker--button date-select-set"
            id="date-picker"
            onClick={this.handleDatePickerToggle}
          >
            <span>{this.props.date.value.format(getDateFormat())}</span>
            <MdExpandMore />
          </button>
          {this.state.showDatePicker && (
            <div className="date-picker--body">
              <Calendar
                date={this.props.date.value}
                onChange={this.handleDateChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    switch (this.props.type) {
      case 'single':
        return this.renderSingleDatePicker();
      case 'range':
      default:
        return this.renderDateRangePicker();
    }
  }
}

export default CustomDatePicker;
