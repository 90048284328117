import React from 'react';
import CustomDropdown from '../Dropdown/CustomDropdown';
import './FormAction.css';

const FormAction = ({
  disabled = false,
  onCancel = () => null,
  onSubmit = () => null,
  cancelLabel = 'Cancel',
  submitLabel = 'Save',
  className = '',
  saveBtnFlag = false,
  marginFromRight = 0,
  additionalFields = [],
  isSalesOrder = false,
  dropUpSO = [],
  hasHr = false,
  showSaveAndSendButton = false,
  saveAndSendField = {},
  type = 'submit',
  setPrompt = () => {},
  showCancel = true,
}) => (
  <>
    {hasHr && <hr />}
    <div
      className={`action-buttons ${className}`}
      style={{ marginRight: `${marginFromRight}rem` }}
    >
      {additionalFields.length !== 0 &&
        isSalesOrder === false &&
        additionalFields.map((field, index) => (
          <button
            id="click_add_Address_Button"
            key={index}
            type="button"
            className="cancel-btn float-left background_blue"
            onClick={(event) => field.onClick(event)}
            disabled={disabled}
          >
            {field.place === 'behind' ? (
              field.icon ? (
                <field.icon />
              ) : null
            ) : null}
            {field.label}
            {field.place === 'front' ? (
              field.icon ? (
                <field.icon />
              ) : null
            ) : null}
          </button>
        ))}

      {showCancel && (
        <button
          type="button"
          className="action-buttons--cancel cancel-btn float-left mr_3"
          onClick={onCancel}
        >
          {cancelLabel}
        </button>
      )}

      {isSalesOrder === false && (
        <button
          className="action-buttons--submit next-btn float-left"
          disabled={disabled}
          type={type}
          onClick={(e) => {
            // setPrompt(false);
            onSubmit(e);
          }}
        >
          {submitLabel}
        </button>
      )}
      {showSaveAndSendButton && (
        <button
          id="next-btn"
          type="button"
          className="action-buttons--submit next-btn float-left"
          disabled={disabled}
          onClick={(event) => saveAndSendField.onClick(event)}
        >
          {saveAndSendField.label}
        </button>
      )}

      {isSalesOrder && (
        <div className={`dropdwon_with_button float-left`}>
          <button
            className="dropdwon_with_button_button"
            disabled={disabled}
            type="button"
            onClick={(e) => {
              setPrompt(false);
              onSubmit(e);
            }}
          >
            {saveBtnFlag ? 'Save' : `${submitLabel} as Draft`}
          </button>
          <CustomDropdown
            className="form-action-dropup dropdwon_options_sales_order"
            textLabel="+"
            type="button"
            options={dropUpSO}
            isSO={true}
          />
        </div>
      )}
    </div>
  </>
);

export default FormAction;
