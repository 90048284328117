import React, { Component, Fragment } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import { NavLink, Link, withRouter } from 'react-router-dom';
import {
  //NewDashboardIcon,
  //NewContactIcon,
  //NewItemIcon,
  //NewReportIcon,
  //NewSalesOrderIcon,
  NewSalesReturnIcon,
  //NewPackageIcon,
  //NewInvoiceIcon,
  //NewPurchaseOrderIcon,
  //NewBillIcon,
  //SettingIcon,
  ItemExpandIcon,
  //SubscriptionPlanIcon,
  ItemExpandedIcon,
} from '../../Assets/Navigation/NavigationIcons';
import {
  forceActive,
  routesPath,
  hasSubtring,
  hasSubtringReports,
} from './NavigationUtils';
// import HamburgerIcon from '../../Assets/Navigation/HamburgerIcon';
import { MdAdd, MdKeyboardBackspace } from '../../Common/Icons';

import './Navigation.css';
import { getObjFromLS, setObjInLS } from '../../Helpers/LocalStorage';
import logo from '../../Assets/Img/logo.svg';
import { restRequest } from '../../Helpers';
//import RecordPaymentIcon from '../../Assets/General/RecordPaymentIcon';

class Navigation extends Component {
  state = {
    itemExpanded: false,
    returnExpanded: false,
    settingsExpanded: false,
    settingsNavSlided: false,
    reportsNavSlided: false,
    toggleOnHover: false,
    reportsNav: false,
    settingsNav: false,
    isMobileMenu: false,
    isSuperAccess: false,
  };

  componentDidMount() {
    this.checkPermission();
    this.checkPathsForExpandedMenus();
  }

  componentDidUpdate(prevProps) {
    const isIncluded = hasSubtring(
      [
        routesPath.settings,
        routesPath.tax,
        routesPath.taxAdd,
        routesPath.taxEdit,
        routesPath.deliveryMethod,
        routesPath.deliveryMethodAdd,
        routesPath.deliveryMethodEdit,
        routesPath.warehouses,
        routesPath.warehouseAdd,
        routesPath.warehouseEdit,
        routesPath.inventoryAdd,
        routesPath.currencies,
        routesPath.currencyAdd,
        routesPath.currencyEdit,
        routesPath.preference,
      ],
      this.props.location.pathname
    );

    const isReportsRoutesIncluded = hasSubtringReports(
      routesPath.reports,
      this.props.location.pathname
    );

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkPathsForExpandedMenus();

      if (!isIncluded) {
        this.setState({
          settingsNavSlided: false,
          settingsNav: false,
          toggleOnHover: false,
        });
      }
      if (!isReportsRoutesIncluded) {
        this.setState({
          reportsNavSlided: false,
          reportsNav: false,
          toggleOnHover: false,
        });
      }
    }
  }

  checkPathsForExpandedMenus() {
    const isIncluded = hasSubtring(
      [
        routesPath.settings,
        routesPath.tax,
        routesPath.taxAdd,
        routesPath.taxEdit,
        routesPath.deliveryMethod,
        routesPath.deliveryMethodAdd,
        routesPath.deliveryMethodEdit,
        routesPath.warehouses,
        routesPath.warehouseAdd,
        routesPath.warehouseEdit,
        routesPath.inventoryAdd,
        routesPath.currencies,
        routesPath.currencyAdd,
        routesPath.currencyEdit,
        routesPath.preference,
        routesPath.inventory,
        routesPath.paymentMethod,
      ],
      this.props.location.pathname
    );

    if (isIncluded) {
      this.setState({
        settingsNavSlided: true,
        settingsNav: true,
        settingsExpanded: true,
      });
    }

    const isReportsRoutesIncluded = hasSubtringReports(
      routesPath.reports,
      this.props.location.pathname
    );

    if (isReportsRoutesIncluded) {
      this.setState({
        reportsNavSlided: false,
        reportsNav: false,
      });
    }

    const isItemsRoutesIncluded = hasSubtring(
      [routesPath.items, routesPath.itemAdjustment, routesPath.itemGroups],
      this.props.location.pathname
    );

    if (isItemsRoutesIncluded) {
      this.setState({
        itemExpanded: true,
      });
    }
    const isReturnRoutesIncluded = hasSubtring(
      [routesPath.returns, routesPath.creditNote],
      this.props.location.pathname
    );

    if (isReturnRoutesIncluded) {
      this.setState({
        returnExpanded: true,
      });
    }
  }

  setInvModule() {
    let { urlPath = '' } = getObjFromLS('module');
    if (urlPath === '/invoices') return;
    let obj = {
      id: 0,
      moduleName: 'invoices',
      nextId: 0,
      prevId: null,
      queryParam: '',
      update: true,
      urlPath: '/invoices',
    };
    setObjInLS('module', obj);
  }

  checkPermission() {
    let allPermissions = getObjFromLS('role').permissions;
    if (allPermissions && allPermissions.hasOwnProperty('superAccess')) {
      this.setState({ isSuperAccess: true });
    } else {
      restRequest('get', 'currentuser/role').then((res) => {
        if (res.permissions.superAccess) {
          this.setState({ isSuperAccess: true });
        }
      });
    }
  }

  setContactModule() {
    if (getObjFromLS('module').moduleName !== 'contacts') {
      this.props.history.push('/contacts');
      sessionStorage.setItem('fullscreen', true);
    }
  }
  handleReturnClick = () => {
    this.setState((state) => {
      if (!this.props.viewNavigation) {
        this.props.toggleNavigation();
      }
      if (
        !state.returnExpanded &&
        ![
          routesPath.returns,
          routesPath.returnsAdd,
          routesPath.creditNote,
          routesPath.creditNoteAdd,
        ].includes(this.props.location.pathname)
      ) {
      }

      return {
        ...state,
        returnExpanded:
          state.returnExpanded && !this.props.viewNavigation
            ? state.returnExpanded
            : !state.returnExpanded,
      };
    });
  };

  handleSettingsClick = () => {
    this.setState((state) => {
      if (!this.props.viewNavigation) {
        this.props.toggleNavigation();
      }
      if (
        !state.settingsExpanded &&
        ![
          routesPath.manageOrg,
          routesPath.myAccount,
          routesPath.organization,
          routesPath.organizationAdd,
          routesPath.settings,
          routesPath.tax,
        ].includes(this.props.location.pathname)
      ) {
      }

      return {
        ...state,
        settingsExpanded:
          state.settingsExpanded && !this.props.viewNavigation
            ? state.settingsExpanded
            : !state.settingsExpanded,
      };
    });
  };

  handleItemClick = () => {
    this.setState((state) => {
      if (!this.props.viewNavigation) {
        this.props.toggleNavigation();
      }

      if (
        !state.itemExpanded &&
        ![
          routesPath.items,
          routesPath.itemAdd,
          routesPath.itemAdjustment,
          routesPath.itemAdjustmentAdd,
          routesPath.itemGroups,
          routesPath.itemGroupsAdd,
        ].includes(this.props.location.pathname)
      ) {
      }

      return {
        ...state,
        itemExpanded:
          state.itemExpanded && !this.props.viewNavigation
            ? state.itemExpanded
            : !state.itemExpanded,
      };
    });
  };

  handleReportsClick = () => {
    const { viewNavigation, toggleNavigation, history } = this.props;
    if (!viewNavigation) {
      toggleNavigation();
    }
    this.setState(
      (state) => {
        return {
          ...state,
          reportsNavSlided: false,
          reportsNav: false,
          settingsNavSlided: false,
          settingsNav: false,
          itemExpanded: false,
          returnExpanded: false,
          settingsExpanded: false,
        };
      },
      () => history.push(routesPath.reportsDash.path)
    );
  };

  handleSettingsOnMouseEnter = () => {
    const { settingsNav, reportsNav, toggleOnHover } = this.state;
    const { viewNavigation } = this.props;

    if (!viewNavigation) return;
    if (!toggleOnHover && !settingsNav && !reportsNav) return;
    this.setState({
      settingsNavSlided: false,
      reportsNavSlided: false,
      toggleOnHover: true,
    });
  };

  handleSettingsOnMouseLeave = () => {
    const { settingsNav, reportsNav, toggleOnHover } = this.state;
    if (!toggleOnHover && !settingsNav && !reportsNav) return;
    const { viewNavigation } = this.props;
    if (!viewNavigation) return;
    if (settingsNav) {
      this.setState({
        settingsNavSlided: true,
        toggleOnHover: false,
      });
      return;
    }
    if (reportsNav) {
      this.setState({
        reportsNavSlided: true,
        toggleOnHover: false,
      });
    }
  };

  renderReportsNav() {
    const { viewNavigation } = this.props;
    return (
      <div className={`ims-nav--settings setting-nav-width scrollbar_style`}>
        <ul className="ims-nav">
          <li className="ims-nav--title">Reports</li>
          {routesPath.reports.map((link, index) =>
            !link.path ? (
              <li className="ims-nav--sub-title" key={index}>
                {link.title}
              </li>
            ) : (
              <li key={index}>
                <NavLink
                  exact
                  to={link.path}
                  className="ims-nav--link"
                  activeClassName="ims-nav--link active"
                  title={link.title}
                >
                  <span className={`${viewNavigation ? '' : 'slide'}`}>
                    {link.title}
                  </span>
                </NavLink>
              </li>
            )
          )}
        </ul>
      </div>
    );
  }

  renderSettingsNav() {
    const { viewNavigation } = this.props;
    return (
      <div className={`ims-nav--settings`}>
        <ul className="ims-nav">
          <li className="ims-nav--title">Settings</li>
          <li>
            <NavLink
              exact
              to={routesPath.settings}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="Overview"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>
                Overview
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={routesPath.tax}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="Taxes"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>Taxes</span>
            </NavLink>
            <Link to={routesPath.taxAdd} className="ims-nav--link-add">
              <MdAdd className="ims-nav--add-icon" />
            </Link>
          </li>
          <li>
            <NavLink
              to={routesPath.deliveryMethod}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="Delivery Methods"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>
                Delivery Methods
              </span>
            </NavLink>
            <Link
              to={routesPath.deliveryMethodAdd}
              className="ims-nav--link-add"
            >
              <MdAdd className="ims-nav--add-icon" />
            </Link>
          </li>
          <li>
            <NavLink
              to={routesPath.warehouses}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="Warehouses"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>
                Warehouses
              </span>
            </NavLink>
            <Link to={routesPath.warehouseAdd} className="ims-nav--link-add">
              <MdAdd className="ims-nav--add-icon" />
            </Link>
          </li>
          <li>
            <NavLink
              exact
              to={routesPath.inventoryAdd}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="New Inventory Account"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>
                New Inventory Account
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={routesPath.currencies}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="Currency Settings"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>
                Currency Settings
              </span>
            </NavLink>
            <Link to={routesPath.currencyAdd} className="ims-nav--link-add">
              <MdAdd className="ims-nav--add-icon" />
            </Link>
          </li>
          <li>
            <NavLink
              to={routesPath.preference}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="Preferences"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>
                Preferences
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={routesPath.numberPreferences}
              className="ims-nav--link"
              activeClassName="ims-nav--link active"
              title="Number Preferences"
            >
              <span className={`${viewNavigation ? '' : 'slide'}`}>
                Prefix Preferences
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
  mobileMenuHanlder = () => {
    this.setState(({ isMobileMenu }) => ({ isMobileMenu: !isMobileMenu }));
  };
  render() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const { itemExpanded, returnExpanded, settingsExpanded, reportsNavSlided } =
      this.state;
    const { viewNavigation, ssoConnected } = this.props;
    const toggleMenuItems = !viewNavigation || reportsNavSlided;

    return (
      <div
        className={`ims-navigation ims-nav-mob ${
          viewNavigation ? 'slide-in' : 'collapse-navigation slide-out '
        }`}
      >
        <div
          className={`${
            viewNavigation ? 'open-menu overlay-menu-open' : 'open-menu-close'
          }`}
          onClick={this.props.toggleNavigation}
        />
        <CSSTransitionGroup
          component={Fragment}
          transitionName="ims-nav--slided-navigation"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {reportsNavSlided && this.renderReportsNav()}
        </CSSTransitionGroup>
        <div className={`ims-nav--space-between`}>
          <Link
            to="/"
            className="header-logo navbar-mob-logo"
            onClick={() => sessionStorage.setItem('expandSettings', false)}
          >
            {' '}
            {/*collapse settings tab on page reload*/}
            <img src={logo} alt="SeeBiz IMS" />
          </Link>
          <ul
            className="ims-nav"
            onMouseEnter={this.handleSettingsOnMouseEnter}
            onMouseLeave={this.handleSettingsOnMouseLeave}
          >
            <button
              id="click_nav_arrow_button"
              className={`nav-arrow ${
                viewNavigation
                  ? 'back-direction-arrow'
                  : 'right-direction-arrow'
              }`}
              onClick={this.props.toggleNavigation}
            >
              <MdKeyboardBackspace />
              {/* <i class="fas fa-bars ims-mob-bars fa-2x"></i> */}
              <i className="fas fa-times ims-mob-bars fa-2x"></i>
            </button>
            <li>
              <NavLink
                exact
                to="/"
                className="ims-nav--link"
                activeClassName="ims-nav--link active"
                isActive={forceActive.bind(null, routesPath.dashboard)}
                title="Dashboard"
              >
                <span className="ims-nav-menu-icon">
                  {/* <NewDashboardIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-braille"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Dashboard
                  </span>
                </span>
              </NavLink>
            </li>

            <li>
              <button
                className="ims-nav--item-button"
                title="Items"
                id="click_items_module"
                onClick={this.handleItemClick}
              >
                <span className="ims-nav-menu-icon">
                  {/* <NewItemIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-tags"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Items
                  </span>
                </span>

                {itemExpanded ? (
                  <ItemExpandedIcon className="item-expand-icon" />
                ) : (
                  <ItemExpandIcon className="item-expand-icon" />
                )}
              </button>
              <ul
                className={`ims-nav--item-ul ${
                  itemExpanded && viewNavigation ? 'show-ul' : ''
                }`}
              >
                <li>
                  <NavLink
                    to={routesPath.itemGroups}
                    className="ims-nav--link"
                    id="click_itemGroup_module"
                    activeClassName="ims-nav--link active"
                    title="Item Groups"
                  >
                    <span
                      className={`ims-nav-menu-subtext ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      Item Groups
                    </span>
                  </NavLink>
                  {pathname === '/item-groups/add' ? (
                    <div
                      className="ims-nav--link-add"
                      style={{ cursor: 'not-allowed' }}
                    >
                      <MdAdd className="ims-nav--add-icon" />
                    </div>
                  ) : (
                    <Link
                      to={routesPath.itemGroupsAdd}
                      className="ims-nav--link-add"
                    >
                      <MdAdd className="ims-nav--add-icon" />
                    </Link>
                  )}
                </li>
                <li>
                  <NavLink
                    to={routesPath.items}
                    className="ims-nav--link"
                    activeClassName="ims-nav--link active"
                    title="All Items"
                  >
                    <span
                      className={`ims-nav-menu-subtext ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      All Items
                    </span>
                  </NavLink>
                  {pathname === '/items/add' ? (
                    <div
                      className="ims-nav--link-add"
                      style={{ cursor: 'not-allowed' }}
                    >
                      <MdAdd className="ims-nav--add-icon" />
                    </div>
                  ) : (
                    <Link to={routesPath.itemAdd} className="ims-nav--link-add">
                      <MdAdd className="ims-nav--add-icon" />
                    </Link>
                  )}
                </li>
                <li>
                  <NavLink
                    to={routesPath.itemAdjustment}
                    className="ims-nav--link"
                    id="click_itemAdjustment_module"
                    activeClassName="ims-nav--link active"
                    title="Item Adjustments"
                  >
                    <span
                      className={`ims-nav-menu-subtext ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      Item Adjustments
                    </span>
                  </NavLink>
                  {pathname === '/item-adjustments/add' ? (
                    <div
                      className="ims-nav--link-add"
                      style={{ cursor: 'not-allowed' }}
                    >
                      <MdAdd className="ims-nav--add-icon" />
                    </div>
                  ) : (
                    <Link
                      to={routesPath.itemAdjustmentAdd}
                      className="ims-nav--link-add"
                    >
                      <MdAdd className="ims-nav--add-icon" />
                    </Link>
                  )}
                </li>
              </ul>
            </li>

            {/* comment below onClick from li for ticket 4068
                Create contact form should open when user click on contacts plus icon.
            */}
            {/* onClick={() => this.setContactModule()} */}
            <li>
              <NavLink
                to={routesPath.contacts}
                className="ims-nav--link"
                id="click_contact_module"
                activeClassName="ims-nav--link active"
                title="Contacts"
              >
                <span className="ims-nav-menu-icon">
                  {/* <NewContactIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-users"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Contacts
                  </span>
                </span>
              </NavLink>
              {pathname === '/contacts/add' ? (
                <div
                  className="ims-nav--link-add"
                  style={{
                    cursor: 'not-allowed',
                  }}
                >
                  <MdAdd className="ims-nav--add-icon" />
                </div>
              ) : (
                <Link to={routesPath.contactAdd} className="ims-nav--link-add">
                  <MdAdd className="ims-nav--add-icon" />
                </Link>
              )}
            </li>

            <li>
              <NavLink
                to={routesPath.salesOrders}
                className="ims-nav--link"
                id="click_salesOrder_module"
                activeClassName="ims-nav--link active"
                title="Sales Orders"
              >
                <span className="ims-nav-menu-icon ">
                  <i className="fas fa-cash-register"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Sales Orders
                  </span>
                </span>
              </NavLink>
              {pathname === '/salesorders/add' ? (
                <div
                  className="ims-nav--link-add"
                  style={{ cursor: 'not-allowed' }}
                >
                  <MdAdd className="ims-nav--add-icon" />
                </div>
              ) : (
                <Link
                  to={routesPath.salesOrderAdd}
                  className="ims-nav--link-add"
                >
                  <MdAdd className="ims-nav--add-icon" />
                </Link>
              )}
            </li>
            <li>
              <NavLink
                to={routesPath.packages}
                className="ims-nav--link"
                id="click_package_module"
                activeClassName="ims-nav--link active"
                title="Packages"
              >
                <span className="ims-nav-menu-icon">
                  {/* <NewPackageIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-box-open"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Packages
                  </span>
                </span>
              </NavLink>
              {pathname === '/packages/add' ? (
                <div
                  className="ims-nav--link-add"
                  style={{
                    cursor: 'not-allowed',
                  }}
                >
                  <MdAdd className="ims-nav--add-icon" />
                </div>
              ) : (
                <Link to={routesPath.packageAdd} className="ims-nav--link-add">
                  <MdAdd className="ims-nav--add-icon" />
                </Link>
              )}
            </li>
            <li onClick={() => this.setInvModule()}>
              <NavLink
                to={routesPath.invoices}
                className="ims-nav--link"
                id="click_invoice_module"
                activeClassName="ims-nav--link active"
                title="Invoices"
              >
                <span className="ims-nav-menu-icon ">
                  {/* <NewInvoiceIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-file-invoice-dollar"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Invoices
                  </span>
                </span>
              </NavLink>
              {pathname === '/invoices/add' ? (
                <div
                  className="ims-nav--link-add"
                  style={{
                    cursor: 'not-allowed',
                  }}
                >
                  <MdAdd className="ims-nav--add-icon" />
                </div>
              ) : (
                <Link to={routesPath.invoiceAdd} className="ims-nav--link-add">
                  <MdAdd className="ims-nav--add-icon" />
                </Link>
              )}
            </li>

            {/* Customer Payment */}
            <li>
              <NavLink
                to={routesPath.customerPayment}
                className="ims-nav--link"
                id="click_customer_payment_module"
                activeClassName="ims-nav--link active"
                title="Payments Received"
              >
                <span className="ims-nav-menu-icon ">
                  {/* <RecordPaymentIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-money-bill"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Payments Received
                  </span>
                </span>
              </NavLink>
              {pathname === ' /paymentsmade/add' ? (
                <div
                  className="ims-nav--link-add"
                  style={{
                    cursor: 'not-allowed',
                  }}
                >
                  <MdAdd className="ims-nav--add-icon" />
                </div>
              ) : (
                <Link
                  to={routesPath.customerPaymentAdd}
                  className="ims-nav--link-add"
                >
                  <MdAdd className="ims-nav--add-icon" />
                </Link>
              )}
            </li>
            {/* Customer Payment */}

            {/* Start */}
            <li>
              <button
                className="ims-nav--item-button"
                title="Returns"
                onClick={this.handleReturnClick}
                id="click_return_module"
              >
                <span className="ims-nav-menu-icon ">
                  <NewSalesReturnIcon className="ims-nav--link-icon" />
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Returns
                  </span>
                </span>
                {returnExpanded ? (
                  <ItemExpandedIcon className="item-expand-icon" />
                ) : (
                  <ItemExpandIcon className="item-expand-icon" />
                )}
              </button>
              <ul
                className={`ims-nav--item-ul ${
                  returnExpanded && viewNavigation ? 'show-ul' : ''
                }`}
              >
                <li>
                  <NavLink
                    to={routesPath.returns}
                    className="ims-nav--link"
                    activeClassName="ims-nav--link active"
                    title="Sales Return"
                  >
                    <span
                      className={`ims-nav-menu-subtext ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      Sales Return
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesPath.creditNote}
                    className="ims-nav--link"
                    activeClassName="ims-nav--link active"
                    title="Credit Note"
                    id="click_creditNote_module"
                  >
                    <span
                      className={`ims-nav-menu-subtext ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      Credit Note
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* End */}
            <li>
              <NavLink
                to={routesPath.purchaseOrders}
                className="ims-nav--link"
                id="click_purchaseOrder_module"
                activeClassName="ims-nav--link active"
                title="Purchase Orders"
              >
                <span className="ims-nav-menu-icon ">
                  {/* <NewPurchaseOrderIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-shopping-bag"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Purchase Orders
                  </span>
                </span>
              </NavLink>
              {pathname === '/purchaseorders/add' ? (
                <div
                  className="ims-nav--link-add"
                  style={{ cursor: 'not-allowed' }}
                >
                  <MdAdd className="ims-nav--add-icon" />
                </div>
              ) : (
                <Link
                  to={routesPath.purchaseOrderAdd}
                  className="ims-nav--link-add"
                >
                  <MdAdd className="ims-nav--add-icon" />
                </Link>
              )}
            </li>
            <li>
              <NavLink
                to={routesPath.bills}
                className="ims-nav--link"
                id="click_bills_module"
                activeClassName="ims-nav--link active"
                title="Bills"
              >
                <span className="ims-nav-menu-icon">
                  {/* <NewBillIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-scroll"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Bills
                  </span>
                </span>
              </NavLink>
              {pathname === routesPath.billAdd ? (
                <div
                  className="ims-nav--link-add"
                  style={{ cursor: 'not-allowed' }}
                >
                  <MdAdd className="ims-nav--add-icon" />
                </div>
              ) : (
                <Link to={routesPath.billAdd} className="ims-nav--link-add">
                  <MdAdd className="ims-nav--add-icon" />
                </Link>
              )}
            </li>
            <li>
              <NavLink
                to={routesPath.reports[0].path}
                className="ims-nav--link"
                activeClassName="ims-nav--link active"
                title="Reports"
              >
                <span className="ims-nav-menu-icon">
                  {/* <NewReportIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-chart-line"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Reports
                  </span>
                </span>
              </NavLink>
            </li>
            {/* Start */}
            <li>
              <button
                className="ims-nav--item-button"
                title="Settings"
                onClick={this.handleSettingsClick}
                id="click_settings_module"
              >
                <span className="ims-nav-menu-icon ">
                  {/* <SettingIcon className="ims-nav--link-icon settings_nav_icon" />{' '} */}
                  <i className="fas fa-cog"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Settings
                  </span>
                </span>
                {settingsExpanded ? (
                  <ItemExpandedIcon className="item-expand-icon" />
                ) : (
                  <ItemExpandIcon className="item-expand-icon" />
                )}
              </button>
              <ul
                className={`ims-nav--item-ul ${
                  settingsExpanded && viewNavigation ? 'show-ul-settings' : ''
                }`}
              >
                <li>
                  <NavLink
                    to={routesPath.manageOrg}
                    className="ims-nav--link"
                    isActive={() =>
                      window.location.pathname.includes('/organization')
                        ? true
                        : false
                    }
                    activeClassName="ims-nav--link active"
                    title="Manage Businesses"
                    exact
                  >
                    <span
                      className={`ims-nav-menu-subtext manage_oraganization_btn ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      Manage Businesses
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesPath.organization}
                    className="ims-nav--link"
                    activeClassName="ims-nav--link active"
                    title="User & Access"
                  >
                    <span
                      className={`ims-nav-menu-subtext ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      User & Access
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesPath.settings}
                    className="ims-nav--link"
                    activeClassName="ims-nav--link active"
                    title="System"
                    isActive={() =>
                      hasSubtring(
                        [
                          routesPath.settings,
                          routesPath.tax,
                          routesPath.taxAdd,
                          routesPath.taxEdit,
                          routesPath.deliveryMethod,
                          routesPath.deliveryMethodAdd,
                          routesPath.deliveryMethodEdit,
                          routesPath.warehouses,
                          routesPath.warehouseAdd,
                          routesPath.warehouseEdit,
                          routesPath.inventoryAdd,
                          routesPath.currencies,
                          routesPath.currencyAdd,
                          routesPath.currencyEdit,
                          routesPath.preference,
                          routesPath.inventory,
                          routesPath.paymentMethod,
                          routesPath.chartOfAccount,
                        ],
                        this.props.location.pathname
                      )
                    }
                  >
                    <span
                      className={`ims-nav-menu-subtext ${
                        toggleMenuItems ? 'slide' : ''
                      }`}
                    >
                      System
                    </span>
                  </NavLink>
                </li>
                {this.state.isSuperAccess && (
                  <li>
                    <NavLink
                      to={routesPath.integrations}
                      className="ims-nav--link"
                      activeClassName="ims-nav--link active"
                      title="Integrations"
                    >
                      <span
                        className={`ims-nav-menu-subtext ${
                          toggleMenuItems ? 'slide' : ''
                        }`}
                      >
                        Integrations
                      </span>
                    </NavLink>
                  </li>
                )}

                {ssoConnected ? (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_SSO_REDIRECT_DEV}/myProfile`}
                      className="ims-nav--link"
                      activeClassName="ims-nav--link active"
                      title="My Account"
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      <span
                        className={`ims-nav-menu-subtext ${
                          toggleMenuItems ? 'slide' : ''
                        }`}
                      >
                        My Account
                      </span>
                    </a>
                  </li>
                ) : (
                  <li>
                    <NavLink
                      to={routesPath.myAccount}
                      className="ims-nav--link"
                      activeClassName="ims-nav--link active"
                      title="My Account"
                    >
                      <span
                        className={`ims-nav-menu-subtext ${
                          toggleMenuItems ? 'slide' : ''
                        }`}
                      >
                        My Account
                      </span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
            {/* End */}
            <li>
              <NavLink
                to={routesPath.packagesplan}
                className="ims-nav--link"
                activeClassName="ims-nav--link active"
                title="Subscription Plan"
              >
                <span className="ims-nav-menu-icon  ">
                  {/* <SubscriptionPlanIcon className="ims-nav--link-icon" /> */}
                  <i className="fas fa-tasks"></i>
                  <span
                    className={`ims-nav-menu-text ${
                      toggleMenuItems ? 'slide' : ''
                    }`}
                  >
                    Subscription Plan
                  </span>
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(Navigation);
