import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';
import getDate from '../../../../Helpers/Date/GetDate';
import {
  getObjFromLS,
  //getStringValueFromLS,
  setObjInLS,
  setValueInLS,
} from '../../../../Helpers/LocalStorage';
import { NewItemAdjustmentIcon } from '../../../../Assets/Navigation/NavigationIcons';

import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import Box from '../../../../Components/Layout/Box';
import { ActionMenu } from '../../../../Components/CheckedMenu/CheckedMenu';
import ListTableImageColumn from '../../../../Components/ListTable/ListTableImageColumn';
import Table from '../../../../Components/Table/Table';
import { MdClose } from '../../../../Common/Icons';
import Loader from '../../../../Components/Loader/Loader';
import NotFound from '../../../../Components/NotFound/NotFound';
import { checkError } from '../../../../Helpers/AuthHelper';
import { HeaderMenus } from '../../../../Components';

class ItemAdjustmentDetails extends Component {
  id = this.props.idFromHeaderWrapper
    ? this.props.idFromHeaderWrapper
    : this.props.match.params.id;
  state = {
    itemAdjustment: {},
    loading: true,
    notFound: false,
    detailsNo: 'Adjustment Summary',
  };

  componentDidMount() {
    document.title = 'Item Adjustment Details';
    this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', `itemadjustments/${this.id}`)
      .then((res) => {
        this.setState({
          itemAdjustment: res,
          detailsNo: this.state.detailsNo,
          loading: false,
        });
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          presistDetailPage: true,
        });
      })
      .catch((err) => {
        checkError(err);
        this.setState({ notFound: true, loading: false });
      });
  }

  renderActionMenu() {
    const hasDeletePermission = this.hasPermission('itemadjustment', 'Delete');
    const menusList = [
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            this.id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable: hasDeletePermission,
      },
      {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/item-adjustments/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }
  renderAccountandAditionalInfo() {
    const { itemAdjustment } = this.state;
    const {
      account: {
        title,
        // account_group: { name },
      },
      adjustment_date: date,
      description,
      item_adjustment_created_by: { name: adjustedBy },
      reason,
      warehouse_name,
      adjustment_type: adjustmentType,
      //warehouse_name : warehouse.name
    } = itemAdjustment;

    return (
      <div className="adjustment_details">
        <div className="section-details--info">
          <div className="field-row">
            <div className="section-details--info-title semi-bold heading_font">
              Adjusted By
            </div>
            <div className="semi-bold">{adjustedBy}</div>
          </div>
          <hr className="bottom_line" />

          <div className="field-row">
            <div className="adjust_date section-details--info-title semi-bold heading_font">
              Adjustment Date
            </div>
            <div>{getDate(date)}</div>
          </div>
          <hr className="bottom_line" />

          <div className="field-row">
            <div className="section-details--info-title semi-bold heading_font">
              Account
            </div>
            <div className="semi-bold">{title}</div>
          </div>
          <hr className="bottom_line" />

          {/* <div className="field-row">
            <div className="section-details--info-title semi-bold heading_font">
              Account Group
            </div>
            <div>{'name'}</div>
          </div> */}
          {/* <hr className="bottom_line" /> */}
          <div className="field-row">
            <div className="section-details--info-title semi-bold heading_font">
              Type
            </div>
            <div className="capitalize">{adjustmentType}</div>
          </div>
          <hr className="bottom_line" />

          <div className="field-row">
            <div className="section-details--info-title semi-bold heading_font">
              Warehouse
            </div>
            <div className="capitalize">
              <span>{warehouse_name}</span>
            </div>
          </div>
          <hr className="bottom_line" />

          <div className="section-details--info-title semi-bold heading_font">
            Reason
          </div>
          <div className="word-break">{reason}</div>

          <hr className="bottom_line" />

          <div className="section-details--info-title semi-bold heading_font">
            Description
          </div>
          <div className="word-break">{description}</div>
        </div>
      </div>
    );
  }
  renderLayoutView() {
    // const { itemAdjustment } = this.state
    // const { item_adjustment_created_by: {name: adjustedBy} } = itemAdjustment

    return (
      <Fragment>
        <div className="float-left w-100 adjustment_summary_update position-relative">
          <div className="close_icon">
            <MdClose
              onClick={() => {
                let dataFromLS = getObjFromLS('module');
                setObjInLS('module', {
                  ...dataFromLS,
                  presistDetailPage: false,
                });
                setValueInLS('fullscreen', 'true');
                sessionStorage.setItem('once', true);

                this.props.history.push('/r');
              }}
            />
          </div>
          {this.renderAccountandAditionalInfo()}
        </div>
      </Fragment>
    );
  }

  prepareAdjustmentItemsData(items) {
    return items.map((item) => ({
      name: (
        <ListTableImageColumn
          name={item?.item?.item_name}
          sku={item?.item?.sku}
          images={item?.item?.images}
          id={item?.item_id}
          showModalUponClick
        />
      ),

      adjustedAmount: `${item?.adjusted_amount} ${
        item?.item.unit ? item.item.unit : ''
      }`,
    }));
  }

  renderSidebar() {
    const preparedData = this.prepareAdjustmentItemsData(
      this.state.itemAdjustment.item_adjustment_details
    );

    const tableHeaders = ['Item', 'Adjusted'];
    const tableData = ['name', 'adjustedAmount'];
    const rightAlignColumns = [2];
    return (
      // <Box  className="no-padding-body itemstab">

      <Box className="itemstab item_adjustment_table">
        <div className="height_custom_adjustment scrollbar_style">
          <Table
            list={preparedData}
            tableHeaders={tableHeaders}
            tableData={tableData}
            rightAlignColumns={rightAlignColumns}
            className="details-table"
          />
        </div>
      </Box>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return (
      // <GridLayout>
      //   <Grid>{this.renderLayoutView()}</Grid>
      //   <Grid transparent>{this.renderSidebar()}</Grid>
      // </GridLayout>
      <Fragment>
        {this.renderLayoutView()}
        {this.renderSidebar()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(ItemAdjustmentDetails, {
  name: 'Item Adjustments', // name prop is used in headerwrapper change in name also need to change in headerwrapper as well
  tableIcon: 'general_module_icon icon_top',
  deleteName: 'Item Adjustment',
  Icon: NewItemAdjustmentIcon,
  baseUrl: 'itemadjustments',
  redirectUrl: '/item-adjustments',
  onlyMenu: true,
  showName: true,
  permissionName: 'itemadjustment',
});
