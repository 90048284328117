import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { NewPurchaseOrderIcon } from '../../../Assets/Navigation/NavigationIcons';
import { routesPath } from '../../Navigation/NavigationUtils';
// import getDate from '../../../Helpers/Date/GetDate'
// import {formatNumber} from '../../../Helpers/FormatNumber'
import getStatusColor from '../../../Helpers/GetStatusColor';
import ExportIcon from '../../../Assets/General/ExportIcon';
import PurchaseOrderImage from '../../../Components/Onboarding/Diagrams/PurchaseOrderImage';
import PurchaseOrderFlowDiagram from '../../../Components/Onboarding/Diagrams/PurchaseOrderFlowDiagram';

import Alert from '../../../Components/Alert/Alert';
import { restRequest } from '../../../Helpers/RequestHelper';
import HeaderWrapper from '../../../Components/HeaderWrapper/HeaderWrapper';
import CancelItems from './CancelItems';
import PDFViewer from '../../../Components/PDFViewer/PDFViewer';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import ListTable from '../../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  Options,
  ButtonLink,
} from '../../../Components/CheckedMenu/CheckedMenu';
import GenerateOptions from '../../../Helpers/ListHelper/GenerateOptions';
import SearchResult from '../../../Components/SearchBar/SearchResult';
import { HorizontalIconColumnJoin } from '../../../Components/ListTable/ListTableColumnJoin';
// import Onboarding from '../../../Components/Onboarding/Onboarding'
import Onboarding from '../../../Components/Onboarding/InitialOnboardingScreen';
import PurchaseOrderDetails from '../../../Pages/PurchaseOrder/PurchaseOrderDetails';
import AddPurchaseOrder from '../../../Pages/PurchaseOrder/AddPurchaseOrder';
import EditPurchaseOrder from '../../../Pages/PurchaseOrder/EditPurchaseOrder';
import CircleStatus from '../../../Assets/SVG/Circle';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../../Helpers/LocalStorage';
import Export from '../../../Components/Export/Export';

import './PurchaseOrder.css';
import EditIcon from '../../../Assets/General/EditIcon';
import PdfIcon from '../../../Assets/General/PdfIcon';
import EmailIcon from '../../../Assets/General/EmailIcon';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import CloneIcon from '../../../Assets/General/CloneIcon';
import MarkAsIssuedIcon from '../../../Assets/General/MarkAsIssuedIcon';
import MarkAsCancelledIcon from '../../../Assets/General/MarkAsCancelledIcon';
import CancelItemIcon from '../../../Assets/General/CancelItemIcon';
import ReopenCancelItemIcon from '../../../Assets/General/ReopenCancelItemIcon';
import DropshipIcon from '../../../Assets/General/DropshipIcon';
import BackorderIcon from '../../../Assets/General/BackorderIcon';
import { checkError } from '../../../Helpers/AuthHelper';
import { AccessDenied } from '../../../Components';

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrderId: null,
      loading: false,
      accessDenied: false,
      data: [],
      dataExpanded: [],

      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 0,

      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',

      cancelItemsModal: false,
      cancelItemsPOId: 0,
      showModal: false,
      showPDF: false,
      pdf: null,
      showExportModal: false,
      search: '',
      start: '',
      end: '',
      contactId: '',
      contactName: null,
    };
    this.getID = this.getID.bind(this);
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      label: 'All',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'draft',
      label: 'Draft',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'draft', page: 1 }),
    },
    {
      id: 'issued',
      label: 'Issued',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'issued', page: 1 }),
    },
    {
      id: 'partially received',
      label: 'Partially Received',
      type: 'link',
      onClick: () =>
        this.generateRoute({ filter: 'partially received', page: 1 }),
    },
    {
      id: 'received',
      label: 'Received',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'received', page: 1 }),
    },
    {
      id: 'cancelled',
      label: 'Cancelled',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'cancelled', page: 1 }),
    },
    {
      id: 'closed',
      label: 'Closed',
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'closed', page: 1 }),
    },
    /*{
      id: 'dropship',
      label: "Dropshipped",
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'dropship', page: 1 })
    },
    {
      id: 'backorder',
      label: "Backorder",
      type: 'link',
      onClick: () => this.generateRoute({ filter: 'backorder', page: 1 })
    },*/
    {
      id: 'not fully received',
      label: 'Not Fully Received',
      type: 'link',
      onClick: () =>
        this.generateRoute({ filter: 'not fully received', page: 1 }),
    },
  ];

  sortOptions = [
    {
      id: 'order_date',
      label: 'Order Date',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('order_date') }),
    },
    {
      id: 'purchase_order_no',
      label: 'Purchase Order No',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('purchase_order_no') }),
    },
    /*{
      id: 'vendor_id',
      label: 'Vendor',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('vendor_id') })
    },*/
    {
      id: 'display_name',
      label: 'Vendor',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('display_name') }),
    },
    /*{
     id: 'expected_delivery_date',
     label: 'Delivery Date',
     type: 'link',
     onClick: () => this.generateRoute({ ...this.prepareSortFilter('expected_delivery_date') })

   },
   {
     id: 'reference',
     label: 'Reference',
     type: 'link',
     onClick: () => this.generateRoute({ ...this.prepareSortFilter('reference') })
   },*/
    {
      id: 'status',
      label: 'Status',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('status') }),
    },
    {
      id: 'bill_status',
      label: 'Billed',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('bill_status') }),
    },
    {
      id: 'receive_status',
      label: 'Received',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('receive_status') }),
    },
    {
      id: 'total',
      label: 'Amount',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('total') }),
    },
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
    /*{
      id: 'updated_at',
      label: 'Last Modified Time',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('updated_at') })
    }*/
  ];

  importExportOptions = [
    {
      label: 'Export Purchase Order',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () =>
        !this.hasViewPermission('purchaseorder') ||
        JSON.parse(localStorage.getItem('exportBtn')),
    },
  ];

  componentDidMount() {
    document.title = 'Purchase Orders';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
          bulkSelectedData: [],
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search, start, end, contactId } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'draft':
      case 'issued':
      case 'partially received':
      case 'received':
      case 'cancelled':
      case 'closed':
      case 'dropship':
      case 'backorder':
      case 'not fully received':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'order_date':
      case 'purchase_order_no':
      case 'vendor_id':
      case 'status':
      case 'display_name':
      case 'bill_status':
      case 'receive_status':
      case 'total':
      case 'created_at':
      case 'updated_at':
      case 'reference':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  fetchData = async () => {
    this.setState({ loading: true });

    const currentFilter =
      this.state.currentFilter === 'all' ? '' : this.state.currentFilter;
    var url = `purchaseorders?filter=${currentFilter}&page=${this.state.currentPage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${this.state.search}`;
    if (this.state.start && this.state.end) {
      url = `${url}&start=${this.state.start}&end=${this.state.end}`;
    }
    if (this.state.contactId) {
      url = `${url}&contactId=${this.state.contactId}`;
    }
    await restRequest('get', url)
      .then((res) => {
        let obj = {
          prevId: null,
          id: res.data[0] && res.data[0].id,
          nextId: res.data[1] && res.data[1].id,
          moduleName: 'purchaseorders',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'purchaseorders') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'purchaseorders' &&
          (dataFromLS.queryParam !== window.location.search ||
            localStorage.getItem('org_switch') === 'true')
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
          localStorage.removeItem('org_switch');
        }
        if (!res?.data?.length) {
          setObjInLS('module', obj);
        }
        this.setState(
          {
            data: this.prepareDataForTableCollapsed(res.data),
            dataExpanded: this.prepareDataForTable(res.data),
            totalPage: res.last_page,
            totalRecords: res.total,
            loading: false,
          },
          () => {
            this.setState({ mainLoading: false });
          }
        );
        if (this.state.contactId) {
          this.setState({ contactName: res.data[0]?.['vendor'].display_name });
        }
      })
      .catch((error) => {
        if (error && error.response.status === 403) {
          this.setState({ accessDenied: true });
        }

        this.setState({ loading: false, mainLoading: false });
        checkError(error, this.props.handleToast);

        this.props.handleToast(error, 'error');
      });
    this.setState({ loading: false });
  };

  prepareDataForTable(data) {
    return data.map((purchaseorder) => ({
      id: purchaseorder.id,

      Date: {
        id: 'order_date',
        sortable: true,
        getValue: function () {
          return purchaseorder[this.id];
        },
      },
      'Purchase Order': {
        id: 'purchase_order_no',
        sortable: true,
        getValue: function () {
          return purchaseorder[this.id];
        },
      },
      Vendor: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return purchaseorder['vendor'][this.id];
        },
      },
      /*'Delivery Date': {
        id: 'expected_delivery_date',
        sortable: true,
        getValue: function () { return purchaseorder[this.id] }
      },
      'Reference': {
        id: 'reference',
        sortable: true,
        getValue: function () { return purchaseorder[this.id] }
      },*/
      Status: {
        id: 'status',
        sortable: true,
        hideStatusColor: true, // hide direct status color show for this status key
        getValue: function () {
          const Icons = [];
          const tooltips = [];
          const showStatus = [];

          Icons.push(CloneIcon);
          tooltips.push('Clone order');
          if (purchaseorder.clone_status === 1) {
            showStatus.push(true);
          } else {
            showStatus.push(false);
          }
          Icons.push(DropshipIcon);
          tooltips.push('Dropshipment');
          if (purchaseorder.type === 'dropship') {
            showStatus.push(true);
          } else {
            showStatus.push(false);
          }
          Icons.push(BackorderIcon);
          tooltips.push('Backorder');
          if (purchaseorder.type === 'backorder') {
            showStatus.push(true);
          } else {
            showStatus.push(false);
          }
          return (
            <HorizontalIconColumnJoin
              labelClass={getStatusColor(purchaseorder[this.id])}
              label={purchaseorder[this.id]}
              Icons={Icons}
              tooltips={tooltips}
              showStatus={showStatus}
            />
          );
        },
      },
      Billed: {
        id: 'bill_status',
        sortable: true,
        getValue: function () {
          let status = purchaseorder[this.id];
          return (
            <CircleStatus
              status={status}
              title={status !== null ? status : 'Not Billed'}
            />
          );
        },
      },
      Received: {
        id: 'receive_status',
        sortable: true,
        getValue: function () {
          let status = purchaseorder[this.id];
          return (
            <CircleStatus
              status={status}
              title={status !== null ? status : 'Not Received'}
            />
          );
        },
      },
      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          /*return `${purchaseorder.currency.symbol}${formatNumber(purchaseorder[this.id])}`*/
          return `${purchaseorder.currency.symbol}${parseFloat(
            purchaseorder[this.id]
          ).toFixed(2)}`;
        },
      },
      // not displayable
      additional: {
        status: purchaseorder.status,
        type: purchaseorder.type,
        receiveStatus: purchaseorder.receive_status,
        billStatus: purchaseorder.bill_status,
        isItemCancelled: purchaseorder.is_item_cancelled,
        cloneStatus: purchaseorder.clone_status,
      },
    }));
  }

  prepareDataForTableCollapsed(data) {
    return data.map((purchaseorder) => ({
      id: purchaseorder.id,

      Date: {
        id: 'order_date',
        sortable: true,
        getValue: function () {
          return purchaseorder[this.id];
        },
      },
      Vendor: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return purchaseorder['vendor'][this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: () => (
          <div className="display-flex-row salesorder_compressed_status">
            <span className={`${getStatusColor(purchaseorder.status)} `}>
              {purchaseorder.status}
            </span>
            <span>
              <CircleStatus
                status={purchaseorder.bill_status}
                title={
                  purchaseorder.bill_status !== null
                    ? purchaseorder.bill_status
                    : 'Not Billed'
                }
              />
            </span>
            <span>
              <CircleStatus
                status={purchaseorder.receive_status}
                title={
                  purchaseorder.receive_status !== null
                    ? purchaseorder.receive_status
                    : 'Not Received'
                }
              />
            </span>
          </div>
        ),
      },

      // not displayable
      additional: {
        status: purchaseorder.status,
        type: purchaseorder.type,
        receiveStatus: purchaseorder.receive_status,
        billStatus: purchaseorder.bill_status,
        isItemCancelled: purchaseorder.is_item_cancelled,
      },
    }));
  }

  close = () => {
    this.setState({
      showModal: false,
      showPDF: false,
      pdf: null,
    });
  };

  openPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `purchaseorders/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };
  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'purchaseorders',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);

    this.setState({ purchaseOrderId: id });
  }
  markAsIssued = (id) => {
    restRequest('put', `purchaseorders/${id}/status/issued`)
      .then((res) => {
        this.props.handleToast('Purchase Order marked as issued.', 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };
  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Purchase Orders"
        Icon={ExportIcon}
        onClose={this.closeExportModal}
        renderActions={false}
      >
        <Export
          name="PurchaseOrders"
          onCancel={this.closeExportModal}
          submitURL="purchaseorders/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }
  markAsCancelled = (id) => {
    restRequest('put', `purchaseorders/${id}/status/cancelled`)
      .then((res) => {
        this.props.handleToast(
          'Purchase Order marked as Cancelled.',
          'success'
        );
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };

  bulkMarkAsIssued(ids) {
    restRequest('put', `purchaseorders/status/issued`, {
      ids: ids,
    })
      .then((res) => {
        this.props.handleToast(res.message, 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  bulkMarkAsCancelled(ids) {
    restRequest('put', `purchaseorders/status/cancelled`, {
      ids: ids,
    })
      .then((res) => {
        this.props.handleToast(res.message, 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  getCurrentPO = (id) => {
    return this.state.data.find((purchaseorder) => {
      return purchaseorder.id === id;
    });
  };

  toggleCancelItemsModal = (id = false) => {
    if (!id) id = this.state.bulkSelectedData[0];

    this.setState({
      cancelItemsModal: !this.state.cancelItemsModal,
      cancelItemsPOId: id,
    });
  };

  cancelItems() {
    return (
      <CustomModal
        showModal={this.state.cancelItemsModal}
        size="large"
        title="Cancel Items"
        renderActions={false}
        onClose={this.toggleCancelItemsModal}
      >
        <CancelItems
          id={this.state.cancelItemsPOId}
          fetchData={this.fetchData}
          closeModal={this.toggleCancelItemsModal}
          updateCheckedState={this.updateCheckedState}
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  reopenCancelItems = (id) => {
    restRequest('put', `purchaseorders/${id}/reopen-cancelled-items`)
      .then(() => {
        this.props.handleToast('Cancelled items has been reopened.', 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  };

  populateConditionalOptions(id, status, isItemCancelled) {
    const hasEditPermission = this.hasPermission('purchaseorder', 'Edit');
    const options = [];
    if (!hasEditPermission) return options;
    if (status === 'draft') {
      options.push({
        label: 'Mark as Issued',
        onClick: () => this.markAsIssued(id),
      });
    }
    if (status === 'issued') {
      options.push({
        label: 'Mark as Cancelled',
        onClick: () => this.markAsCancelled(id),
      });
    }
    if (
      status !== 'cancelled' &&
      status !== 'draft' &&
      status !== 'closed' &&
      isItemCancelled !== 1
    ) {
      options.push({
        label: 'Cancel Items',
        onClick: () => this.toggleCancelItemsModal(),
      });
    }
    if (isItemCancelled === 1) {
      options.push({
        label: 'Reopen Cancel Items',
        onClick: () => this.reopenCancelItems(id),
      });
    }
    options.push({
      label: 'Export Selected',
      onClick: () => this.exportCurrentView(),
      disabled: () => !this.hasViewPermission('purchaseorder'),
    });

    return options;
  }

  exportCurrentView = () => {
    restRequest('post', `purchaseorders/exportSelected`, {
      ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `PurchaseOrders.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(
          `Purchase Orders exported successfully`,
          'success'
        );
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        //this.props.handleToast(error, 'error')
        checkError(error, this.props.handleToast);
      });
  };
  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const checkedId = this.state.bulkSelectedData[0];
    const checkedPO = this.getCurrentPO(checkedId);
    const options = this.populateConditionalOptions(
      checkedId,
      checkedPO.additional.status,
      checkedPO.additional.isItemCancelled
    );
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('purchaseorder', 'All');
    return (
      <Fragment>
        <CheckedMenu count={this.state.bulkSelectedData.length}>
          <ButtonLink
            enable={hasCreatePermission}
            to={`/purchaseorders/clone/${checkedId}`}
            type="clone"
          />
          <ButtonLink
            enable={
              checkedPO.additional.status !== 'cancelled' &&
              checkedPO.additional.type !== 'dropship' &&
              hasEditPermission
            }
            to={`/purchaseorders/edit/${checkedId}`}
            type="edit"
          />

          <Button handleClick={() => this.openPdf(checkedId)} type="pdf" />
          <ButtonLink
            enable={hasCreatePermission}
            to={`/purchaseorders/email/${checkedId}`}
            type="email"
          />
          <Button
            enable={hasDeletePermission}
            handleClick={() => this.openDeleteModal(checkedId)}
            type="delete"
          />
          <Button handleClick={() => this.exportCurrentView()} type="export" />
          <Options enable={!!options.length} options={options} />
        </CheckedMenu>
      </Fragment>
    );
  };

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'purchaseorder',
      'All'
    );
    const hasViewPermission = this.hasViewPermission('purchaseorder');
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
        <Options
          enable={hasViewPermission}
          options={[
            {
              label: 'Mark as Issued',
              onClick: () => this.bulkMarkAsIssued(this.state.bulkSelectedData),
              disabled: () => !hasEditPermission,
            },
            {
              label: 'Mark as Cancelled',
              onClick: () =>
                this.bulkMarkAsCancelled(this.state.bulkSelectedData),
              disabled: () => !hasEditPermission,
            },
            {
              label: 'Export Selected',
              onClick: () => this.exportCurrentView(),
              disabled: () => !this.hasViewPermission('purchaseorder'),
            },
          ]}
        />
      </CheckedMenu>
    );
  };

  isOrderEditable(id) {
    let currentOrder = this.getCurrentPO(id);
    return (
      currentOrder.additional.billStatus !== null ||
      currentOrder.additional.receiveStatus !== null ||
      currentOrder.additional.type === 'dropship' ||
      currentOrder.additional.type === 'backorder' ||
      currentOrder.additional.status === 'cancelled'
    );
  }

  isOrderCreateable() {
    const [hasCreatePermission] = this.hasPermission('invoice', 'All'); //, hasEditPermission, hasDeletePermission
    return !hasCreatePermission;
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.props.history.push(`purchaseorders/edit/${id}`),
        disabled: (id) => this.isOrderEditable(id),
      },
      {
        label: 'View PDF',
        icon: PdfIcon,
        onClick: this.openPdf,
      },
      {
        label: 'Email',
        // type: 'link',
        icon: EmailIcon,
        // onClick: id => `purchaseorders/email/${id}`
        onClick: (id) => this.props.history.push(`/purchaseorders/email/${id}`),
        disabled: () => this.isOrderCreateable(),
      },
      {
        label: 'Clone',
        icon: CloneIcon,
        type: 'link',
        onClick: (id) => `/purchaseorders/clone/${id}`,
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: this.openDeleteModal,
        disabled: (id) => {
          const item = this.getCurrentPO(id);
          return !(
            !item.additional.receiveStatus && !item.additional.billStatus
          );
        },
      },
      {
        label: 'Mark as Issued',
        icon: MarkAsIssuedIcon,
        onClick: (id) => this.markAsIssued(id),
        disabled: (id) =>
          !(this.getCurrentPO(id).additional.status === 'draft'),
      },
      {
        label: 'Mark as Cancelled',
        icon: MarkAsCancelledIcon,
        onClick: (id) => this.markAsCancelled(id),
        disabled: (id) => {
          const item = this.getCurrentPO(id);
          return !(
            item.additional.status === 'issued' &&
            !item.additional.receiveStatus &&
            !item.additional.billStatus
          );
        },
      },
      {
        label: 'Cancel Items',
        icon: CancelItemIcon,
        onClick: (id) => this.toggleCancelItemsModal(id),
        disabled: (id) => {
          const currentItem = this.getCurrentPO(id);
          return !(
            currentItem.additional.status !== 'cancelled' &&
            currentItem.additional.status !== 'draft' &&
            currentItem.additional.status !== 'closed' &&
            currentItem.additional.isItemCancelled !== 1 &&
            !currentItem.additional.receiveStatus &&
            !currentItem.additional.billStatus
          );
        },
      },
      {
        label: 'Reopen Cancel Items',
        icon: ReopenCancelItemIcon,
        onClick: (id) => this.reopenCancelItems(id),
        disabled: (id) =>
          !(this.getCurrentPO(id).additional.isItemCancelled === 1),
      },
    ];

    const permissions = this.hasPermission('purchaseorder', 'All');

    const allowedRowOptions = GenerateOptions(permissions, rowOptions, [
      'Mark as Issued',
      'Mark as Cancelled',
      'Cancel Items',
      'Reopen Cancel Items',
    ]);

    return allowedRowOptions;
  }

  renderTable() {
    const {
      data,
      bulkSelectedData,
      dataExpanded,
      sortFilter,
      orderBy,
      //loading,
      mainLoading,
    } = this.state;
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        // if(getObjFromLS('module') === null) {
        //   let obj={
        //     prevId:null,
        //     id:null,
        //     nextId:null,
        //     moduleName:'purchaseorders',
        //     urlPath:window.location.pathname,
        //     queryParam:window.location.search,
        //   }
        //   setObjInLS('module',obj)
        //  }
        return (
          // <Onboarding
          //   name="Purchase Order"
          //   Image={PurchaseOrderImage}
          //   FlowDiagram={PurchaseOrderFlowDiagram}
          //   title="Manage Your Purchase Activity"
          //   description="Create, customize and manage your purchase orders"
          //   buttonLabel="Create Purchase Order"
          //   buttonLink="/purchaseorders/add"
          // />
          this.state.accessDenied ?
            <AccessDenied />
            : <Onboarding
            name="Purchase Order"
            Image={PurchaseOrderImage}
            FlowDiagram={PurchaseOrderFlowDiagram}
            videoFrame={
              <iframe
                width="700"
                height="400"
                src="https://www.youtube.com/embed/TRyVJe0NEcw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            DisplayIcon={NewPurchaseOrderIcon}
            headerTitle="Purchase Order"
            title="Manage Your Purchase Activity"
            description="Create, customize and manage your purchase orders"
            buttonLabel="Create Purchase Order"
            buttonLink="/purchaseorders/add"
          />
        );
      }
    }
    let fullscreen = getStringValueFromLS('fullscreen');
    let fullscreenFlag = getStringValueFromLS('fullscreenFlag');
    if (fullscreenFlag === 'true') {
      sessionStorage.setItem('fullscreen', false);
      sessionStorage.setItem('fullscreenFlag', false);
    }

    return (
      <div className="purchase_order_table_listing">
        <ListTable
          data={fullscreen === 'true' ? dataExpanded : data}
          selectedData={bulkSelectedData}
          rowOptions={this.prepareRowOptions()}
          linkTo="/purchaseorders"
          preferenceLink="/preference/purchaseorder"
          className={
            fullscreen === 'true'
              ? 'purchase-order-fullscreen fullscreen global-status-listing'
              : 'compressedscreen purchase-compressed'
          }
          preference="Purchase Order Preferences"
          sortFilter={sortFilter}
          orderBy={orderBy}
          loading={mainLoading}
          generateRoute={this.generateRoute}
          handleCheckAll={this.handleCheckAll}
          handleCheckSingle={this.handleCheckSingle}
          getID={this.getID}
          moduleName="purchaseorders"
          totalRecords={this.state.totalRecords}
        />
      </div>
    );
  }

  onClickHandler = () => {
    this.setState({ contactId: '', contactName: '' });
    let dataFromLS = getObjFromLS('module');
    //console.log('data', dataFromLS)
    if (dataFromLS && dataFromLS.moduleName === 'purchaseorders') {
      setObjInLS('module', {
        ...dataFromLS,
        urlPath: routesPath.purchaseOrders,
        queryParam: '?filter=all&sort=created_at&page=1&orderBy=desc&view=20',
      });
      this.props.history.push('/r');
    } else {
      this.props.history.push(routesPath.purchaseOrders);
    }
  };

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.purchaseOrders}
        >
          <SearchResult
            criterias={['Purchase Order No', 'Vendor Name', 'Reference']}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Purchase Orders"
          linkTo={routesPath.purchaseOrders}
          crossIcon={false}
          // onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        {this.renderExportModal()}
        {this.cancelItems()}
        <PDFViewer
          showPDF={this.state.showPDF}
          hidePDFModal={this.close}
          pdf={this.state.pdf}
        />
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default HeaderWrapper(
  PurchaseOrder,
  {
    name: 'Purchase Orders',
    Icon: NewPurchaseOrderIcon,
    // tableIcon:'purchaseOrder_table_icon',
    tableIcon: 'general_module_icon po_top',
    baseUrl: 'purchaseorders',
    deleteName: 'Purchase Order',
    redirectUrl: '/purchaseorders',
    buttonUrl: '/purchaseorders/add',
    buttonLabel: 'New Purchase Order',
    routeName: 'purchaseorders',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'purchaseorder',
  },
  PurchaseOrderDetails,
  AddPurchaseOrder,
  EditPurchaseOrder
);
