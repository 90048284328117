import React, { Component, Fragment } from 'react';

import Header from './Header/Header';
import Content from './Content/Content';
import Pages from './Pages/Pages';
import Footer from './Footer/Footer';

//import { seebizCloudUrl } from '../../Helpers/UrlHelper';
import {
  validToken,
  //setTokenRefreshToken,
  //restRequest,
} from '../../Helpers/index';
//import queryString from 'query-string';
import './LandingPage.css';
import { Loader } from '../UnsyncOrders';
import { validUser } from "../../Helpers/AuthHelper";
import Cookies from 'universal-cookie';
const cookies = new Cookies;

class LandingPage extends Component {
  state = {
    loading: false,
    caseTrue: false,
  };
  /*componentWillMount() {
    //console.log('compone will mount ');
    const query = queryString.parse(this?.props?.history?.location?.search);
    const { acc = '' } = query;
    let email = atob(acc);
    var filter =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(email) && !validToken()) {
      this.setState({
        loading: true,
        caseTrue: true,
      });
      restRequest('post', 'auth/email_login', {
        email,
      })
        .then((res) => {
          //console.log('line 36');
          setTokenRefreshToken(res.id_token, res.refresh_token, true);
          this.props.history.push('/dashboard');
        })
        .catch((error) => {
          this.setState({
            //loading: false,
            caseTrue: false,
          });
          //console.log('------- errrr', error);
          this.redirectToBlog();
        });
    }
  }*/

  componentDidMount() {
    //console.log('compone did mount');
    document.title = 'Seebiz | Inventory Home';
    window.scrollTo(0, 0);
    if (!this.state.caseTrue) {
      //console.log('line 59');
      this.redirectToBlog();
    }
  }

  redirectToBlog() {
    const redirect = localStorage.getItem("redirect")
    // add here that server is down or inactive account etc
    if (validUser() === 'in_active' && !redirect) {
      const account_status = cookies.get('account_status');
      //console.log('--------------------- here in landing page');
      cookies.remove('account_status', {
        path: '/',
        domain: `${process.env.REACT_APP_COOKIEPARENT}`,
      });
      switch(account_status){
        case 'inactive_user':
          //console.log('inactive_user');
          this.props.history.push('/userDeactivate');
          return false;
        case 'inactive_account':
          //console.log('inactive_account');
          this.props.history.push('/accountDeactivate');
          return false;
        default:
          //console.log('default');
          this.props.history.push('/serverAuthenticate');
          return false;
      }
      this.props.history.push('/userDeactivate');
    }
    else if (!validToken()) {
      localStorage.removeItem("redirect")
      window.location.href = process.env.REACT_APP_ENVIOURMENT_REDIRECT_URL;
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        {loading ? (
          <div
            style={{
              height: '100vh',
              display: 'flex',
              alignItem: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="home">
            {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (
              <Fragment>
                <Header />
                <Content />
                <Pages />
                <Footer />
              </Fragment>
            ) : (
              false
            )}
          </div>
        )}
      </>
    );
  }
}

export default LandingPage;
