import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { Loader } from '../Components';
import { restRequest } from './RequestHelper';
import withContext from '../Context/UserContextHOC';
import ErrorBoundary from '../Components/ErrorBoundry';
import { MapOldAccount } from '../Pages/ChartOfAccounts';
import { checkError, checkUpdatedPermission } from './AuthHelper';
import AccessDenied from '../Components/AccessDenied/AccessDenied';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const AuthenticatedRoute = ({
  context,
  handleToast,
  component: Component,
  checkPermission = false,
  ...rest
}) => {
  const skipButtonClicked = JSON.parse(
    localStorage.getItem('skipBtnoldAccount')
  );
  const history = useHistory();
  const [status, setStatus] = useState(false);
  const [showSkipButton, setshowskipbutton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [mapAccount, setMapAccount] = useState(true);
  useEffect(() => {
    restRequest('get', `old_accounts`)
      .then((res) => {
        if (res?.data?.status) {
          setStatus(res?.data?.status);
          setshowskipbutton(res?.data?.show_skip_button);
          setMapAccount(res?.data?.skip_mapping);
        } else {
          setStatus(false);
        }
      })
      .catch((error) => {
        checkError(error, handleToast);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const role = context.role;

  let permissions;
  if (role) {
    permissions = role.permissions;
  }
  if (
    window.location.pathname !== '/contacts' &&
    window.location.pathname !== '/r'
  ) {
    localStorage.removeItem('selectedChar');
  }
  // for checked false permission if admin update the permission () regardless of socket
  if (
    window.location.pathname !== '/' &&
    window.location.pathname !== '/dashboard' &&
    permissions &&
    !permissions.superAccess &&
    permissions[checkPermission] === false
  ) {
    checkUpdatedPermission(checkPermission, handleToast);
  }
  if (loading) {
    return <Loader />;
  }
  if (
    !checkPermission ||
    (permissions && permissions[checkPermission]) ||
    (permissions && permissions.superAccess)
  )
    return (
      // <ErrorBoundary key={window.location.pathname}>
      //   {history.location.pathname === '/organization/add' ||
      //   (history.location.pathname === '/oc/organization/choose' &&
      //     role.permissions.superAccess) ? (
      //     <Route
      //       {...rest}
      //       render={(props) => (
      //         <Component {...props} handleToast={handleToast} />
      //       )}
      //     />
      //   ) : role.permissions.superAccess ? (
      //     // Owner case
      //     (status && !skipButtonClicked) ||
      //     (!showSkipButton && !skipButtonClicked) ? (
      //       <MapOldAccount
      //         history={history}
      //         isLoading={isLoading}
      //         handleToast={handleToast}
      //         setIsLoading={setIsLoading}
      //       />
      //     ) : (
      //       <Route
      //         {...rest}
      //         render={(props) => (
      //           <Component {...props} handleToast={handleToast} />
      //         )}
      //       />
      //     )
      //   ) : // Invited case
      //   !status || mapAccount ? (
      //     (localStorage.setItem(
      //       'skipBtnoldAccount',
      //       !status ? JSON.parse(false) : JSON.parse(true)
      //     ),
      //     (
      //       <Route
      //         {...rest}
      //         render={(props) => (
      //           <Component {...props} handleToast={handleToast} />
      //         )}
      //       />
      //     ))
      //   ) : (
      //     <MapOldAccount
      //       history={history}
      //       isLoading={isLoading}
      //       handleToast={handleToast}
      //       setIsLoading={setIsLoading}
      //     />
      //   )}
      // </ErrorBoundary>
      <ErrorBoundary key={window.location.pathname}>
        {history.location.pathname === '/organization/add' ||
        history.location.pathname === '/oc/organization/choose' ? (
          // Allow access to these paths without restrictions
          <Route
            {...rest}
            render={(props) => (
              <Component {...props} handleToast={handleToast} />
            )}
          />
        ) : status && !showSkipButton ? (
          <MapOldAccount
            history={history}
            isLoading={isLoading}
            handleToast={handleToast}
            setIsLoading={setIsLoading}
          />
        ) : role.permissions.superAccess ? (
          // Owner cases
          status && !skipButtonClicked ? (
            // Case 1 & 4: User sees MapOldAccount only
            <MapOldAccount
              history={history}
              isLoading={isLoading}
              handleToast={handleToast}
              setIsLoading={setIsLoading}
            />
          ) : (
            // Case 2: User presses skip button
            <Route
              {...rest}
              render={(props) => (
                <Component {...props} handleToast={handleToast} />
              )}
            />
          )
        ) : // Invited user cases
        !status || mapAccount ? (
          // Case 1: Status is false and mapAccount is true
          (localStorage.setItem(
            'skipBtnoldAccount',
            !status ? JSON.stringify(false) : JSON.stringify(true)
          ),
          (
            <Route
              {...rest}
              render={(props) => (
                <Component {...props} handleToast={handleToast} />
              )}
            />
          ))
        ) : (
          // Case 2: User sees MapOldAccount
          <MapOldAccount
            history={history}
            isLoading={isLoading}
            handleToast={handleToast}
            setIsLoading={setIsLoading}
          />
        )}
      </ErrorBoundary>
    );
  return <Route {...rest} render={(props) => <AccessDenied />} />;
};
export default withContext(AuthenticatedRoute);
