import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import { hasPermissionCustom } from '../../../Helpers/CustomPermission';
import PaymentModeForm from '../../../Components/Form/PaymentModeForm/PaymentModeForm';
import { restRequest } from '../../../Helpers/RequestHelper';
import {
  moreThanMinus,
  moreThanZero,
  required,
  // ValidateAmountNumber,
  ValidateMax255,
  ValidateMax50,
  ValidateNumber,
  ValidateRequired,
} from '../../../Helpers/Validator';
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';

import Loader from '../../Loader/Loader';
import NotFound from '../../NotFound/NotFound';
import Field from '../../Form/Field/Field';
import Form from '../../Form/Form';
import FormAction from '../FormAction';
import ErrorFocus from '../ErrorFocus';
// import {PaymentMode} from '../../../../src/Constants';

import './RecordPaymentForm.css';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import { withRouter } from 'react-router-dom';
// import DeliveryIcon from '../../../Assets/General/DeliveryMethodIcon';
import CurrencyIcon from '../../../Assets/General/CurrencyIcon';
import { checkError } from '../../../Helpers/AuthHelper';
import { confirmationAlert } from '../../../Helpers';
import { validatePaymentForm } from '../../../Helpers/FormValidator';

let flag;
class RecordPaymentForm extends Component {
  type = this.props.type; // "invoice", "bill"

  id = this.props.id || null;
  saveAndSend = false;
  state = {
    loading: true,
    paymentMethods: {},
    selectedPaymentMethod: '',
    payment: {
      amount: 0,
      date: moment(getOrganizationDate()),
      mode: '',
      reference: '',
      notes: '',
    },
    refund: {
      amount: '',
      date: moment(getOrganizationDate()),
      mode: '',
      reference: '',
      notes: '',
    },
    totalRemainingCredits: 0,
    remainingCredits: 0,
    totalPayment: 0,
    disableSaveBtn: true,
    paymentDue: 0,
    currency: {},
    notFound: false,
    minDate: null,
    showCreatePaymentMethodModal: false,
    alertModal: false,
    alertFlag: false,
  };

  myRef = React.createRef();

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.setState({ loading: true });
    this.type === 'refund'
      ? this.setRefundData()
      : this.type === 'paymentMadeRefund'
      ? this.setRefundPaymentMode()
      : this.fetchData().then(() => this.setState({ loading: false }));

    window.onbeforeunload = function () {
      return true;
    };
  }
  componentWillUnmount() {
    flag = undefined;
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.alertFlag !== this.state.alertFlag) {
      const value = { ...this.myRef?.current?.state?.values };
      delete value.paymentDue;
      const { checkout } = validatePaymentForm(
        value,
        this.myRef?.current?.initialValues
      );
      if (!checkout) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  setRefundData = async () => {
    flag = true;
    await restRequest('get', 'paymentmethods')
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            paymentMethods: res,
            selectedPaymentMethod: res[0]?.name,
            refund: {
              ...this.state.refund,
              mode: res[0]?.name,
              //date: moment(this.props.creditNoteDate), // by default today date will be set
            },
          });
        } else {
          this.setState({ paymentMethods: [] });
        }
      })
      .catch((error) => {
        checkError(error);
        this.setState({ paymentMethods: [] });
      });
    this.setState({
      refund: {
        ...this.state.refund,
        date:
          this?.props?.creditNoteDate &&
          moment(this.props.creditNoteDate) < moment(getOrganizationDate())
            ? moment(getOrganizationDate())
            : moment(this.props.creditNoteDate),
      },
      currency: this.props.currency,
      totalRemainingCredits: this.props.remainingCredits,
      remainingCredits: this.props.remainingCredits,
      minDate: moment(this.props.creditNoteDate),
      loading: false,
    });
  };

  setRefundPaymentMode = async () => {
    flag = true;
    this.setState(
      {
        paymentMethods: [],
        currency: this.props.currency,
        totalRemainingCredits: this.props.remainingCredits,
        remainingCredits: this.props.remainingCredits,
        minDate: moment(this.props.creditNoteDate),
      },
      () => {
        restRequest('get', `advancerefund/${this.props.id}/create`)
          .then((res) => {
            this.setState({
              paymentMethods: res?.payment_methods,
              selectedPaymentMethod: res?.advancePayment?.payment_mode,
              refund: {
                ...this.state.refund,
                mode: res?.advancePayment?.payment_mode,
                date:
                  res?.advancePayment?.payment_date &&
                  moment(res?.advancePayment?.payment_date) <
                    moment(getOrganizationDate())
                    ? moment(getOrganizationDate())
                    : moment(
                        moment(res?.advancePayment?.payment_date).format(
                          'YYYY-MM-DD ' + moment().format('HH:mm:ss')
                        )
                      ),
              },
              loading: false,
            });
          })
          .catch((error) => {
            checkError(error);
            this.setState({ loading: false });
          });
      }
    );
  };

  fetchData = async () => {
    this.setState({ loading: true });

    await this.fetchPayment(this.id);

    this.setState({ loading: false });
  };

  fetchRequestUrl(id) {
    switch (this.type) {
      case 'invoice':
        return `invoicepaymentrecords/${id}/create`;
      case 'bill':
      default:
        return `bills/${id}/paymentrecords/create`;
    }
  }

  fetchPayment = async (id) => {
    await restRequest('get', this.fetchRequestUrl(id))
      .then((res) => {
        let bill_creation_date = moment(getOrganizationDate());
        let invoice_creation_date = moment(getOrganizationDate());
        if (this.type === 'bill') {
          bill_creation_date =
            moment(res?.bill_date) < moment(getOrganizationDate())
              ? moment(getOrganizationDate())
              : moment(
                  moment(res?.bill_date).format(
                    'YYYY-MM-DD ' + moment().format('HH:mm:ss')
                  )
                );
        }
        if (this.type === 'invoice') {
          invoice_creation_date =
            moment(res.invoice?.invoice_date) < moment(getOrganizationDate())
              ? moment(getOrganizationDate())
              : moment(
                  moment(res.invoice?.invoice_date).format(
                    'YYYY-MM-DD ' + moment().format('HH:mm:ss')
                  )
                );
        }

        flag = res;
        this.setState({
          totalPayment:
            (this.type === 'invoice' && res?.invoice.total) ||
            (this.type === 'bill' && res.payment_details.total) ||
            0,
          paymentDue:
            (this.type === 'invoice' && res?.invoice.payment_due) ||
            (this.type === 'bill' && res.payment_details.balance_due) ||
            0,
          paymentMethods: res?.payment_methods,
          selectedPaymentMethod: res?.payment_methods[0]?.name,
          payment: {
            ...this.state.payment,
            date:
              (this.type === 'invoice' && invoice_creation_date) ||
              (this.type === 'bill' && bill_creation_date),
            amount:
              (this.type === 'invoice' && res.invoice.payment_due) ||
              (this.type === 'bill' && res.payment_details.balance_due) ||
              0,
            mode: res.payment_methods[0]?.name,
          },
          currency: res.base_currency,
          minDate:
            (this.type === 'invoice' && res.invoice?.invoice_date) ||
            (this.type === 'bill' && res.bill_date) ||
            null,
        });
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
        checkError(error);
        if (this.props.inModal) {
          this.props.onClose();
        }
      });
  };

  submitRequestUrl() {
    switch (this.type) {
      case 'invoice':
        return 'invoicepaymentrecords';
      case 'paymentMadeRefund':
        return 'advancerefund/store';
      case 'refund':
        return 'creditrefund/store';
      case 'bill':
      default:
        return `bills/${this.id}/paymentrecords`;
    }
  }

  submitRequestPayload(values) {
    switch (this.type) {
      case 'invoice':
        return {
          payment_made: values.amount,
          payment_date:
            values.date &&
            values.date.format('YYYY-MM-DD ' + moment().format('HH:mm:ss')),
          payment_mode: this.state.selectedPaymentMethod,
          reference: values.reference,
          note: values.notes,
          invoice_id: this.id,
        };
      case 'paymentMadeRefund':
        return {
          amount: values.amount,
          refund_date:
            values.date &&
            values.date.format('YYYY-MM-DD ' + moment().format('HH:mm:ss')),
          mode: this.state.selectedPaymentMethod,
          reference: values.reference,
          note: values.notes,
          advance_payment_id: this.props.id,
          type: 'invoice',
        };

      case 'refund':
        return {
          credit_note_id: this.id,
          refund_credits: values.amount,
          refund_date:
            values.date &&
            values.date.format('YYYY-MM-DD ' + moment().format('HH:mm:ss')),
          refund_mode: this.state.selectedPaymentMethod,
          reference: values.reference || '',
          note: values.notes || '',
        };
      case 'bill':
      default:
        return {
          payment_made: values.amount,
          payment_date:
            values.date &&
            values.date.format('YYYY-MM-DD ' + moment().format('HH:mm:ss')),
          payment_mode: this.state.selectedPaymentMethod,
          reference: values.reference,
          note: values.notes,
        };
    }
  }

  handleSubmit = (values, setSubmitting) => {
    if (this.state.selectedPaymentMethod === '') {
      this.props.handleToast('Please add payment mode field', 'error');
      setSubmitting(false);
    }
    restRequest(
      'post',
      this.submitRequestUrl(),
      this.submitRequestPayload(values)
    )
      .then((res) => {
        // this.props.onSubmit()
        localStorage.setItem('alert', false);
        this.type === 'refund'
          ? this.props.handleToast(
              'Credit Note Refund created successfully',
              'success'
            )
          : this.props.handleToast(
              'Payment has been recorded successfully',
              'success'
            );
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        window.location.pathname.includes('purchaseorders') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'purchaseorders',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }bills=true`,
          });
        window.location.pathname.includes('bills') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'bills',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }payments=true`,
          });
        window.location.pathname.includes('invoices') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'invoices',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam
                ? dataFromLS.queryParam.substring(0, 1) === '&'
                  ? '?' + dataFromLS.queryParam.slice(1) + '&'
                  : dataFromLS.queryParam + '&'
                : '?'
            }payments=true`,
          });
        window.location.pathname.includes('salesorders') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'salesorders',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }invoices=true`,
          });
        window.location.pathname.includes('creditnotes') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'creditnotes',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }creditRefunds=true`,
          });
        window.location.pathname.includes('paymentsmade') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'paymentsmade',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }creditRefunds=true`,
          });
        if (this.saveAndSend) {
          if (this.type === 'invoice') {
            this.props.history.push(`/invoice_payments/email/${res.data.id}`);
          } else if (this.type === 'bill') {
            this.props.history.push(`/bill_payments/email/${res.data.id}`);
          }
        } else {
          this.props.history.push('/r');
          sessionStorage.setItem('fullscreen', false);
        }
      })
      .catch((error) => {
        if (typeof setSubmitting !== undefined) {
          setSubmitting(false);
        }
        //var message = this.type === 'refund' ?'Credit Note Refund could not be created.' : 'Payments made cannot be greater than balance due.'
        this.type === 'refund'
          ? this.props.handleToast(
              'Credit Note Refund could not be created.',
              'error'
            )
          : this.props.handleToast(error, 'error');
        checkError(error);
      });
  };

  handleSaveAndSend = (event, values) => {
    event.preventDefault();
    this.saveAndSend = true;
    this.handleSubmit(values, true);
  };

  handleAmountChange = (value, setValue) => {
    setValue('amount', value.floatValue);
    this.alertCheck();
    if (value.floatValue > this.props.remainingCredits) {
      setValue('amount', this.props.remainingCredits);
      return;
    }
    this.setState({
      totalRemainingCredits: this.props.remainingCredits,
    });
    if (value.value === '') {
      setValue('amount', '');
      this.setState({
        disableSaveBtn: true,
      });
      return;
    }
    let calcCredits = (
      Number(this.props.remainingCredits) - Number(value.floatValue)
    ).toFixed(2);
    if (calcCredits < 0 || value.floatValue === 0) {
      this.setState({
        disableSaveBtn: true,
        totalRemainingCredits: calcCredits,
      });
    } else {
      this.setState({
        disableSaveBtn: false,
        totalRemainingCredits: calcCredits,
      });
    }
  };

  handlePaymentModeChange = (value, setValue) => {
    if (value) {
      this.alertCheck();
    }
    if (value.value === 'createNew') {
      this.setState({ showCreatePaymentMethodModal: true });
    } else {
      setValue('mode', value ? value.name : '');
      this.setState({ selectedPaymentMethod: value.name });
    }
  };

  handlePaymentMethodSubmit(item) {
    this.state.paymentMethods.push(item);
    this.props.handlePrompt();
    this.setState({
      ...this.state,
      paymentMethods: this.state.paymentMethods,
      selectedPaymentMethod: item.name,
      showCreatePaymentMethodModal: false,
    });
    return this.state.selectedPaymentMethod;
  }

  handleAlertmodal = (status = true) => {
    this.setState({
      alertModal: status,
    });
  };

  closeModal() {
    this.setState({
      showCreatePaymentMethodModal: false,
    });
  }

  paymentMethodCreateModal = () => {
    return (
      <CustomModal
        className="add_new_payment"
        showModal={this.state.showCreatePaymentMethodModal}
        title="Create New Payment Method"
        renderActions={false}
        Icon={CurrencyIcon}
        fill="#000000"
        modaltitle="payment_modal_title"
        size="large"
        classNames="bill-inner-popup"
        onClose={() => this.setState({ showCreatePaymentMethodModal: false })}
        showPrompt={this.state.alertModal}
      >
        <PaymentModeForm
          inModal={true}
          closeModal={() =>
            this.setState({ showCreatePaymentMethodModal: false })
          }
          //onSubmitModal={() => {this.setState({showCreatePaymentMethodModal : false}); this.fetchData()}}
          onSubmitModal={(newitem) => {
            this.handlePaymentMethodSubmit(newitem);
          }}
          type="add"
          handleToast={this.props.handleToast}
          handleAlertmodal={this.handleAlertmodal}
        />
      </CustomModal>
    );
  };

  renderModals = () => {
    return <Fragment>{this.paymentMethodCreateModal()}</Fragment>;
  };

  alertCheck = () => {
    this.setState({
      alertFlag: !this.state.alertFlag,
    });
  };

  handleCancel = () => {
    const { onClose } = this.props;
    let response = true;
    const value = { ...this.myRef?.current?.state?.values };
    delete value.paymentDue;
    const { checkout } = validatePaymentForm(
      value,
      this.myRef?.current?.initialValues
    );
    if (!checkout) {
      response = confirmationAlert();
    }
    if (checkout || response) {
      onClose();
    }
  };

  renderRecordPaymentForm() {
    let {
      payment,
      totalPayment,
      refund,
      totalRemainingCredits,
      paymentDue,
      currency,
    } = this.state;
    return (
      <Fragment>
        {this.renderModals()}
        <Formik
          ref={this.myRef}
          enableReinitialize
          initialValues={
            this.type === 'refund' || this.type === 'paymentMadeRefund'
              ? refund
              : payment
          }
          validationSchema={Yup.object().shape({
            amount:
              this.type === 'refund'
                ? required(moreThanMinus(ValidateNumber))
                : required(moreThanZero(ValidateNumber)),
            date: ValidateRequired.nullable(),
            mode: required(ValidateMax50),
            reference: ValidateMax50,
            notes: ValidateMax255,
          })}
          onSubmit={(values, { setSubmitting }) => {
            this.handleSubmit(values, setSubmitting);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            submitCount,
            isSubmitting,
          }) =>
            this.type === 'refund' || this.type === 'paymentMadeRefund' ? (
              <Form onSubmit={handleSubmit} className="record-payment-form">
                {flag ? (
                  <div>
                    <div className="form-fields-container d-flex flex-wrap">
                      <Field
                        className="flex-25"
                        name="Refund Date"
                        id="date"
                        value={values.date}
                        required
                        type="date"
                        minDate={moment(this.state.minDate)}
                        handleChange={(date) => {
                          setFieldValue('date', date);
                          this.alertCheck();
                        }}
                        onBlur={() => setFieldTouched('date')}
                        error={touched.date && errors.date}
                      />

                      <Field
                        className="flex-25"
                        type="number"
                        name="Amount"
                        id="amount"
                        placeholder="$0"
                        value={values.amount}
                        prefix={currency.symbol}
                        required
                        onValueChange={(value) => {
                          this.handleAmountChange(value, setFieldValue);
                        }}
                        onBlur={handleBlur}
                        autoFocus={true}
                        //error={(touched.amount && values.amount === 0) && 'Can not be Zero'}
                        error={
                          (touched.amount &&
                            values.amount === 0 &&
                            'Can not be Zero.') ||
                          (values.amount > this.state.remainingCredits &&
                            'Amount shouldn’t be greater than the Available Credit')
                        }
                      />
                      <Field
                        className="flex-25"
                        name="Payment Mode"
                        id="mode"
                        value={this.state.selectedPaymentMethod}
                        required
                        options={this.state.paymentMethods}
                        dropdownValue="name"
                        dropdownLabel="name"
                        clearable={false}
                        handleChange={(option) =>
                          this.handlePaymentModeChange(option, setFieldValue)
                        }
                        error={touched.mode && errors.mode}
                        type="dropdown"
                        addButton={
                          hasPermissionCustom('paymentmode', 'Create')
                            ? true
                            : false
                        }
                      />

                      <Field
                        className="flex-25"
                        name="Reference"
                        id="reference"
                        value={values.reference}
                        handleChange={(e) => {
                          handleChange(e);
                          this.alertCheck();
                        }}
                        onBlur={handleBlur}
                        error={touched.reference && errors.reference}
                      />

                      <Field
                        className="flex-50 payment-record"
                        size="large"
                        name="Description"
                        id="notes"
                        value={values.notes}
                        handleChange={(e) => {
                          handleChange(e);
                          this.alertCheck();
                        }}
                        type="textarea"
                        onBlur={handleBlur}
                        error={touched.notes && errors.notes}
                      />
                    </div>

                    <div className="float-left w-100 remaining_Credits">
                      <div>
                        Remaining Credits{' '}
                        {totalRemainingCredits < 0 ? (
                          <span className="red_color">
                            {currency.symbol}
                            {totalRemainingCredits}
                          </span>
                        ) : (
                          <span>
                            {currency.symbol}
                            {totalRemainingCredits}
                          </span>
                        )}
                      </div>
                    </div>
                    <FormAction
                      hasHr={true}
                      marginFromRight={4}
                      disabled={isSubmitting || this.state.disableSaveBtn}
                      onCancel={this.handleCancel}
                      showSaveAndSendButton={false}
                      saveAndSendField={{
                        label: 'Save & Send',
                        disabled: { isSubmitting },
                        onClick: (event) => {
                          event.preventDefault();
                          this.saveAndSend = true;
                          handleSubmit();
                        },
                      }}
                    />

                    <ErrorFocus />
                  </div>
                ) : (
                  <p>Sorry , no action can be done</p>
                )}
              </Form>
            ) : (
              <Form
                onSubmit={handleSubmit}
                className="record-payment-forms payment-form-set"
              >
                {flag ? (
                  <div>
                    <div className="form-fields-container d-flex flex-wrap">
                      <Field
                        type="number"
                        name="Amount"
                        className="flex-33"
                        id="totalPayment"
                        value={totalPayment}
                        prefix={currency.symbol}
                        disabled
                      />

                      <Field
                        className="flex-33"
                        type="number"
                        name="Payment Due"
                        id="paymentDue"
                        value={paymentDue}
                        prefix={currency.symbol}
                        disabled
                      />

                      <Field
                        className="flex-33"
                        type="number"
                        name={
                          (this.type === 'invoice' && 'Payment Received') ||
                          (this.type === 'bill' && 'Payment Made')
                        }
                        id="amount"
                        value={values.amount}
                        prefix={currency.symbol}
                        required
                        onValueChange={(value) => {
                          setFieldValue('amount', value.floatValue);
                          setFieldValue('paymentDue', paymentDue);
                          this.alertCheck();
                        }}
                        onBlur={handleBlur}
                        // autoFocus={true}
                        error={
                          (touched.amount || submitCount > 0) && errors.amount
                        }
                      />

                      <Field
                        className="flex-33"
                        name="Payment Date"
                        id="date"
                        // value={values.date}
                        value={
                          typeof values.date == 'string'
                            ? moment(values.date)
                            : values.date
                        }
                        required
                        type="date"
                        // minDate={this.state.minDate}
                        minDate={
                          typeof this.state.minDate == 'string'
                            ? moment(this.state.minDate)
                            : this.state.minDate
                        }
                        handleChange={(date) => {
                          if (!date) return;
                          setFieldValue('date', date);
                          this.alertCheck();
                        }}
                        onBlur={() => setFieldTouched('date')}
                        error={touched.date && errors.date}
                      />

                      <Field
                        className="flex-33"
                        name="Payment Mode"
                        id="mode"
                        value={this.state.selectedPaymentMethod}
                        required
                        options={this.state.paymentMethods}
                        dropdownValue="name"
                        dropdownLabel="name"
                        clearable={false}
                        handleChange={(option) =>
                          this.handlePaymentModeChange(option, setFieldValue)
                        }
                        error={touched.mode && errors.mode}
                        type="dropdown"
                        addButton={
                          hasPermissionCustom('paymentmode', 'Create')
                            ? true
                            : false
                        }
                      />

                      <Field
                        className="flex-33"
                        name="Reference No."
                        id="reference"
                        value={values.reference}
                        handleChange={(e) => {
                          handleChange(e);
                          this.alertCheck();
                        }}
                        onBlur={handleBlur}
                        error={touched.reference && errors.reference}
                      />

                      <Field
                        className="flex-66"
                        name="Notes"
                        id="notes"
                        value={values.notes}
                        handleChange={(e) => {
                          handleChange(e);
                          this.alertCheck();
                        }}
                        type="textarea"
                        onBlur={handleBlur}
                        error={touched.notes && errors.notes}
                      />
                    </div>
                    <FormAction
                      hasHr={true}
                      // marginFromRight={4}
                      disabled={isSubmitting}
                      onCancel={this.handleCancel}
                      showSaveAndSendButton={true}
                      saveAndSendField={{
                        label: 'Save & Send',
                        disabled: { isSubmitting },
                        onClick: (event) => {
                          event.preventDefault();
                          this.saveAndSend = true;
                          handleSubmit();
                        },
                      }}
                    />

                    <ErrorFocus />
                  </div>
                ) : (
                  <p>Sorry , no action can be done</p>
                )}
              </Form>
            )
          }
        />
      </Fragment>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    return this.renderRecordPaymentForm();
  }
}

export default withRouter(RecordPaymentForm);
