import React from 'react';
import AuthContainer from '../AuthContainer';

const DeactivateUser = (props) => {
  localStorage.setItem("redirect", true);
  const subtitle = [`Our database is down right now.`,<br />,`We will be back soon.`];
  return (
    <AuthContainer title='Account Not Found' subtitle={subtitle} linkdisable >
      <button
        id="click_login_button"
        className="button--full-width"
        type="submit"
        onClick={() => props.history.push('/')}
      >
        Back
      </button>
    </AuthContainer>
  );
};

export default DeactivateUser;