import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { restRequest } from '../../Helpers/RequestHelper';
import { SearchIcon } from '../../Assets/Mixed/SearchIcons';

import Dropdown from '../Dropdown/CustomDropdown';
import { routesPath } from '../../Pages/Navigation/NavigationUtils';
import OverlayLoader from '../Loader/OverlayLoader';

import './SearchBar.css';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: 'contacts',
      selectedFilterLabel: 'Contacts',
      onRealTimeSearch: false,
      isFocused: false,
      isFetching: false,
      contacts: [],
      showFilterStyles: null,
      searchFromIconValue: null,
      searchKeySelected: false,
      search:
        this.props.location.search.length > 0
          ? queryString.parse(this.props.location.search).search
          : '',
    };
  }
  //Mobile search expandable
  SearchbarHandler = () => {};
  filterOptions = [
    {
      id: 'contacts',
      label: 'Contacts',
      path: routesPath.contacts,
      onClick: () =>
        this.setState({
          selectedFilter: 'contacts',
          selectedFilterLabel: 'Contacts',
        }),
    },
    {
      id: 'items',
      label: 'Items',
      path: routesPath.items,
      onClick: () =>
        this.setState({
          selectedFilter: 'items',
          selectedFilterLabel: 'Items',
        }),
    },
    {
      id: 'item-groups',
      label: 'Item Groups',
      path: routesPath.itemGroups,
      onClick: () =>
        this.setState({
          selectedFilter: 'item-groups',
          selectedFilterLabel: 'Item Groups',
        }),
    },
    {
      id: 'item-adjustment',
      label: 'Item Adjustments',
      path: routesPath.itemAdjustment,
      onClick: () =>
        this.setState({
          selectedFilter: 'item-adjustment',
          selectedFilterLabel: 'Item Adjustments',
        }),
    },
    {
      id: 'salesorders',
      label: 'Sales Orders',
      path: routesPath.salesOrders,
      onClick: () =>
        this.setState({
          selectedFilter: 'salesorders',
          selectedFilterLabel: 'Sales Orders',
        }),
    },
    {
      id: 'packages',
      label: 'Packages',
      path: routesPath.packages,
      onClick: () =>
        this.setState({
          selectedFilter: 'packages',
          selectedFilterLabel: 'Packages',
        }),
    },
    {
      id: 'invoices',
      label: 'Invoices',
      path: routesPath.invoices,
      onClick: () =>
        this.setState({
          selectedFilter: 'invoices',
          selectedFilterLabel: 'Invoices',
        }),
    },
    {
      id: 'salesreturns',
      label: 'Sales Return',
      path: routesPath.salesreturns,
      onClick: () =>
        this.setState({
          selectedFilter: 'salesreturns',
          selectedFilterLabel: 'Sales Return',
        }),
    },
    {
      id: 'creditnotes',
      label: 'Credit Notes',
      path: routesPath.creditnotes,
      onClick: () =>
        this.setState({
          selectedFilter: 'creditnotes',
          selectedFilterLabel: 'Credit Notes',
        }),
    },
    {
      id: 'paymentsmade',
      label: 'Payments Received',
      path: routesPath.customerPayment,
      onClick: () =>
        this.setState({
          selectedFilter: 'paymentsmade',
          selectedFilterLabel: 'Payments Received',
        }),
    },
    {
      id: 'purchaseorders',
      label: 'Purchase Orders',
      path: routesPath.purchaseOrders,
      onClick: () =>
        this.setState({
          selectedFilter: 'purchaseorders',
          selectedFilterLabel: 'Purchase Orders',
        }),
    },
    {
      id: 'bills',
      label: 'Bills',
      path: routesPath.bills,
      onClick: () =>
        this.setState({
          selectedFilter: 'bills',
          selectedFilterLabel: 'Bills',
        }),
    },
  ];

  componentDidMount() {
    if (this.state.search && this.state.search.length > 0) {
      this.setState({ searchKeySelected: true });
    } else {
      this.setState({ searchKeySelected: false });
    }

    this.checkPaths();
    document.addEventListener('mousedown', this.handleResultOutsideClick);
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.search &&
      this.state.search.length > 0 &&
      this.state.searchKeySelected === true
    ) {
      this.input.focus();
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ searchKeySelected: false });
      this.input.blur();
      this.checkPaths();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleResultOutsideClick);
  }

  checkPaths() {
    const filterIds = this.filterOptions;

    if (
      this.props.location.pathname.includes(this.state.selectedFilter) === false
    ) {
      this.input.value = '';
    }
    let validFilterPath = false;
    for (let filter of filterIds) {
      if (
        this.props.location.pathname.includes(filter.id) &&
        this.props.location.pathname !== '/packagesplan'
      ) {
        this.setState({ selectedFilter: filter.id });
        this.setState({ selectedFilterLabel: filter.label });
        validFilterPath = true;
      }
    }

    if (!validFilterPath) {
      this.setState({ selectedFilter: 'contacts' });
      this.setState({ selectedFilterLabel: 'Contacts' });
    }
  }

  inputOnFocus = (event) => {
    this.setState({ isFocused: true });
    this.handleRealTimeSearch(event.target.value);
  };

  inputOnBlur = () => {
    // this.setState({ isFocused: false, contacts: [], onRealTimeSearch: false })
    this.setState({ isFocused: false });
  };

  inputOnChange = (event) => {
    this.handleRealTimeSearch(event.target.value);
    this.setState({ searchFromIconValue: event.target.value });
  };

  handleRealTimeSearch(value) {
    if (this.state.selectedFilter !== 'contacts') return;

    if (value === '') {
      this.setState({ contacts: [], onRealTimeSearch: false });
    } else {
      this.setState({ onRealTimeSearch: true, isFetching: true });
      this.handleInputChange(value);
    }
  }

  handleInputChange = debounce(async (value) => {
    let val = value.replace('&', '%26');

    const res = await restRequest('get', `contacts?search=${val}&view=5`);
    if (this.state.onRealTimeSearch) {
      this.setState({
        contacts: res.contacts.data,
        isFetching: false,
      });
    }
  }, 500);

  handleSearchSubmit = (event, fromIcon = false) => {
    if (this.input.value === '') {
      event.preventDefault();
      this.input.focus();
      return;
    } else {
      event.preventDefault();
      const searchKey = fromIcon
        ? this.state.searchFromIconValue
        : this.input.value;
      if (!searchKey) return;
      const searchPath = this.filterOptions.find(
        (filter) => filter.id === this.state.selectedFilter
      ).path;
      this.input.value = '';
      this.input.blur();

      this.setState({ contacts: [], onRealTimeSearch: false });
      if (searchKey) {
        this.setState({ search: searchKey });
        this.setState({ searchKeySelected: true });
      } else {
        this.setState({ search: '' });
        this.setState({ searchKeySelected: false });
      }
      const pos = searchKey.indexOf('&');
      var newSearchKey = searchKey;
      if (pos !== -1) {
        newSearchKey = searchKey.replace(/&/g, '%26');
      }
      newSearchKey = newSearchKey.replace('&', '%26');
      this.props.history.push(`${searchPath}?search=${newSearchKey}`);
    }
  };

  handleResultOutsideClick = (event) => {
    this.setState({ search: '' });
    if (this.searchResult && !this.searchResult.contains(event.target)) {
      this.setState({ contacts: [], onRealTimeSearch: false });
    }
  };
  showFilter(val) {
    this.setState({
      showFilterStyles: val,
    });
  }
  renderFilter() {
    //console.log('this.state', this.state.selectedFilter)
    //console.log('this.state', this.state.selectedFilterLabel)
    return (
      <span
        className={`search-bar--dropdown search_header_filter ${
          this.state.selectedFilterLabel === 'Purchase Orders' ||
          this.state.selectedFilterLabel === 'Item Adjustments' ||
          this.state.selectedFilterLabel === 'Payments Received'
            ? 'added_search'
            : ''
        }`}
      >
        <Dropdown
          selectedFilter={this.state.selectedFilter}
          textLabel={this.state.selectedFilterLabel}
          showFilterFlag={true}
          showFilter={this.showFilter.bind(this)}
          options={this.filterOptions}
        />
      </span>
    );
  }

  handleResultClick(id) {
    this.setState({ contacts: [], onRealTimeSearch: false });
    this.props.history.push(`/contacts/${id}`);
  }

  renderSearchResult() {
    const { isFetching, contacts } = this.state;
    return (
      <div
        className="search-holder--search-result"
        ref={(element) => {
          this.searchResult = element;
        }}
      >
        {isFetching && <OverlayLoader />}
        {contacts.map((contact) => (
          <li
            key={contact.id}
            onClick={() => this.handleResultClick(contact.id)}
          >
            <div>{contact.display_name}</div>
            <div className="grey font-1_2rem">{contact.company_name}</div>
            <div className="grey font-1_2rem">{contact.email}</div>
          </li>
        ))}
        {!contacts.length && !isFetching && (
          <li className="no-result"> No Result Found </li>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="search-holder mob-searchbar">
        <div
          className={`search-bar ${
            this.state.showFilterStyles ? 'search-head' : ''
          } ${this.state.isFocused ? 'focus' : ''}`}
        >
          {this.renderFilter()}
          <form onSubmit={this.handleSearchSubmit}>
            <input
              ref={(element) => {
                this.input = element;
              }}
              onFocus={this.inputOnFocus}
              onBlur={this.inputOnBlur}
              onChange={this.inputOnChange}
              className="search-bar--input"
              type="text"
              placeholder="Search"
              maxLength="100"
              id="realTimeSearch"
            />
            <div
              title="Search"
              className="pointer"
              onClick={(e) => this.handleSearchSubmit(e, true)}
            >
              <SearchIcon onClick={this.SearchbarHandler()} />
            </div>
          </form>
        </div>
        {this.state.onRealTimeSearch && this.renderSearchResult()}
      </div>
    );
  }
}

export default withRouter(SearchBar);
