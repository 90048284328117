import React, { Component, Fragment } from 'react';
import queryString from 'query-string';

import WarehouseIcon from '../../Assets/General/WarehouseIcon';
import PrimaryWarehouseIcon from '../../Assets/General/PrimaryWarehouseIcon';
import { restRequest } from '../../Helpers/RequestHelper';

import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import ListTable from '../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  ButtonLink,
} from '../../Components/CheckedMenu/CheckedMenu';
import CustomModal from '../../Components/CustomModal/CustomModal';

import WarehouseForm from '../../Components/Form/WarehouseForm/WarehouseForm';
import EditIcon from '../../Assets/General/EditIcon';
import DeleteIcon from '../../Assets/General/DeleteIcon';

import './Warehouse.css';
import { setValueInLS } from '../../Helpers/LocalStorage';
import { checkError } from '../../Helpers/AuthHelper';

class Warehouse extends Component {
  state = {
    loading: true,
    warehouseList: [],
    data: [],
    totalPage: {},
    activePage: 1,
    sortFilter: 'created_at',
    orderBy: 'desc',
    showCreateWarehouseModal:
      window.location.search.slice(1).split('=')[0] === 'new',
    showEditWarehouseModal: false,
    warehouseID: null,
    totalRecords: 0,
    showPrompt: false,
  };

  sortOptions = [
    {
      id: 'name',
      label: 'Name',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('name') }),
    },
    {
      id: 'email',
      label: 'Email',
      type: 'link',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('email') }),
    },
    {
      id: 'created_at',
      label: 'Created Time',
      type: 'link',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
  ];

  componentDidMount() {
    setValueInLS('fullscreen', true);
    document.title = 'Warehouses';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const { sort, orderBy } = this.parseQueryString();
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { sort } = this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    let sort = '';
    switch (query.sort) {
      case 'name':
      case 'email':
      case 'created_at':
        sort = query.sort;
        break;
      default:
        sort = 'name';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'asc';
    }

    let page = '';
    let view = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
    };
  }

  fetchData = () => {
    this.setState({ loading: true });

    restRequest(
      'get',
      `warehouses?page=${this.state.activePage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}`
    )
      .then((res) => {
        this.setState({
          warehouseList: res,
          data: this.prepareDataForTable(res),
          totalPage: res.last_page,
          totalRecords: res && res.length ? res.length : 0,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
      });
  };

  renderPrimaryWarehouseLabel(name, isPrimaryWarehouse) {
    return (
      <div className="current-warehouse">
        <span style={{ float: 'left', width: '86%' }}>{name}</span>
        {isPrimaryWarehouse && (
          <span
            className="current-warehouse--current"
            style={{ float: 'right', marginLeft: '0px' }}
          >
            <PrimaryWarehouseIcon />
          </span>
        )}
      </div>
    );
  }

  prepareDataForTable(warehouseList) {
    return warehouseList.map((warehouse) => {
      return {
        id: warehouse.id,
        'Warehouse Name': {
          id: 'name',
          sortable: true,
          getValue: () =>
            this.renderPrimaryWarehouseLabel(
              warehouse.name,
              warehouse.is_primary
            ),
        },
        Email: {
          id: 'email',
          sortable: true,
          getValue: function () {
            return warehouse[this.id];
          },
        },
        Address: {
          id: 'address',
          getValue: function () {
            return warehouse[this.id];
          },
        },
        City: {
          id: 'city',
          getValue: function () {
            return warehouse[this.id];
          },
        },
        State: {
          id: 'state',
          getValue: function () {
            return warehouse[this.id] && warehouse[this.id];
          },
        },
        Phone: {
          id: 'phone',
          getValue: function () {
            return warehouse[this.id];
          },
        },
        additional: {
          isActive: warehouse.is_active,
        },
      };
    });
  }

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const checkedId = this.state.bulkSelectedData[0];
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'warehouse',
      'All'
    );
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={hasEditPermission}
          to={`/warehouses/edit/${checkedId}`}
          type="edit"
        />
        <Button
          enable={hasDeletePermission}
          handleClick={() => this.openDeleteModal(checkedId)}
          type="delete"
        />
      </CheckedMenu>
    );
  };

  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const hasDeletePermission = this.hasPermission('warehouse', 'Delete');
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={this.openBulkDeleteModal}
          type="delete"
        />
      </CheckedMenu>
    );
  };
  markAsPrimary = (id) => {
    restRequest('put', `warehouses/${id}/markasprimary`)
      .then(() => {
        let name = '';
        let updatedList = this.state.warehouseList.map((warehouse) => {
          if (warehouse.is_primary) {
            return {
              ...warehouse,
              is_primary: false,
            };
          }
          if (warehouse.id === id) {
            name = warehouse.name;
            return {
              ...warehouse,
              is_primary: true,
            };
          }
          return { ...warehouse };
        });
        this.setState({
          warehouseList: updatedList,
          data: this.prepareDataForTable(updatedList),
        });

        this.props.handleToast(
          `Warehouse: "${name}" marked as primary`,
          'success'
        );
      })
      .catch((error) => {
        //console.log('error.response',error.response)
        if (error.response.status === 403) {
          checkError(error, this.props.handleToast);
          //this.props.handleToast(error.response.data.message, 'error')
        } else {
          this.props.handleToast(
            'Warehouse is already marked as primary',
            'error'
          );
        }
      });
  };

  checkPrimary = (id) => {
    return this.state.warehouseList.some(
      (warehouse) => warehouse.id === id && warehouse.is_primary
    );
  };
  isInActive = (id) => {
    return (
      this.state.warehouseList.find((warehouse) => warehouse.id === id)
        .is_active === 0
    );
  };

  hasEditPermission() {
    const hasEditPermission = this.hasPermission('warehouse', 'Edit');
    return hasEditPermission;
  }
  hasDeltePermission() {
    const hasDeltePermission = this.hasPermission('warehouse', 'Delete');
    return hasDeltePermission;
  }

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Edit',
        // onClick: id => this.props.history.push(`warehouses/edit/${id}`),
        onClick: (id) => this.showEditModal(id),
        disabled: (id) => this.isInActive(id) || !this.hasEditPermission(),
        icon: EditIcon,
      },
      {
        label: 'Delete',
        onClick: this.openDeleteModal,
        disabled: (id) => this.isInActive(id) || !this.hasDeltePermission(),
        icon: DeleteIcon,
      },
      {
        label: 'Mark as primary',
        onClick: (id) => this.markAsPrimary(id),
        icon: PrimaryWarehouseIcon,
        disabled: (id) => this.checkPrimary(id) || this.isInActive(id),
      },
    ];

    return rowOptions;
  }

  showCreateModal() {
    return this.setState({ showCreateWarehouseModal: true });
  }
  showEditModal(id) {
    return this.setState({ showEditWarehouseModal: true, warehouseID: id });
  }

  closeAndBack(totalRecords) {
    this.setState({ showCreateWarehouseModal: false });
    this.setState({ showEditWarehouseModal: false });
    if (totalRecords === undefined || totalRecords === 0) {
      //history.push('/settings')
      window.location.href = '/settings';
    }
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  renderCreateModal() {
    return (
      <CustomModal
        className="warehouse_add_new"
        showModal={this.state.showCreateWarehouseModal}
        title="Create New Warehouse"
        Icon={WarehouseIcon}
        renderActions={false}
        size="large"
        //onClose={() => this.setState({showCreateWarehouseModal : false})}
        onClose={() => this.closeAndBack(this.state.totalRecords)}
        showPrompt={this.state.showPrompt}
      >
        <WarehouseForm
          type="add"
          handleToast={this.props.handleToast}
          inModal={true}
          closeModal={() => this.setState({ showCreateWarehouseModal: false })}
          onSubmitModal={() => {
            this.setState({ showCreateWarehouseModal: false });
            this.fetchData();
          }}
          totalRecords={this.state.totalRecords}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  renderEditModal() {
    return (
      <CustomModal
        showModal={this.state.showEditWarehouseModal}
        className="warehouse_add_new"
        title="Edit Warehouse"
        renderActions={false}
        Icon={WarehouseIcon}
        size="large"
        //onClose={() => this.setState({showEditWarehouseModal : false})}
        onClose={() => this.closeAndBack(this.state.totalRecords)}
        showPrompt={this.state.showPrompt}
      >
        <WarehouseForm
          type="edit"
          handleToast={this.props.handleToast}
          inModal={true}
          id={this.state.warehouseID}
          closeModal={() => this.setState({ showEditWarehouseModal: false })}
          onSubmitModal={() => {
            this.setState({ showEditWarehouseModal: false });
            this.fetchData();
          }}
          totalRecords={this.state.totalRecords}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  renderTable() {
    return (
      <Fragment>
        {this.renderCreateModal()}
        {this.renderEditModal()}
        <ListTable
          className="system_settings warehouse sytem_setting_main"
          data={this.state.data}
          selectedData={this.state.bulkSelectedData}
          rowOptions={this.prepareRowOptions()}
          generateRoute={this.generateRoute}
          orderBy={this.state.orderBy}
          sortFilter={this.state.sortFilter}
          hasCheckbox={false}
          isBlur="isActive"
          fromWareHouse={true}
          loading={this.state.loading}
          handleCheckAll={this.handleCheckAll}
          handleCheckSingle={this.handleCheckSingle}
          totalRecords={this.state.totalRecords}
        />
      </Fragment>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(Warehouse, {
  name: 'Warehouses',
  deleteName: 'Warehouse',
  Icon: WarehouseIcon,
  baseUrl: 'warehouses',
  redirectUrl: '/warehouses',
  buttonUrl: '/warehouses/add',
  buttonLabel: 'New Warehouse',
  routeName: 'warehouses',
  pagination: false,
  permissionName: 'warehouse',
  createModal: true,
});
