import {
  FaArrowUp,
  FaArrowDown,
  FaCamera,
  FaFile,
  FaFilePdf,
  FaSkype,
  FaTrash,
  FaBuilding,
  FaSlidersH,
  FaDollarSign,
  FaColumns,
  FaShopify,
  // FaRegFilePdf,
} from 'react-icons/fa';
import { AiOutlineSetting, AiOutlineAlert } from 'react-icons/ai';

import {
  MdCheck,
  MdClose,
  MdInfoOutline,
  MdCached,
  MdFullscreen,
  MdAdd,
  MdEdit,
  MdPrint,
  MdLocalPhone,
  MdPhoneAndroid,
  MdEmail,
  MdSettings,
  MdRemove,
  MdOutlineHelp,
  MdHelpOutline,
  MdKeyboardBackspace,
  MdLocationOn,
  MdArrowForward,
  MdArrowBack,
  MdInsertChart,
  MdInfo,
  MdExpandMore,
  MdOpenInNew,
  MdWork,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdDashboard,
  MdShoppingCart,
  MdContentPaste,
  MdKeyboard,
  MdMoveToInbox,
  MdWeb,
  MdKeyboardArrowDown,
  MdAddToQueue,
  MdLocalShipping,
  MdReceipt,
  MdWarning,
  MdStore,
  MdSearch,
  MdOutlineMoreVert,
  MdFormatAlignJustify,
  MdOutlineSyncProblem,
  MdDragIndicator,
} from 'react-icons/md';
import { GoPlus } from 'react-icons/go';
import { IoMdCheckmarkCircle, IoMdNotificationsOutline } from 'react-icons/io';
import { TiUser } from 'react-icons/ti';
import { ImInfo, ImFilePdf as FaRegFilePdf } from 'react-icons/im';
import { IoSettingsOutline } from 'react-icons/io5';
import { SiWordpress, SiShopify, SiAmazon } from 'react-icons/si';
import { BsExclamationTriangleFill } from 'react-icons/bs';

export {
  FaArrowUp,
  FaArrowDown,
  FaCamera,
  FaFile,
  FaFilePdf,
  FaRegFilePdf,
  FaSkype,
  FaTrash,
  FaBuilding,
  FaSlidersH,
  FaColumns,
  FaDollarSign,
  MdCheck,
  MdClose,
  MdInfoOutline,
  MdCached,
  MdFullscreen,
  MdAdd,
  MdEdit,
  MdRemove,
  MdSettings,
  MdOutlineHelp,
  MdHelpOutline,
  MdPrint,
  MdLocationOn,
  MdLocalPhone,
  MdPhoneAndroid,
  MdEmail,
  MdArrowForward,
  MdArrowBack,
  MdInsertChart,
  MdKeyboardBackspace,
  MdInfo,
  MdExpandMore,
  MdOpenInNew,
  MdWork,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdDashboard,
  MdShoppingCart,
  MdContentPaste,
  MdOutlineMoreVert,
  MdKeyboard,
  MdMoveToInbox,
  MdWeb,
  MdKeyboardArrowDown,
  MdAddToQueue,
  MdLocalShipping,
  MdReceipt,
  MdWarning,
  MdStore,
  MdSearch,
  MdOutlineSyncProblem,
  GoPlus,
  IoMdCheckmarkCircle,
  TiUser,
  ImInfo,
  MdFormatAlignJustify,
  SiWordpress,
  SiShopify,
  SiAmazon,
  MdDragIndicator,
  AiOutlineSetting,
  IoSettingsOutline,
  FaShopify,
  AiOutlineAlert,
  BsExclamationTriangleFill,
  IoMdNotificationsOutline,
};
