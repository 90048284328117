import React, { Component, Fragment, useState } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import {getOrganization, checkError, setDeploymentTime} from '../../Helpers/AuthHelper';
import { Header } from '../../Components/Layout/Layout';
import Loader from '../../Components/Loader/Loader';
import { setObjInLS } from '../../Helpers/LocalStorage';
import { NewDashboardIcon } from '../../Assets/Navigation/NavigationIcons';
import './DashboardSummary.css';
import DashboardBox from './DashboardBox/DashboardBox';
import DashboardFooter from './DashboardFooter/DashboardFooter';
import DashboardSalesInfo from './DashboardSalesInfo/DashboardSalesInfo';
import DashboardPackageAndShipment from './DashboardPackageAndShipment/DashboardPackageAndShipment';
import DashboardPurchaseInfo from './DashboardPurchaseInfo/DashboardPurchaseInfo';
import DashboardReceivables from './DashboardReceivables/DashboardReceivables';
import DashboardPayables from './DashboardPayables/DashboardPayables';
import DashboardTopSellingItems from './DashboardTopSellingItems/DashboardTopSellingItems';
import DashboardSalesVsPurchase from './DashboardSalesVsPurchase/DashboardSalesVsPurchase';
import DashboardSOSummary from './DashboardSOSummary/DashboardSOSummary';
import DashboardTopCustomers from './DashboardTopCustomers/DashboardTopCustomers';
import DashboardOrderDetails from './DashboardOrderDetails/DashboardOrderDetails';
import CustomModal from '../../Components/CustomModal/CustomModal';
import DashboardUpdates from './DashboardUpdates/DashboardUpdates';
import InfoIcon from '../../Assets/Dashboard/infoIcon';
import InitialDashboardScreen from './InitialDashboardScreen/InitialDashboardScreen';
import SidePanel from '../../Components/SidePanel/SidePanel';

const Onboarding = () => {
  return <InitialDashboardScreen />;
};

const Dashboard = () => {
  const [receivable, setReceivable] = useState('');
  const [payable, setPayable] = useState('');

  return (
    <Fragment>
      <div className="dashboard_box_width dashboard_mobile-box">
        <DashboardBox
          className="box_1"
          title="Sales Information"
          render={(dr, startData, endDate) => (
            <DashboardSalesInfo
              currentDateRange={dr}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <div className="dashboard_box_width dashboard_mobile-box">
        <DashboardBox
          className="box_2"
          title="Package & Shipment"
          render={(dr, startData, endDate) => (
            <DashboardPackageAndShipment
              currentDateRange={dr}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <div className="dashboard_box_width dashboard_mobile-box">
        <DashboardBox
          className="box_3"
          title="Purchase Information"
          render={(dr, startData, endDate) => (
            <DashboardPurchaseInfo
              currentDateRange={dr}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <div className="dashboard_box_width dashboard_mobile-box">
        <DashboardBox
          className="box_4 box-cus-main"
          title="Receivables"
          subTitile={receivable}
          render={(dr, startData, endDate) => (
            <DashboardReceivables
              setReceivable={setReceivable}
              currentDateRange={dr}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <div className="dashboard_box_width dashboard_mobile-box">
        <DashboardBox
          className="box_5 box-cus-main"
          title="Payable"
          subTitile={payable}
          render={(dr, startData, endDate) => (
            <DashboardPayables
              setPayable={setPayable}
              currentDateRange={dr}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <div className="dashboard_box_width dashboard_mobile-box">
        <DashboardBox
          className="box_6"
          title="Top Selling Items"
          filter
          render={(dr, filter, endDate, _, startData) => (
            <DashboardTopSellingItems
              currentDateRange={dr}
              filter={filter}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <div className="dashboard_box_width_two dashboard_box_cus-set">
        <DashboardBox
          className="double_dash_box box_7"
          title="Order Details"
          hasDateRange={false}
          render={() => <DashboardOrderDetails />}
        />
      </div>
      <div className="dashboard_box_width dashboard-mob-inner ">
        <DashboardBox
          className="box_8 box_8-main box_8-main-set"
          title="Top Customers"
          render={(dr, startData, endDate) => (
            <DashboardTopCustomers
              currentDateRange={dr}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
        <DashboardBox
          className="box_9"
          title="Sales vs Purchase"
          ToolTip="This card shows the comparative analysis of Invoices of Sales and Bills of Purchases in the confirmed state."
          salevspurchaseFlag
          render={(dr, startData, endDate) => (
            <DashboardSalesVsPurchase
              currentDateRange={dr}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <div className="dashboard_box_width_full dashboard_box_cus-set">
        <DashboardBox
          className="triple_dash_box box_10"
          title="Sales Order Summary -"
          subTitile="Confirmed Orders"
          salesOrderSummaryFlag
          render={(_, startData, endDate, filter) => (
            <DashboardSOSummary
              currentDateRange={filter}
              startData={startData}
              endDate={endDate}
            />
          )}
        />
      </div>
      <DashboardFooter />
    </Fragment>
  );
};

class DashboardSummary extends Component {
  state = {
    loading: true,
    showUpdateModal: false,
    showOnboarding: true,
    openSidePanel: false
  };

  componentDidMount() {
    const check = localStorage.getItem("organizationSideBar")
    if (!check) {
      this.fetchDeployment();
    restRequest('get', `organizations/${getOrganization()}`)
      .then((res) => {
        localStorage.setItem(
          'dashboardPreferences',
          JSON.stringify(res.dashboard_preferences)
        );
        if (res.has_contacts || res.has_items) {
          this.setState({
            showOnboarding: false,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        checkError(error);
      });
    let obj = {
      id: 0,
      moduleName: 'invoices',
      nextId: 0,
      prevId: null,
      queryParam: '',
      update: true,
      urlPath: '/invoices',
    };
    setObjInLS('module', obj);
    }
    else {
      this.setState({
        openSidePanel: true,
        loading: false
      });
    }

  }

  async fetchDeployment() {
    await restRequest('get', `deploymentTime`)
      .then((res) => {
        setDeploymentTime(res);
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  renderDashboard() {
    if (this.state.loading) return <Loader />;
    if (this.state.showOnboarding) return <Onboarding />;
    return <Dashboard />;
  }
  ViewUpdateModal() {
    this.setState({ showUpdateModal: true });
  }

  closeAllModals = () => {
    this.setState({
      showUpdateModal: false,
    });
  };

  componentWillUnmount() {
    localStorage.removeItem('dashboardPreferences');
  }
  renderViewUpdateModal() {
    return (
      <CustomModal
        showModal={this.state.showUpdateModal}
        Icon={InfoIcon}
        title={'Recent Updates'}
        onClose={this.closeAllModals}
        renderActions={false}
        className="dash_viewpdates recent-updated-popup-set"
      >
        <DashboardUpdates
          inModal
          type="add"
          salesOrderId={this.id}
          close={this.closeAllModals}
          onSubmit={this.handlePackageActionSubmit}
        />
      </CustomModal>
    );
  }

  renderSidePanel() {
    return (
      <SidePanel
        showSidePanel={this.state.openSidePanel}
        // closeMe={closeMe.bind(this)}
        // handleLogout={this.handleLogout}
        handleToast={this.props.handleToast}
        history={this.props.history}
      // ssoConnected={ssoConnected}
      />
    )
  }

  render() {
    document.title = 'Dashboard';
    let { showOnboarding } = this.state;
    return (
      <Fragment>
        {this.renderViewUpdateModal()}
        <Header
          title={showOnboarding ? 'Welcome to SeeBiz Inventory!' : 'Dashboard'}
          Icon={NewDashboardIcon}
          className="dashboard-header"
          fromDashboard={true}
          showModal={() => this.ViewUpdateModal()}
        />
        <div
          className={`float-left w-100 ${showOnboarding ? 'onboarding' : ''}`}
        >
          {this.renderDashboard()}
          {this.state.openSidePanel && this.renderSidePanel()}
        </div>
      </Fragment>
    );
  }
}

export default DashboardSummary;
