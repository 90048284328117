import React, { Component, Fragment } from 'react';
import queryString from 'query-string';

import { restRequest } from '../../../../Helpers/RequestHelper';
import GenerateOptions from '../../../../Helpers/ListHelper/GenerateOptions';
import { routesPath } from '../../../Navigation/NavigationUtils';

import { NewBillIcon } from '../../../../Assets/Navigation/NavigationIcons';
import BillImage from '../../../../Components/Onboarding/Diagrams/BillImage';
import BillFlowDiagram from '../../../../Components/Onboarding/Diagrams/BillFlowDiagram';
import ExportIcon from '../../../../Assets/General/ExportIcon';
import Export from '../../../../Components/Export/Export';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import Alert from '../../../../Components/Alert/Alert';
import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';
import ListTable from '../../../../Components/ListTable/ListTable';
import CheckedMenu, {
  Button,
  ButtonLink,
  Options,
} from '../../../../Components/CheckedMenu/CheckedMenu';
import SearchResult from '../../../../Components/SearchBar/SearchResult';
import Onboarding from '../../../../Components/Onboarding/InitialOnboardingScreen';
import {
  removeValueFromLS,
  getObjFromLS,
  setObjInLS,
  getStringValueFromLS,
} from '../../../../Helpers/LocalStorage';
import BillDetails from '../../../../Pages/PurchaseOrder/Bills/BillDetails';
import EditBill from '../../../../Pages/PurchaseOrder/Bills/Edit/EditBill';
import AddBill from '../../../../Pages/PurchaseOrder/Bills/Add/AddBill';
import DeleteIcon from '../../../../Assets/General/DeleteIcon';
import EditIcon from '../../../../Assets/General/EditIcon';
import PdfIcon from '../../../../Assets/General/PdfIcon';

import './BillsPage.css';
import MarkAsOpenIcon from '../../../../Assets/General/MarkAsOpenIcon';
import { checkError } from '../../../../Helpers/AuthHelper';
import { AccessDenied } from '../../../../Components';
class BillsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billId: null,
      loading: true,
      data: [],
      dataExpanded: [],
      billList: [],
      activePage: 1,
      totalPage: 1,
      totalRecords: 0,

      currentFilter: 'all',
      sortFilter: 'created_at',
      orderBy: 'desc',

      currentPage: 1,
      viewPerPage: 20,

      pdf: null,
      showPDF: false,
      showExportModal: false,
      search: '',
      start: '',
      end: '',
      contactId: '',
      contactName: null,
      accessDenied: false,

    };
    this.getID = this.getID.bind(this);
    //this.fetchData = this.fetchData.bind(this)
  }

  filterOptions = [
    {
      id: 'none',
      label: 'Status',
      type: 'tagline',
    },
    {
      id: 'all',
      type: 'link',
      label: 'All',
      onClick: () => this.generateRoute({ filter: 'all', page: 1 }),
    },
    {
      id: 'draft',
      type: 'link',
      label: 'Draft',
      onClick: () => this.generateRoute({ filter: 'draft', page: 1 }),
    },
    {
      id: 'open',
      type: 'link',
      label: 'Open',
      onClick: () => this.generateRoute({ filter: 'open', page: 1 }),
    },
    {
      id: 'partially paid',
      type: 'link',
      label: 'PRTL Paid',
      onClick: () => this.generateRoute({ filter: 'partially paid', page: 1 }),
    },
    {
      id: 'paid',
      type: 'link',
      label: 'Paid',
      onClick: () => this.generateRoute({ filter: 'paid', page: 1 }),
    },
    {
      id: 'overdue',
      type: 'link',
      label: 'Overdue',
      onClick: () => this.generateRoute({ filter: 'overdue', page: 1 }),
    },
    /*{
      id: 'void',
      type: 'link',
      label: "Void",
      onClick: () => this.generateRoute({ filter: 'void', page: 1 })
    },*/
  ];

  sortOptions = [
    /*{
      id: 'bill_date',
      type: 'link',
      label: 'Bill Date',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('bill_date') })
    },*/
    {
      id: 'due_date',
      type: 'link',
      label: 'Due Date',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('due_date') }),
    },
    {
      id: 'order_no',
      type: 'link',
      label: 'Purchase Order No',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('order_no') }),
    },
    {
      id: 'status',
      type: 'link',
      label: 'Bill Status',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('status') }),
    },
    {
      id: 'total',
      type: 'link',
      label: 'Amount',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('total') }),
    },
    {
      id: 'balance_due',
      type: 'link',
      label: 'Balance Due',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('balance_due') }),
    },
    /*{
      id: 'bill_no',
      type: 'link',
      label: 'Bill Number',
      onClick: () => this.generateRoute({ ...this.prepareSortFilter('bill_no') })
    },*/
    {
      id: 'display_name',
      type: 'link',
      label: 'Vendor Name',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('display_name') }),
    },
    {
      id: 'created_at',
      type: 'link',
      label: 'Created Time',
      onClick: () =>
        this.generateRoute({ ...this.prepareSortFilter('created_at') }),
    },
  ];

  importExportOptions = [
    {
      label: 'Export Bills',
      icon: <ExportIcon />,
      onClick: () => this.openExportModal(),
      disabled: () =>
        !this.hasViewPermission('bill') ||
        JSON.parse(localStorage.getItem('exportBtn')),
    },
  ];

  componentDidMount() {
    document.title = 'Bills';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      contactId = '',
    } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState({
        start: '',
        end: '',
      });
      // for (var i = 0; i < this.filterOptions.length; i++) {
      //   if (this.filterOptions[i].id === 'due') {
      //     this.filterOptions.splice(i, 1);
      //     i--; // decrement index if item is removed
      //   }
      // }
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.fetchData()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.fetchData()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.fetchData()
      );
    }
    if (page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.fetchData()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.fetchData()
      );
    }
    if (search !== this.state.search) {
      this.setState({ search: search, bulkSelectedData: [] }, () =>
        this.fetchData()
      );
    }
    if (contactId !== this.state.contactId) {
      this.setState(
        {
          contactId: contactId,
        },
        () => this.fetchData()
      );
    }
  }

  fetchDataWithQueryString() {
    const { filter, page, sort, orderBy, view, search, start, end, contactId } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: parseInt(page),
        orderBy: orderBy,
        viewPerPage: parseInt(view),
        search: search,
        start: start,
        end: end,
        contactId: contactId,
      },
      () => this.fetchData()
    );
  }

  parseQueryString() {
    const query = queryString.parse(this.props.location.search);
    let filter = '';
    switch (query.filter) {
      case 'all':
      case 'draft':
      case 'open':
      case 'partially paid':
      case 'paid':
      case 'overdue':
      case 'void':
      case 'due':
        filter = query.filter;
        break;
      default:
        filter = 'all';
    }

    let sort = '';
    switch (query.sort) {
      case 'due_date':
      case 'order_no':
      case 'status':
      case 'total':
      case 'created_at':
      case 'balance_due':
      case 'display_name':
      case 'bill_no':
        sort = query.sort;
        break;
      default:
        sort = 'created_at';
    }

    let orderBy = '';
    switch (query.orderBy) {
      case 'asc':
      case 'desc':
        orderBy = query.orderBy;
        break;
      default:
        orderBy = 'desc';
    }

    let page = parseInt(query.page);
    if (!page) page = 1;
    let view = parseInt(query.view);
    if (!view) view = 20;

    let search = query.search;
    if (!search) search = '';

    let start = query.start;
    if (!start) start = '';

    let end = query.end;
    if (!end) end = '';

    let contactId = query.contactId;
    if (!contactId) contactId = '';

    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
      start,
      end,
      contactId,
    };
  }

  fetchData() {
    this.setState({ loading: true });
    const currentFilter =
      this.state.currentFilter[0]?.toLowerCase() +
      this.state.currentFilter.substr(1);
    var url = `bills?filter=${currentFilter}&page=${this.state.currentPage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}&view=${this.state.viewPerPage}&search=${this.state.search}`;
    if (this.state.start && this.state.end) {
      url = `${url}&start=${this.state.start}&end=${this.state.end}`;
      if (currentFilter === 'due') {
        this.filterOptions.push({
          id: 'due',
          type: 'link',
          label: 'Due Bills',
          onClick: () =>
            this.generateRoute({
              filter: 'due',
              page: 1,
            }),
        });
      }
    }
    if (this.state.contactId) {
      url = `${url}&contactId=${this.state.contactId}`;
    }

    restRequest('get', url)
      .then((res) => {
        let obj = {
          prevId: null,
          id: res.data[0] && res.data[0].id,
          nextId: res.data[1] && res.data[1].id,
          moduleName: 'bills',
          urlPath: window.location.pathname,
          queryParam: window.location.search,
        };
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS && dataFromLS.moduleName !== 'bills') {
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
          removeValueFromLS('module');
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (!dataFromLS) {
          // when redirect from dashboard to new tab, page shows loader unlimited time
          sessionStorage.setItem('fullscreen', true);
          sessionStorage.setItem('once', true);
        }
        if (!res.data[0]) {
          removeValueFromLS('module');
        }
        if (!dataFromLS || dataFromLS.action === 'add') {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
        } else if (dataFromLS.action === 'bulkdelete') {
          if (res.data[0]) {
            setObjInLS('module', { ...obj, lolo: 'no' });
          }
        } else if (
          dataFromLS &&
          dataFromLS.moduleName === 'bills' &&
          (dataFromLS.queryParam !== window.location.search ||
            localStorage.getItem('org_switch') === 'true')
        ) {
          if (res.data[0]) {
            setObjInLS('module', obj);
          }
          localStorage.removeItem('org_switch');
        }
        if (!res?.data?.length) {
          setObjInLS('module', obj);
        }
        this.setState(
          {
            billList: res.data,
            data: this.prepareDataForTableCollapsed(res.data),
            dataExpanded: this.prepareDataForTable(res.data),
            totalPage: res.last_page,
            totalRecords: res.total,
            loading: false,
          },
          () => {
            this.setState({
              mainLoading: false,
              loading: false,
            });
          }
        );
        if (this.state.contactId) {
          this.setState({ contactName: res.data[0]?.vendor.display_name });
        }
      })
      .catch((error) => {
        if (error && error.response.status === 403) {
          this.setState({ accessDenied: true, mainLoading: false });
        }
        checkError(error);
        this.props.handleToast(error, 'error');
        this.setState({ loading: false });
      });
  }

  prepareDataForTableCollapsed(data) {
    return data.map((bill) => ({
      id: bill.id,
      //'Bill Due Date': {
      'Due Date': {
        id: 'due_date',
        sortable: true,
        getValue: function () {
          return bill[this.id];
        },
      },
      // 'Bill No.': {
      //   id: 'bill_no',
      //   sortable: true,
      //   getValue: function () { return bill[this.id] }
      // },
      Vendor: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return bill.vendor[this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return bill[this.id] === 'partially paid'
            ? 'PRTL Paid'
            : bill[this.id];
        },
      },
      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          return `${bill.currency.symbol}${bill[this.id]}`;
        },
      },
      // not displayable
      additional: {
        status: bill.status,
      },
    }));
  }

  prepareDataForTable(data) {
    return data.map((bill) => ({
      id: bill.id,
      /*'Bill No.': {
        id: 'bill_no',
        sortable: true,
        getValue: function () { return bill[this.id] }
      },
      'Bill Date': {
        id: 'bill_date',
        sortable: true,
        getValue: function () { return getDate(bill[this.id]) }
      },*/
      'Due Date': {
        id: 'due_date',
        sortable: true,
        getValue: function () {
          return bill[this.id];
        },
      },
      'Bill No.': {
        id: 'bill_no',
        sortable: true,
        getValue: function () {
          return bill[this.id];
        },
      },
      'Purchase Order': {
        id: 'order_no',
        sortable: true,
        getValue: function () {
          return bill[this.id];
        },
      },
      Vendor: {
        id: 'display_name',
        sortable: true,
        getValue: function () {
          return bill.vendor[this.id];
        },
      },
      Status: {
        id: 'status',
        sortable: true,
        getValue: function () {
          return bill[this.id] === 'partially paid'
            ? 'PRTL Paid'
            : bill[this.id];
        },
      },
      Amount: {
        id: 'total',
        sortable: true,
        alignRight: true,
        getValue: function () {
          /*return `${bill.currency.symbol}${bill[this.id]}` */
          return `${bill.currency.symbol}${parseFloat(bill[this.id]).toFixed(
            2
          )}`;
        },
      },
      'Balance Due': {
        id: 'balance_due',
        sortable: true,
        alignRight: true,
        getValue: function () {
          /*return `${bill.currency.symbol}${bill[this.id]}` */
          return `${bill.currency.symbol}${parseFloat(bill[this.id]).toFixed(
            2
          )}`;
        },
      },
      // not displayable
      additional: {
        status: bill.status,
      },
    }));
  }

  openPdf = (id) => {
    this.setState({ showPDF: true });
    restRequest('get', `bills/${id}/pdf`)
      .then((res) => {
        this.setState({ pdf: res });
      })
      .catch((error) => checkError(error));
  };

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'bills',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    // sessionStorage.setItem('fullscreen',true)
    // sessionStorage.setItem('once',true)
    setObjInLS('module', obj);
    this.setState({ billId: id });
    // setValueInLS('id',id)
    //this.props.history.push('/r')
  }

  closeAllModals = () => {
    this.setState({
      showPDF: false,
      pdf: null,
    });
  };

  markAsOpen(id) {
    restRequest('put', `bills/${id}/markasopen`)
      .then(() => {
        this.props.handleToast('Bill has been marked as open.', 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  markAsVoid(id) {
    restRequest('put', `bills/${id}/markasvoid`)
      .then(() => {
        this.props.handleToast('Bill has been marked as void.', 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  convertToDraft(id) {
    restRequest('put', `bills/${id}/converttodraft`)
      .then(() => {
        this.props.handleToast('Bill has been marked as draft.', 'success');
        this.fetchData();
        this.updateCheckedState();
      })
      .catch((error) => checkError(error, this.props.handleToast));
  }

  populateConditionalOptions(id) {
    const hasEditPermission = this.hasPermission('bill', 'Edit');
    const options = [];

    if (!hasEditPermission) return options;

    options.push({
      label: 'Mark as Open',
      onClick: () => this.markAsOpen(id),
    });

    /*options.push({
      label: 'Mark as Void',
      onClick: () => this.markAsVoid(id)
    })

    options.push({
      label: 'Convert to draft',
      onClick: () => this.convertToDraft(id),
    });*/
    return options;
  }

  // render action menu for single selected data
  renderSingleActionMenu = () => {
    const checkedId = this.state.bulkSelectedData[0];
    const [, hasEditPermission, hasDeletePermission] = this.hasPermission(
      'bill',
      'All'
    );
    const options = this.populateConditionalOptions(checkedId);
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <ButtonLink
          enable={
            hasEditPermission &&
            !this.isDisabled(checkedId, ['void']) &&
            !this.checkIfEditable(checkedId)
          }
          to={`/bills/edit/${checkedId}`}
          type="edit"
        />
        <Button handleClick={() => this.openPdf(checkedId)} type="pdf" />
        <Button handleClick={() => this.print(checkedId)} type="print" />
        <Button
          enable={hasDeletePermission}
          handleClick={() => this.openDeleteModal(checkedId)}
          type="delete"
        />

        <Button handleClick={() => this.exportCurrentView()} type="export" />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  print(id) {
    this.setState({ loading: true });
    restRequest('get', `bills/${id}/print`)
      .then((res) => {
        var w = window.open();
        w.document.write(res.body);
        w.document.close();

        setTimeout(function () {
          w.focus();
          w.print();
          w.close();
        }, 100);

        this.setState({ loading: false });
      })
      .catch((error) => {
        //this.props.handleToast(error,'error')
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }
  // render action menu for multiple selected data
  renderMultipleActionMenu = () => {
    const hasDeletePermission = this.hasPermission('bill', 'Delete');
    const options = this.populateConditionalOptionsBulkSelect();
    return (
      <CheckedMenu count={this.state.bulkSelectedData.length}>
        <Button
          enable={hasDeletePermission}
          handleClick={() => this.openBulkDeleteModal()}
          type="delete"
        />
        <Options enable={!!options.length} options={options} />
      </CheckedMenu>
    );
  };

  populateConditionalOptionsBulkSelect() {
    const options = [];
    options.push({
      label: 'Export Selected',
      onClick: () => this.exportCurrentView(),
      disabled: () => !this.hasViewPermission('bill'),
    });

    return options;
  }

  exportCurrentView = () => {
    restRequest('post', `bills/exportSelected`, {
      ids: this.state.bulkSelectedData,
    })
      .then((res) => {
        let csvFile = 'data:text/csv;charset=utf-8,' + res;
        let encodedUri = encodeURI(csvFile);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Bills.csv`);
        document.body.appendChild(link);
        link.click();
        this.props.handleToast(`Bills exported successfully`, 'success');
        this.setState({ bulkSelectedData: [] });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        //this.props.handleToast(error, 'error')
      });
  };

  isDisabled(id, status) {
    let bill = this.state.billList.find((bill) => bill.id === id);
    return status.includes(bill.original_status);
  }

  checkIfEditable(id) {
    let bill = this.state.billList.find((bill) => bill.id === id);
    if (bill.status === 'overdue') return true;
    return false;
  }

  handleEditLink(id) {
    this.props.history.push(`/bills/edit/${id}`);
  }

  checkBillReceived = (id) => {
    let bill = this.state.billList.find((bill) => bill.id === id);
    if (bill.is_billed_from_receive === 1 || bill.is_receive_from_bill === 1)
      return true;
    return false;
  };

  prepareRowOptions() {
    const hasEditPermission = this.hasPermission('bill', 'Edit');
    const rowOptions = [
      {
        label: 'Edit',
        icon: EditIcon,
        onClick: (id) => this.handleEditLink(id),
        disabled: (id) =>
          this.isDisabled(id, ['void', 'paid', 'overdue', 'partially paid']) ||
          this.checkIfEditable(id) ||
          this.checkBillReceived(id),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: this.openDeleteModal,
        disabled: (id) =>
          this.isDisabled(id, ['paid', 'overdue', 'partially paid']),
      },
      {
        label: 'View PDF',
        icon: PdfIcon,
        onClick: (id) => this.openPdf(id),
      },
      {
        label: 'Mark as Open',
        onClick: (id) => this.markAsOpen(id),
        icon: MarkAsOpenIcon,
        disabled: (id) =>
          !hasEditPermission ||
          this.isDisabled(id, ['open', 'void', 'paid', 'partially paid']),
      },
    ];

    const permissions = this.hasPermission('bill', 'All');
    const allowedRowOptions = GenerateOptions(permissions, rowOptions);

    return allowedRowOptions;
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  renderExportModal() {
    return (
      <CustomModal
        showModal={this.state.showExportModal}
        title="Export Bills"
        Icon={ExportIcon}
        onClose={this.closeExportModal}
        renderActions={false}
      >
        <Export
          name="Bills"
          onCancel={this.closeExportModal}
          submitURL="bills/export"
          handleToast={this.props.handleToast}
        />
      </CustomModal>
    );
  }

  renderTable() {
    const {
      showPDF,
      pdf,
      data,
      dataExpanded,
      bulkSelectedData,
      sortFilter,
      orderBy,
      accessDenied,
      //loading,
      mainLoading,
    } = this.state;
    let fullscreen = getStringValueFromLS('fullscreen');
    let fullscreenFlag = getStringValueFromLS('fullscreenFlag');
    if (fullscreenFlag === 'true') {
      sessionStorage.setItem('fullscreen', false);
      sessionStorage.setItem('fullscreenFlag', false);
    }
    if (!mainLoading) {
      if (this.checkIfOnboarding()) {
        if (fullscreen === 'true')
          return (
            // <Onboarding
            //   name="Bill"
            //   Image={BillImage}
            //   FlowDiagram={BillFlowDiagram}
            //   title="Manage Bills Activity"
            //   description="Create & manage bills of products you bought"
            //   buttonLabel="Create Bill"
            //   buttonLink="/bills/add"
            // />
            accessDenied ?
              <AccessDenied />
              :
            <Onboarding
              name="Bill"
              Image={BillImage}
              FlowDiagram={BillFlowDiagram}
              videoFrame={
                <iframe
                  width="700"
                  height="400"
                  src="https://www.youtube.com/embed/VJ88qlgGn5Y"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              }
              DisplayIcon={NewBillIcon}
              headerTitle="Bills"
              title="Manage Bills Activity"
              description="Create your Bills & get paid easily"
              buttonLabel="Create Bill"
              buttonLink="/bills/add"
            />
          );
        else {
          return false;
        }
      }
    }
    return (
      <Fragment>
        {this.renderExportModal()}
        <PDFViewer
          showPDF={showPDF}
          hidePDFModal={this.closeAllModals}
          pdf={pdf}
        />
        <ListTable
          className={
            fullscreen === 'true' ? 'bill_listing' : 'expanded_view_bill'
          }
          data={fullscreen === 'true' ? dataExpanded : data}
          selectedData={bulkSelectedData}
          rowOptions={this.prepareRowOptions()}
          linkTo="/bills"
          sortFilter={sortFilter}
          orderBy={orderBy}
          loading={mainLoading}
          generateRoute={this.generateRoute}
          handleCheckAll={this.handleCheckAll}
          handleCheckSingle={this.handleCheckSingle}
          getID={this.getID}
          moduleName="bills"
          totalRecords={this.state.totalRecords}
        />
      </Fragment>
    );
  }

  onClickHandler = () => {
    this.setState({ contactId: '', contactName: '' });
    let dataFromLS = getObjFromLS('module');
    if (dataFromLS && dataFromLS.moduleName === 'bills') {
      setObjInLS('module', {
        ...dataFromLS,
        queryParam: '?filter=all&sort=created_at&page=1&orderBy=desc&view=20',
      });
      this.props.history.push('/r');
    } else {
      this.props.history.push(routesPath.bills);
    }
  };

  renderSearchDetails() {
    const { search, contactId, contactName } = this.state;
    if (search) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Search Criteria"
          linkTo={routesPath.bills}
        >
          <SearchResult
            criterias={['Bill No.', 'Vendor Name', 'Order No.']}
            searchKey={search.replace('%26', '&')}
          />
        </Alert>
      );
    }
    if (contactId) {
      return (
        <Alert
          className="full-width green"
          id="elasticSearch"
          title="Bills"
          linkTo={routesPath.bills}
          crossIcon={false}
          //onClickHandler={this.onClickHandler}
        >
          Displaying all results from contact "{contactName}"
        </Alert>
      );
    }
    return null;
  }

  render() {
    return this.renderTable();
  }
}

export default HeaderWrapper(
  BillsPage,
  {
    name: 'Bills',
    deleteName: 'Bill',
    // tableIcon:'bills_table_header_icon',
    tableIcon: 'general_module_icon',
    Icon: NewBillIcon,
    baseUrl: 'bills',
    buttonUrl: '/bills/add',
    buttonLabel: 'New Bill',
    redirectUrl: '/bills',
    routeName: 'bills',
    showDetailsComp: true,
    bypassLoadingState: false,
    permissionName: 'bill',
  },
  BillDetails,
  AddBill,
  EditBill
);
