import React, { Fragment } from 'react';
import { NewItemIcon } from '../../../../Assets/Navigation/NavigationIcons';
import ItemGroupDetails from '../../ItemGroups/ItemGroupDetails/index';
import CustomModal from '../../../../Components/CustomModal/CustomModal';

// var showItemGroupModal = false
const ItemInfo = ({
  info: {
    unit,
    upc,
    mpn,
    ean,
    isbn,
    taxable,
    itemType,
    description,
    weight,
    dimensions,
    inventory_type,
    manufacturer,
    brand,
    history,
    tax,
    sku,
    itemGroup,
    varient,
    inModal,
    platform
  },
}) => {
  const [item, showItem] = React.useState(false);
  function viewItemGroupModal() {
    return itemGroup ? (
      <CustomModal
        Icon={NewItemIcon}
        showModal={item}
        title={itemGroup.name ? itemGroup.name : 'View Item Group'}
        className="view_item_groups_popup"
        modaltitle="item_group_modal_title"
        renderActions={false}
        size="large"
        onClose={() => showItem(false)}
        linkTo={`item-groups/${itemGroup.id}`}
      >
        <ItemGroupDetails id={itemGroup.id} inModal={true} />
      </CustomModal>
    ) : null;
  }

  return (
    <Fragment>
      {viewItemGroupModal()}
      <div className="section-details--info">
        <div className="float-left w-100">
          {itemGroup && (
            <div className="section-detail--info-main">
              <div className="section-details--info-title semi-bold float-left w-50 item_label">
                Group Name
              </div>
              {inModal ? (
                <div className="capitalize float-left w-50 word-break float-right">
                  {itemGroup.name}
                </div>
              ) : (
                <div
                  className="capitalize float-left w-50 word-break float-right colorBlue pointer"
                  onClick={() => showItem(true)}
                >
                  {itemGroup.name}
                </div>
              )}
            </div>
          )}

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Item Type
            </div>
            <div className="capitalize float-left w-50 word-break">
              {itemType}{' '}
              {inventory_type && inventory_type === 'inventory'
                ? '(Returnable)'
                : '(Non-Returnable)'}{' '}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Inventory
            </div>
            <div
              className={`capitalize float-left w-50 word-break ${
                inventory_type === 'inventory'
                  ? 'inventory-track'
                  : 'inventory-untrack'
              }`}
              // className="capitalize float-left w-50 word-break"
              // style={{
              //   backgroundColor:
              //     inventory_type === 'inventory' ? 'green' : 'red',
              // }}
            >
              {inventory_type === 'inventory' ? `Tracked` : `Un-Tracked`}
            </div>
          </div>

          {varient.length !== 0 &&
            varient?.map((res, indx) => (
              <div className="section-detail--info-main" key={indx}>
                <div className="section-details--info-title semi-bold float-left w-50 item_label">
                  {Object.keys(res)}
                </div>
                <div className="margin-bottom-xs float-left w-50 word-break">
                  {Object.values(res)}
                </div>
              </div>
            ))}

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Unit
            </div>
            <div className="margin-bottom-xs float-left w-50 word-break">
              {unit}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              SKU
            </div>
            <div className="margin-bottom-xs float-left w-50 word-break">
              {sku}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              UPC
            </div>
            <div className="margin-bottom-xs float-left w-50">{upc}</div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              MPN
            </div>
            <div className="margin-bottom-xs float-left w-50">{mpn}</div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              EAN
            </div>
            <div className="margin-bottom-xs float-left w-50">{ean}</div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              ISBN
            </div>
            <div className="margin-bottom-xs float-left w-50">{isbn}</div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Manufacturer
            </div>
            <div className="margin-bottom-xs float-left w-50 item_fields_break">
              {manufacturer}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Brand
            </div>
            <div className="margin-bottom-xs float-left w-50 item_fields_break">
              {brand}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Weight
            </div>
            <div className="margin-bottom-xs float-left w-50">
              {weight && weight}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Dimensions
            </div>
            <div className="margin-bottom-xs float-left w-50">
              {dimensions && dimensions}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-50 item_label">
              Origin
            </div>
            <div className="margin-bottom-xs float-left w-50 item_fields_break">
              {platform}
            </div>
          </div>

          <div className="section-detail--info-main">
            <div className="section-details--info-title semi-bold float-left w-100 item_label">
              Description
            </div>
            <div className="margin-bottom-xs float-left w-100">
              {description}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ItemInfo;
