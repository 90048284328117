import React, { Component } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import { NewAccountIcon } from '../../Assets/Settings/SettingsIcon';
import ListTable from '../../Components/ListTable/ListTable';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
import DeleteIcon from '../../Assets/General/DeleteIcon';
import EmptyTable from '../../Components/ListTable/EmptyTable';
import SubNavMenu from '../../Components/SubNavMenu/SubNavMenu';
import Loader from '../../Components/Loader/Loader';
import CustomModal from '../../Components/CustomModal/CustomModal';
import AccountForm from './AccountForm';
import AccountGroupList from '../../Pages/Account/AccountGroupList';
import NewAccount from './NewAccount';
import InventoryAccountIcon from '../../Assets/General/InventoryAccountIcon';
import {
  getObjFromLS,
  setObjInLS,
  setValueInLS,
} from '../../Helpers/LocalStorage';
import { checkError } from '../../Helpers/AuthHelper';
import classNames from 'classnames';

class AccountList extends Component {
  // addNew =
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      allData: [],
      loading: true,
      sortFilter: 'created_at',
      orderBy: 'desc',
      showAccountNameCreateModal:
        window.location.search.slice(1).split('=')[0] === 'new',
      showAccountGroupCreateModal: false,
      showEditTaxModal: false,
      taxID: null,
      currentTab: 'accountName',
      totalRecords: 0,
      activePage: 1,
      showPrompt: false,
    };
  }

  componentDidMount() {
    document.title = 'Inventory Accounts';
    this.fetchData();
    setValueInLS('fullscreen', true);
    getObjFromLS('tab') && this.handleTabChange(getObjFromLS('tab'));
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  componentDidUpdate() {
    setObjInLS('tab', this.state.currentTab);
  }

  fetchData = () => {
    this.setState({ loading: true });
    restRequest(
      'get',
      `accounts?page=${this.state.activePage}&sort_by=${this.state.sortFilter}&order_by=${this.state.orderBy}`
    )
      .then((res) => {
        console.log(res, 'res');

        this.setState({
          data: this.prepareDataForTable(res?.accounts, res?.currency),
          allData: res?.accounts,
          totalRecords:
            res?.accounts && res?.accounts?.length ? res?.accounts?.length : 0,
          currency: res?.currency.symbol,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  };

  prepareDataForTable(data, currency) {
    console.log(data, 'dataa');
    return data.map((account) => ({
      id: account?.id,
      Name: {
        id: 'title',
        sortable: false,
        getValue: function () {
          return account[this.id];
        },
      },
      Group: {
        id: 'name',
        getValue: function () {
          return account?.account_group && account?.account_group[this.id];
        },
      },
      'Opening Balance': {
        id: 'opening_balance',
        alignRight: true,
        sortable: false,
        getValue: function () {
          return `${currency.symbol}${account[this.id]}`;
        },
      },
      'Current Balance': {
        id: 'current_balance',
        alignRight: true,
        sortable: false,
        getValue: function () {
          return `${currency.symbol}${account[this.id]}`;
        },
      },
    }));
  }

  hasDeltePermission() {
    const hasDeltePermission = this.hasPermission('account', 'Delete');
    return hasDeltePermission;
  }
  checkDeletable = (id) =>
    this.state.allData.find((one) => one.id === id).deletable === 0;

  prepareRowOptions() {
    const rowOptions = [
      {
        label: 'Delete',
        onClick: (id) => this.deleteAccountData(id),
        icon: DeleteIcon,
        disabled: (id) => !this.hasDeltePermission() || this.checkDeletable(id),
      },
    ];

    return rowOptions;
  }

  deleteAccountData = (id) => {
    this.openDeleteModal(id);
    setObjInLS('tab', this.state.currentTab);
  };
  renderCreateAccountNameModal() {
    return (
      <CustomModal
        showModal={this.state.showAccountNameCreateModal}
        className={classNames('create_new_account_inventory')}
        title="Create Inventory Account"
        fil="#000000"
        modaltitle="inventory_modal_title"
        Icon={InventoryAccountIcon}
        renderActions={false}
        size="large"
        showPrompt={this.state.showPrompt}
        onClose={() => this.setState({ showAccountNameCreateModal: false })}
      >
        <AccountForm
          history={this.props.history}
          handleToast={this.props.handleToast}
          inModal
          onClose={() => this.setState({ showAccountNameCreateModal: false })}
          onSubmit={() => {
            this.fetchData();
            this.setState({ showAccountNameCreateModal: false });
          }}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }
  renderCreateAccountGroupModal() {
    return (
      <CustomModal
        showModal={this.state.showAccountGroupCreateModal}
        title="Create Inventory Group"
        fil="#000000"
        className={classNames('add_inventory_tabs')}
        modaltitle="inventory_modal_title"
        Icon={InventoryAccountIcon}
        renderActions={false}
        size="large"
        onClose={() => this.setState({ showAccountGroupCreateModal: false })}
        showPrompt={this.state.showPrompt}
      >
        <NewAccount
          inModal={true}
          close={() => this.setState({ showAccountGroupCreateModal: false })}
          onSubmit={() => {
            this.fetchData();
          }}
          handleToast={this.props.handleToast}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  }

  handleTabChange(tab) {
    this.setState({ currentTab: tab });
  }

  tabs() {
    return [
      {
        id: 'accountGroup',
        label: 'Account Group',
        onClick: () => {
          this.handleTabChange('accountGroup');
        },
        renderContent: () => this.renderAccountGroupTable(),
      },
      {
        id: 'accountName',
        label: 'Account Name',
        onClick: () => {
          this.handleTabChange('accountName');
        },
        renderContent: () => this.renderAccountNamesTable(),
        data: this.state.data,
        loading: this.state.loading,
        orderBy: this.state.orderBy,
        sortFilter: this.state.sortFilter,
        prepareRowOptions: this.prepareRowOptions,
        generateRoute: this.generateRoute,
      },
    ];
  }

  showInvitedUsers() {
    this.setState({ showInvitedUsers: true });
  }

  showUserRolesUsersAdd() {
    this.setState({ showUserRolesUsersAdd: true });
  }

  renderAccountNamesTable() {
    console.log(this.state, 'this.state');
    // if (this.state.data.length === 0) return <EmptyTable />;
    return (
      <>
        <ListTable
          data={this.state.data}
          rowOptions={this.prepareRowOptions()}
          generateRoute={this.generateRoute}
          hasCheckbox={false}
          loading={this.state.loading}
          orderBy={this.state.orderBy}
          sortFilter={this.state.sortFilter}
          totalRecords={this.state.totalRecords}
        />
      </>
    );
  }
  renderAccountGroupTable() {
    return (
      <AccountGroupList
        history={this.props.history}
        handleToast={this.props.handleToast}
      />
    );
  }
  showAccountGroupCreateModal() {
    this.handleTabChange('accountGroup');
    this.setState({ showAccountGroupCreateModal: true });
  }
  showAccountNameCreateModal() {
    this.handleTabChange('accountName');
    this.setState({ showAccountNameCreateModal: true });
  }
  renderTable() {
    const hasAccountCreatePermission = this.hasPermission('account', 'Create');
    return (
      <>
        {this.renderCreateAccountNameModal()}
        {this.renderCreateAccountGroupModal()}
        <SubNavMenu
          tabs={this.tabs()}
          isInventoryAccounts={true}
          showAccountGroupCreateModal={this.showAccountGroupCreateModal.bind(
            this
          )}
          showAccountNameCreateModal={this.showAccountNameCreateModal.bind(
            this
          )}
          currentTab={this.state.currentTab}
          permission={hasAccountCreatePermission}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.renderTable();
  }
}

export default HeaderWrapper(AccountList, {
  name: 'Chart of Accounts',
  Icon: NewAccountIcon,
  baseUrl: 'accounts',
  redirectUrl: '/setting/account',
  routeName: 'account',
  buttonUrl: '/account/add',
  createModal: true,
  buttonLabel: 'Add New Account',
  pagination: false,
  showNewButton: false,
  permissionName: 'account',
  subName: 'Account Name',
  // showCreateModal:showCreateModal()
});
