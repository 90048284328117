import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withFormik, FieldArray } from 'formik';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import {
  Radio,
  Switch,
  Loader,
  CustomModal,
  RadioContainer,
  ListTableImageColumn,
} from '../../';
import {
  checkError,
  setObjInLS,
  restRequest,
  getObjFromLS,
  GetDateString,
  getFilteredItem,
  getOverallTaxDetails,
  handleExtraDescription,
} from '../../../Helpers';
import {
  required,
  MAX_NUMBER,
  ValidateDate,
  moreThanZero,
  moreThanMinus,
  ValidateMax50,
  ValidateNumber,
  ValidateEqual0,
  ValidateMax1000,
  ValidateRequired,
  ValidateMax10Billion,
  ValidateMax100Number,
} from '../../../Helpers/Validator';
import {
  Form,
  Field,
  TaxForm,
  AddItem,
  ItemForm,
  ErrorFocus,
  FormAction,
  FormSection,
} from '../';
import moment from 'moment';
import { ToolTip } from '../../../Common';
import { Draggable } from 'react-beautiful-dnd';
import DragDropItems from '../../../Common/DragDrop';
import { emptyItem } from '../../../Api/Invoice/Utils';
import { getDiscountOptions } from '../../../Constants';
import TableContent from '../../ListTable/TableContant';
import { MdDragIndicator } from '../../../Common/Icons';
import AddContact from '../../../Pages/Contact/AddContact';
import { formSubmit, getError, validate } from '../FormHelper';
import Customer from '../../../Pages/Contact/ContactDetails/index';
import { validatePurchaseOrderForm } from '../../../Helpers/FormValidator';
import {
  NewBillIcon,
  NewContactIcon,
} from '../../../Assets/Navigation/NavigationIcons';

let totalConfirmCheck = false;

// const skipButton = localStorage.getItem('skipBtnoldAccount');
const getYupValidationSchema = (values, props) => {
  return Yup.object().shape({
    reference: ValidateMax50,
    vendorID: required(ValidateNumber),
    purchaseDate: required(ValidateDate),
    deliveryDate: required(ValidateDate),
    purchaseOrderNo: required(ValidateMax50),
    termsAndConditions: values.hasTermsAndConditions
      ? ValidateMax1000
      : ValidateEqual0,
    warehouseID:
      props.type === 'dropship' ? ValidateNumber : required(ValidateNumber),
    items: required(
      Yup.array().of(
        Yup.object().shape({
          name: ValidateRequired,
          quantity: required(
            moreThanZero(ValidateMax10Billion).integer('Invalid Value')
          ),
          accountID: required(ValidateNumber),
          unitPrice: required(moreThanMinus(ValidateMax10Billion)),
        })
      )
    ),
    transactionDiscount:
      values.transactionDiscountType === 'percent'
        ? ValidateMax100Number
        : ValidateMax10Billion,
    adjustment: ValidateMax10Billion,
  });
};
let flagOnce = 0;
let modalflag = false;
var loadingSelectItem = false;
const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: ({ purchaseOrder }) => ({
    ...purchaseOrder,
    saveAndSend: false,
    saveAndIssue: false,
  }),
  mapPropsToStatus: (props) => ({
    emptyItem: props.emptyItem,
    showContactCreateModal: false,
    itemsFromDB: props.itemsFromDB,
  }),
  handleSubmit: (values, bag) => {
    handleFormSubmit(values, bag);
  },
});

const POForm = (props) => {
  const {
    type,
    title,
    errors,
    status,
    values,
    touched,
    history,
    setValues,
    setStatus,
    handleBlur,
    handleToast,
    cofirmState,
    handleSubmit,
    handleChange,
    isSubmitting,
    updateTaxList,
    purchaseOrder,
    setFieldValue,
    setFieldTouched,
    shippingAddress,
    warehouseFromDB,
    updateVendorList,
    vendorIdTransaction,
    organizationCurrency,
    handleTotalCheckFalse,
    previousPurchasePrices,
  } = props;
  const [itemsList, setItemsList] = useState([]);
  const [staticVal, setStaticValues] = useState({});
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentVendor, setCurrentVendor] = useState([]);
  const [skipButton, setskipButton] = useState(
    localStorage.getItem('skipBtnoldAccount')
  );
  useEffect(() => {
    setskipButton(JSON.parse(localStorage.getItem('skipBtnoldAccount')));
  }, [skipButton]);
  useEffect(() => {
    handleAlert();
  }, [values, staticVal]);

  useEffect(() => {
    setStaticValues(values);
  }, []);

  useEffect(() => {
    let editableItems = [...values.items];
    values.items.forEach((singleItem, index) => {
      editableItems[index] = {
        ...singleItem,
        previousPurchasePrice: previousPurchasePrices[index],
      };
    });
    setFieldValue('items', editableItems);
    setItemsList(editableItems);
  }, [previousPurchasePrices]);

  const handleAlert = () => {
    let { checkout } = validatePurchaseOrderForm(values, {
      ...staticVal,
      items: itemsList,
    });
    if (!checkout) props.setPrompt();
    else props.setPrompt(false);
  };

  function closeModal() {
    setFieldValue('showTaxModal', false);
    setFieldValue('showItemModal', false);
    setStatus({
      ...status,
      showContactCreateModal: false,
    });
  }

  var PurchaseFakeItem = false;
  // if (type === 'clone') {
  //   var obj = vendorsFromDB.find((c) => c.id === values.vendorID);
  //   if (typeof obj === 'undefined') {
  //     values.vendorID = null;
  //   }
  // }

  const renderAddItem = () => {
    const { items } = values;
    items.forEach((item) => {
      if (item.id == null) {
        PurchaseFakeItem = true;
      }
    });
    if (PurchaseFakeItem) {
      return '';
    } else {
      return (
        <AddItem
          disabled={type === 'dropship' || type === 'backorder'}
          onClick={() =>
            setFieldValue('items', [...values.items, status.emptyItem])
          }
        />
      );
    }
  };

  const handleTermsAndConditions = (event) => {
    let selectedValue = event.target.value;
    if (selectedValue === 'true') {
      setFieldValue('hasTermsAndConditions', !!selectedValue);
    } else {
      setFieldValue('hasTermsAndConditions', !!!selectedValue);
    }
  };

  const handleContactCreateSubmit = (newCustomer) => {
    const old_obj = {
      id: newCustomer.id,
      display_name: newCustomer.display_name,
    };
    setVendorName(newCustomer.display_name);
    const { id: value, display_name: label, ...rest } = old_obj;

    const new_customer = { value, label, ...newCustomer };

    setCurrentVendor(new_customer);
    updateVendorList(new_customer);
    closeModal();
    setFieldValue('vendorID', new_customer.id);
  };

  const handleNewCreatedItem = async (option, index) => {
    loadingSelectItem = true;
    status.itemsFromDB.push(option);
    let newItem = {
      quantity: 1,
      id: option.id,
      sku: option.sku,
      ean: option.ean,
      mpn: option.mpn,
      upc: option.upc,
      name: option.name,
      isbn: option.isbn,
      unit: option.unit,
      images: option.images,
      stocks: option.stocks,
      previousPurchasePrice: 0,
      dragDropID: GetDateString(),
      unitPrice: option.purchase_unit_price,
      purchase_unit_price: option.purchase_unit_price,
      accountID: parseInt(option.purchase_account_id),
      total: parseFloat(option.purchase_unit_price).toFixed(2),
      purchase_account_id: parseInt(option.purchase_account_id),
      inventoryType: option.inventory_type === 'inventory' ? true : false,
      tax: {
        id: option.tax ? option.tax.id : null,
        name: option.tax ? option.tax.name : '',
        rate: option.tax ? option.tax.rate : 0,
      },
      taxAmount: option.tax
        ? getTaxAmount(1, option.purchase_unit_price, option.tax.rate)
        : 0,
    };
    values.items.splice(index, 1, newItem);
    renderAddItem();
    loadingSelectItem = false;
  };

  const renderTermsAndConditions = () => {
    return (
      <div
        className={` terms_error po-terms-and-cond inline-terms-and-conditions flex-50 mt_0 ${
          values.hasTermsAndConditions ? 'textarea-show-set' : ''
        }`}
      >
        <Field
          id="yes"
          type="radio"
          labelStyle={true}
          name="Add Terms & Conditions?"
          className="radio-field radio_buttons terms-po"
        >
          <RadioContainer>
            <Radio
              id="yes"
              name="Yes"
              value={true}
              className="inline-field"
              onChange={handleTermsAndConditions}
              checked={values.hasTermsAndConditions}
            />
            <Radio
              id="no"
              name="No"
              value={false}
              className="inline-field"
              onChange={handleTermsAndConditions}
              checked={!values.hasTermsAndConditions}
            />
          </RadioContainer>
        </Field>
        <div className="invoice-textarea-height">
          {values.hasTermsAndConditions && (
            <Field
              type="textarea"
              required={true}
              id="termsAndConditions"
              handleChange={handleChange}
              className="textarea-po-terms"
              value={values.termsAndConditions}
              error={touched.termsAndConditions && errors.termsAndConditions}
            />
          )}
        </div>
      </div>
    );
  };

  const newItemModal = () => {
    return (
      <CustomModal
        size="large"
        title="Add New Item"
        onClose={closeModal}
        renderActions={false}
        showPrompt={showPrompt}
        customWidth="item-modal-width"
        showModal={values.showItemModal}
      >
        <ItemForm
          inModal
          type="add"
          onSubmitModal={(newItem) => {
            let ItemsIndex = values.items.length - 1;
            handleNewCreatedItem(newItem.data, ItemsIndex);
            setValues({
              ...values,
              showItemModal: false,
            });
          }}
          modalSource={false}
          closeModal={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
        />
      </CustomModal>
    );
  };

  const handlePrompt = (status = true) => setShowPrompt(status);

  const contactCreateModal = () => {
    modalflag = true;
    return (
      <CustomModal
        size="large"
        title="Add Contact"
        onClose={closeModal}
        renderActions={false}
        showPrompt={showPrompt}
        customWidth="contact-modal-width"
        showModal={status.showContactCreateModal}
      >
        <AddContact
          inModal
          close={closeModal}
          type="purchaseOrder"
          handleToast={handleToast}
          handlePrompt={handlePrompt}
          onSubmit={(res) => handleContactCreateSubmit(res.data)}
        />
      </CustomModal>
    );
  };

  React.useEffect(() => {
    if (parseInt(vendorIdTransaction))
      setFieldValue('vendorID', parseInt(vendorIdTransaction));
    return () => (flagOnce = 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [vendorName, setVendorName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showPendBillModal, setShowPendBillModal] = useState(false);

  const handleDetailModal = (id) => {
    setCustomerId(id);
    setShowPendBillModal(true);
  };

  const handleCustomerModal = (id) => {
    setCustomerId(id);
    setShowCustomerModal(true);
  };
  const contactDeatilModal = () => {
    return (
      <CustomModal
        size="large"
        title="View Contact"
        Icon={NewContactIcon}
        renderActions={false}
        showModal={showCustomerModal}
        className="contacts_modal_view"
        modaltitle="inventory_modal_title"
        onClose={() => setShowCustomerModal(false)}
        linkTo={`contacts/${customerId && customerId}`}
      >
        <Customer id={customerId && customerId} inModal={true} />
      </CustomModal>
    );
  };

  const PendBillDetailModal = () => {
    return (
      <CustomModal
        size="large"
        Icon={NewBillIcon}
        renderActions={false}
        className="view_pending_popup"
        showModal={showPendBillModal}
        modaltitle="inventory_modal_title"
        title={`View ${vendorName} Pending Bills`}
        onClose={() => setShowPendBillModal(false)}
      >
        <TableContent
          inModal={true}
          showdetail="Bills"
          currency={organizationCurrency.symbol}
          url={`purchaseorders/unpaid/${customerId}`}
        />
      </CustomModal>
    );
  };

  const handleCustomerChange = (option, setFieldValue, status, setStatus) => {
    if (option !== null) {
      // Add New
      if (option.value === 'createNew') {
        setStatus({ ...status, showContactCreateModal: true });
      } else {
        // customer selected
        const vendorID = option.value;
        setFieldValue('vendorID', vendorID);
        setVendorName(option.label);
        if (values.items[0].id !== null) {
          values.items.map((newItem, index) =>
            getPreviousSellingPrice(
              vendorID,
              newItem,
              status,
              setStatus,
              purchaseOrder
            ).then((updatedNewItem) => {
              values.items[index] = updatedNewItem;
              values.vendorID = vendorID;
              setValues({ ...values });
            })
          );
          // setFieldValue('vendorID', vendorID);
        }
      }
    } else {
      // removed selected customer
      setFieldValue('vendorID', null);
    }
  };

  const [texIndex, setTexIndex] = useState();
  const handleTaxChange = (option, index, currentItems, setFieldValue) => {
    let itemsList = [...currentItems];
    let currentItem = itemsList[index];
    setTexIndex(index);
    if (option === null) {
      itemsList[index] = {
        ...currentItem,
        tax: {
          id: null,
          name: '',
          rate: 0,
        },
        taxAmount: 0,
      };
    } else {
      if (option.value === 'createNew') {
        setFieldValue('showTaxModal', true);
        return;
      } else {
        // tax selected
        itemsList[index] = {
          ...currentItem,
          tax: {
            id: option.id,
            name: option.name,
            rate: option.rate,
          },
          taxAmount: getTaxAmount(
            currentItem.quantity,
            currentItem.unitPrice,
            option.rate
          ),
        };
      }
    }
    setFieldValue('items', itemsList);
  };
  const handleSelectedItem = async (
    option,
    items,
    index,
    setFieldValue,
    status,
    setStatus,
    vendorID = null,
    setValues,
    purchaseOrder
  ) => {
    if (option.value === 'createNew') {
      setFieldValue('showItemModal', true);
    } else {
      loadingSelectItem = true;
      let newItem = {
        id: option.id || option.value,
        dragDropID: GetDateString(),
        name: option.name
          ? option.name
          : option.label.props?.children[1].props?.children[0],
        sku: option.sku,
        ean: option.ean,
        mpn: option.mpn,
        upc: option.upc,
        isbn: option.isbn,
        unit: option.unit,
        images: option.images,
        stocks: option.stocks,
        previousPurchasePrice: null,
        quantity: items[index].quantity,
        unitPrice: option.purchase_unit_price,
        accountID: option.purchase_account_id,
        purchase_account_id: option.purchase_account_id,
        purchase_unit_price: option.purchase_unit_price,
        total: parseFloat(option.purchase_unit_price).toFixed(2),
        inventoryType: option.inventory_type === 'inventory' ? true : false,
        tax: {
          id: option.tax ? option.tax.id : null,
          name: option.tax ? option.tax.name : '',
          rate: option.tax ? option.tax.rate : 0,
        },
        taxAmount: option.tax
          ? getTaxAmount(1, option.purchase_unit_price, option.tax.rate)
          : 0,
      };

      let updatedNewItem = await getPreviousSellingPrice(
        vendorID,
        newItem,
        status,
        setStatus,
        purchaseOrder
      );
      // const updatedItems = [...Object.assign([...items], { [index]: newItem })]
      setFieldValue(`items[${index}]`, { ...updatedNewItem });
      loadingSelectItem = false;
    }
  };

  const taxValueFetchAutomatically = (newTax) => {
    let { values } = props;
    let itemList = [...values.items];
    let currentItem = itemList[texIndex];
    if (itemList) {
      itemList[texIndex] = {
        ...currentItem,
        tax: {
          id: newTax.id,
          name: newTax.name,
          rate: newTax.rate,
        },
        taxAmount: getTaxAmount(
          currentItem.quantity,
          currentItem.unitPrice,
          newTax.rate
        ),
      };
      setFieldValue('items', itemList);
    }
  };

  const newTaxModal = () => {
    return (
      <CustomModal
        title="Add New Tax"
        onClose={closeModal}
        renderActions={false}
        showPrompt={showPrompt}
        showModal={values.showTaxModal}
        classNames="UIUX-addtax-popup invoice-popup-set"
      >
        <TaxForm
          inModal
          type="add"
          onSubmitModal={(newTax) => {
            updateTaxList(newTax);
            setValues({
              ...values,
              tax: newTax.id,
              showTaxModal: false,
            });
          }}
          closeModal={closeModal}
          handleToast={handleToast}
          handlePrompt={handlePrompt}
          taxValueFetchAutomatically={taxValueFetchAutomatically}
        />
      </CustomModal>
    );
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(values.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue('items', items);
  };

  const handleArrowsKey = (e, index, fieldname) => {
    if (e.key === 'ArrowDown' && index + 1 < values?.items?.length) {
      const element = document.getElementById(
        `items[${index + 1}].${fieldname}`
      );
      setTimeout(() => {
        element.select();
        element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }, 100);
    }
  };

  const handleArrowUpKey = (e, index, fieldname) => {
    if (e.key === 'ArrowUp' && index - 1 >= 0) {
      const element = document.getElementById(
        `items[${index - 1}].${fieldname}`
      );
      element.select();
      element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  };

  const renderSelectedItemsTable = ({
    type,
    errors,
    status,
    touched,
    taxFromDB,
    setStatus,
    setValues,
    handleBlur,
    setFieldValue,
    accountsFromDB,
    stockPreference,
    setFieldTouched,
    organizationCurrency,
    values: { items, warehouseID, vendorID, objectId },
  }) => {
    const onChangeValueHandler = (val, index, currentItems) => {
      let newVal = handleExtraDescription(val, 254);
      let itemsList = [...currentItems];
      let currentItem = itemsList[index];
      itemsList[index] = {
        ...currentItem,
        extra_description: newVal,
      };

      setFieldValue('items', itemsList);
    };
    return (
      <FieldArray
        name="items"
        render={() => {
          return (
            <div className="so-tbletabs">
              <table
                style={{ width: '98%' }}
                className="dp-width list-table form-focus form-table border horizontal-items-table create_package create-po-package"
              >
                <thead>
                  <tr>
                    <th>Items</th>
                    <th>Account</th>
                    <th className="">Qty.</th>
                    <th className="">Price</th>
                    <th>Tax</th>
                    <th className="right-align">Amount</th>
                  </tr>
                </thead>
                <DragDropItems handleOnDragEnd={handleOnDragEnd}>
                  {items.map((item, index) => (
                    <Draggable
                      index={index}
                      key={item.dragDropID}
                      draggableId={item.dragDropID}
                    >
                      {(provided) => (
                        <tr
                          key={index}
                          className="table--row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <td className={item.id ? '' : 'no-padding'}>
                            <div className="__dragDrop">
                              <div
                                {...provided.dragHandleProps}
                                className={
                                  values.items.length >= 2
                                    ? '__dragDrop_iconParent'
                                    : '__dragDrop_iconParent notDisplay'
                                }
                                tabIndex="-1"
                              >
                                <MdDragIndicator />
                              </div>
                              <div className="__drag-righ">
                                {item.id ? (
                                  <ListTableImageColumn
                                    id={item.id}
                                    index={index}
                                    sku={item.sku}
                                    name={item.name}
                                    images={item.images}
                                    currentitems={items}
                                    showModalUponClick={true}
                                    showExtraDescription={true}
                                    value={item.extra_description}
                                    onChangeValue={onChangeValueHandler}
                                  />
                                ) : (
                                  <Field
                                    id="itemId"
                                    size="large"
                                    value={item.id}
                                    autoFocus={true}
                                    addButton={true}
                                    withImage={true}
                                    disabled={!vendorID}
                                    api="purchaseorders/items"
                                    type="DynamicPaginationDropdown"
                                    placeholder={
                                      vendorID
                                        ? 'Select Item'
                                        : 'Please select Vendor'
                                    }
                                    alphabetSort
                                    searchKeys={[
                                      'name',
                                      'sku',
                                      'upc',
                                      'mpn',
                                      'ean',
                                      'isbn',
                                    ]}
                                    clearable={false}
                                    itemsDropDown={true}
                                    handleChange={(option) =>
                                      handleSelectedItem(
                                        option,
                                        items,
                                        index,
                                        setFieldValue,
                                        status,
                                        setStatus,
                                        vendorID,
                                        setValues,
                                        objectId
                                      )
                                    }
                                    error={getError(
                                      errors,
                                      touched,
                                      `items[${index}].name`
                                    )}
                                    onBlur={() =>
                                      setFieldTouched(
                                        `items[${index}].name`,
                                        true
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="no-padding">
                            <Field
                              size="medium"
                              type="dropdown"
                              dropdownValue="id"
                              dropdownLabel="title"
                              value={item.accountID}
                              placeholder="Select Account"
                              options={
                                skipButton
                                  ? accountsFromDB?.expense
                                  : item.inventoryType
                                  ? accountsFromDB?.inventory
                                  : accountsFromDB?.expense
                              }
                              handleChange={(option) =>
                                handleChangeAccount(
                                  option,
                                  items,
                                  index,
                                  setFieldValue
                                )
                              }
                              error={getError(
                                errors,
                                touched,
                                `items[${index}].accountID`
                              )}
                              onBlur={handleBlur}
                              clearable={false}
                            />
                          </td>

                          <td className="no-padding">
                            <Field
                              size="small"
                              type="number"
                              decimalScale={6}
                              value={item.quantity}
                              allowNegative={false}
                              thousandSeparator=","
                              className="adjust_width"
                              id={`items[${index}].quantity`}
                              onValueChange={(value) =>
                                handleItemQuantityChange(
                                  value,
                                  index,
                                  items,
                                  setFieldValue
                                )
                              }
                              error={getError(
                                errors,
                                touched,
                                `items[${index}].quantity`
                              )}
                              disabled={
                                type === 'dropship' || type === 'backorder'
                              }
                              onBlur={handleBlur}
                              onKeyDown={(e) =>
                                handleArrowsKey(e, index, 'quantity')
                              }
                              onKeyUp={(e) =>
                                handleArrowUpKey(e, index, 'quantity')
                              }
                            />
                            {!!warehouseID &&
                              type !== 'dropship' &&
                              type !== 'backorder' && (
                                <div className="table-extra-data">
                                  <div
                                    style={{
                                      marginTop: '1.3rem',
                                    }}
                                  >
                                    {/* Avl.:{' '} */}
                                    {availableItem(
                                      item.inventoryType,
                                      item.stocks,
                                      warehouseID,
                                      stockPreference,
                                      item.unit
                                    )}{' '}
                                    {/* {item.unit} */}
                                  </div>
                                </div>
                              )}
                          </td>

                          <td className="right-align no-padding">
                            <Field
                              size="small"
                              type="number"
                              decimalScale={6}
                              thousandSeparator=","
                              allowNegative={false}
                              value={item.unitPrice}
                              className="adjust_width"
                              id={`items[${index}].unitPrice`}
                              prefix={organizationCurrency.symbol}
                              onValueChange={(value) =>
                                handleItemPriceChange(
                                  value,
                                  index,
                                  items,
                                  setFieldValue
                                )
                              }
                              error={getError(
                                errors,
                                touched,
                                `items[${index}].unitPrice`
                              )}
                              onBlur={handleBlur}
                              onKeyDown={(e) =>
                                handleArrowsKey(e, index, 'unitPrice')
                              }
                              onKeyUp={(e) =>
                                handleArrowUpKey(e, index, 'unitPrice')
                              }
                            />
                            {!!item.previousPurchasePrice && (
                              <ToolTip
                                content="Click to set this price"
                                className="previous_sold-po po-prev-toopltip"
                              >
                                <div className="table-extra-data right-align">
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      handleChangeprevPrice(
                                        items,
                                        setFieldValue,
                                        item?.previousPurchasePrice,
                                        index
                                      )
                                    }
                                  >
                                    <span className="po-prev-sold">{`Prev Purchase: ${organizationCurrency.symbol}${item.previousPurchasePrice}`}</span>
                                  </div>
                                </div>
                              </ToolTip>
                            )}
                          </td>
                          <td className="no-padding">
                            <Field
                              size="small"
                              type="dropdown"
                              dropdownValue="id"
                              options={taxFromDB}
                              dropdownLabel="name"
                              value={item?.tax?.id}
                              placeholder="Select Tax"
                              handleChange={(option) =>
                                handleTaxChange(
                                  option,
                                  index,
                                  items,
                                  setFieldValue
                                )
                              }
                              addButton={true}
                            />
                          </td>
                          <td
                            style={{ textAlign: 'right' }}
                            className="list-table--amount right-align"
                          >
                            <span>
                              {organizationCurrency.symbol}
                              {parseFloat(
                                getItemTotalAmount(
                                  item.quantity,
                                  item.unitPrice
                                )
                              ).toFixed(2)}
                            </span>
                            {type !== 'backorder' && type !== 'dropship' && (
                              <button
                                type="button"
                                disabled={
                                  items.length === 1 && items[0].id === null
                                }
                                className="close-btn button"
                                onClick={() =>
                                  handleRemoveItem(
                                    index,
                                    items,
                                    setFieldValue,
                                    status,
                                    setStatus,
                                    item
                                  )
                                }
                              >
                                <DeleteIcon />
                              </button>
                            )}
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </DragDropItems>
              </table>
            </div>
          );
        }}
      />
    );
  };
  const checkTotalValueForZero = () => {
    return (
      <CustomModal
        renderActions={false}
        showModal={cofirmState}
        style={{ marginTop: '5%' }}
        title="Confirm Purchase Order"
        onClose={() => handleTotalCheckFalse(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <p>
            Your total purchase order amount is Zero. Please confirm to
            continue.
          </p>

          <button
            type="button"
            className="action-buttons--submit next-btn"
            onClick={() => {
              handleTotalCheckFalse(false);
              totalConfirmCheck = true;
              handleSubmit();
            }}
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    );
  };

  return (
    <>
      {newTaxModal()}
      {newItemModal()}
      {contactCreateModal()}
      {contactDeatilModal()}
      {PendBillDetailModal()}
      {checkTotalValueForZero()}
      <Form
        className="purchaseorder-form"
        onSubmit={(event) =>
          formSubmit(event, values.items, setFieldValue, handleSubmit)
        }
      >
        <FormSection title={title}>
          <div className="form-fields-container d-flex flex-wrap po-fields-container position-relative">
            <div className="field-container invoice-fields-container position-relative flex-25 form-field">
              <Field
                required
                name="Vendor"
                id="vendorID"
                alphabetSort
                autoFocus={true}
                addButton={true}
                onBlur={handleBlur}
                newCust={currentVendor}
                value={values.vendorID}
                placeholder="Select Vendor"
                editCust={values.contactObj}
                api="purchaseorders/contact"
                type="DynamicPaginationDropdown"
                error={touched.vendorID && errors.vendorID}
                handleChange={(option) =>
                  handleCustomerChange(option, setFieldValue, status, setStatus)
                }
              />
              {values.vendorID && (
                <div className="details-sec text-left d-flex space-between">
                  <div className="details-sec text-left">
                    <button
                      type="button"
                      className="view-details-btn-1 pt-8"
                      onClick={() => handleDetailModal(values.vendorID)}
                    >
                      {' '}
                      Pending Bills
                    </button>
                  </div>

                  <div className="details-sec text-right">
                    <button
                      type="button"
                      className="view-details-btn-1 pt-8"
                      onClick={() => handleCustomerModal(values.vendorID)}
                    >
                      {' '}
                      View Details
                    </button>
                  </div>
                </div>
              )}
            </div>
            <Field
              required
              disabled
              onBlur={handleBlur}
              className="flex-25"
              id="purchaseOrderNo"
              name="Purchase Order No."
              handleChange={handleChange}
              value={values.purchaseOrderNo}
              placeholder="Purchase Order No."
              error={touched.purchaseOrderNo && errors.purchaseOrderNo}
            />

            <Field
              required
              type="date"
              name="Order Date"
              id="purchaseDate"
              className="flex-25"
              value={
                typeof values.purchaseDate == 'string'
                  ? moment(values.purchaseDate)
                  : values.purchaseDate
              }
              handleChange={(date) => {
                if (!date) return;
                setFieldValue(
                  'purchaseDate',
                  date && date.format('YYYY-MM-DD 00:00:00')
                );
                if (values.deliveryDate < date.format('YYYY-MM-DD 00:00:00')) {
                  setFieldValue(
                    'purchaseDate',
                    date && date.format('YYYY-MM-DD 00:00:00')
                  );
                  if (
                    values.deliveryDate < date.format('YYYY-MM-DD 00:00:00')
                  ) {
                    setFieldValue(
                      'deliveryDate',
                      date.format('YYYY-MM-DD 00:00:00')
                    );
                  }
                }
              }}
              error={touched.purchaseDate && errors.purchaseDate}
              onBlur={() => setFieldTouched('purchaseDate', true)}
            />

            <Field
              type="date"
              id="deliveryDate"
              name="Delivery Date"
              className="flex-25 date-canalder-g"
              value={
                typeof values.deliveryDate == 'string'
                  ? moment(values.deliveryDate)
                  : values.deliveryDate
              }
              minDate={
                typeof values.purchaseDate == 'string'
                  ? moment(values.purchaseDate)
                  : values.purchaseDate
              }
              handleChange={(date) => {
                setFieldValue(
                  'deliveryDate',
                  date && date.format('YYYY-MM-DD 00:00:00')
                );
              }}
              onBlur={() => setFieldTouched('deliveryDate', true)}
              required
              error={touched.deliveryDate && errors.deliveryDate}
            />

            {/* <div className="po-term-set w-100 d-flex flex-wrap"> */}
            {title === 'Add Dropship' && (
              <>
                <div className="dropship-main">
                  <p>Shipping Address</p>
                  <div className="lower_so_address">
                    <div>
                      {!!shippingAddress?.attention &&
                        `${shippingAddress?.attention},`}
                    </div>
                    <div>
                      {!!shippingAddress?.street &&
                        `${shippingAddress?.street},`}
                    </div>
                    <div>
                      {!!shippingAddress?.city && `${shippingAddress?.city},`}{' '}
                      {!!shippingAddress?.state && `${shippingAddress?.state} `}{' '}
                      {!!shippingAddress?.zip_code &&
                        `${shippingAddress?.zip_code},`}{' '}
                      {shippingAddress?.country_name}
                    </div>

                    {!!shippingAddress?.phone && (
                      <div>Phone: {shippingAddress?.phone}</div>
                    )}
                    {!!shippingAddress?.fax && (
                      <div>Fax: {shippingAddress?.fax}</div>
                    )}
                  </div>
                </div>
              </>
            )}
            <Field
              id="reference"
              name="Reference"
              onBlur={handleBlur}
              value={values.reference}
              handleChange={handleChange}
              error={touched.reference && errors.reference}
              className="flex-25 position-relative dropship-reference"
            />
            {type !== 'dropship' && (
              <Field
                required
                size="large"
                type="dropdown"
                name="Warehouse"
                id="warehouseID"
                dropdownValue="id"
                className="flex-25"
                onBlur={handleBlur}
                dropdownLabel="name"
                options={warehouseFromDB}
                value={values.warehouseID}
                disabled={type === 'backorder'}
                error={touched.warehouseID && errors.warehouseID}
                handleChange={(option) =>
                  handleWarehouseChange(option, setFieldValue)
                }
              />
            )}
            {renderTermsAndConditions()}
          </div>
          {/* </div> */}
          <div className="form-field form-table--container mr-0">
            <label className="form-label">Select Items</label>
            {loadingSelectItem && <Loader />}
            {renderSelectedItemsTable(props)}
            {renderAddItem()}
            {renderFooter(props)}
          </div>

          <FormAction
            marginFromRight={0}
            className="Po-actions"
            disabled={isSubmitting}
            onSubmit={handleSubmit}
            saveBtnFlag={values.status === 'issued'}
            onCancel={() => {
              let dataFromLS = getObjFromLS('module');
              let redirectUrl = '';
              if (dataFromLS && dataFromLS.moduleName === 'purchaseorders') {
                redirectUrl = dataFromLS.queryParam;
              }
              if (dataFromLS.moduleName === 'contacts') {
                history.push('/contacts');
                return;
              }
              if (
                window.location.pathname.includes('add') ||
                window.location.pathname.includes('edit')
              ) {
                history.push('/purchaseorders' + redirectUrl);
              } else {
                setObjInLS('module', {
                  ...dataFromLS,
                  action: null,
                });
                history.push('/r');
              }
            }}
            isSalesOrder={true}
            dropUpSO={
              values.status === 'issued'
                ? [
                    {
                      label: 'Save & Send',
                      onClick: (event) => {
                        setFieldValue('saveAndSend', true);
                        formSubmit(
                          event,
                          values.items,
                          setFieldValue,
                          handleSubmit
                        );
                      },
                      disabled: isSubmitting,
                    },
                  ]
                : [
                    {
                      label: 'Save & Issue',
                      onClick: (event) => {
                        setFieldValue('saveAndIssue', true);
                        formSubmit(
                          event,
                          values.items,
                          setFieldValue,
                          handleSubmit
                        );
                      },
                      disabled: isSubmitting,
                    },
                    {
                      label: 'Save & Send',
                      disabled: isSubmitting,
                      onClick: (event) => {
                        let dataFromLS = getObjFromLS('module');
                        setObjInLS('module', {
                          ...dataFromLS,
                          urlPath: '/purchaseorders',
                          moduleName: 'purchaseorders',
                          action: type === 'add' ? 'add' : 'justedit',
                          queryParam: '',
                        });
                        setFieldValue('saveAndSend', true);
                        formSubmit(
                          event,
                          values.items,
                          setFieldValue,
                          handleSubmit
                        );
                      },
                    },
                  ]
            }
          />

          <ErrorFocus />
        </FormSection>
      </Form>
    </>
  );
};

const renderFooter = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  organizationCurrency,
}) => {
  let currencySymbol = organizationCurrency.symbol;

  var currentLocation = window.location;
  const handleAdjustmentChange = (adjustmentValue) => {
    let adjustment = adjustmentValue.floatValue;
    if (adjustment > 0) {
      let adjustmentString = adjustment.toString();

      // Price_Quantity_Limit

      // price and quantity limit from 5 digits to 10 digits
      if (adjustmentString.length > 10) {
        adjustmentString = adjustmentString.slice(0, 10);
      }
      adjustment = parseFloat(adjustmentString);
    }
    setFieldValue('adjustment', adjustment);
  };
  if (currentLocation.pathname.includes('add')) {
    if (flagOnce === 0) {
      values.adjustment = 0;
      flagOnce = 1;
    }
  }
  return (
    <div className="float-left mt_3 summary_table" style={{ width: '100%' }}>
      <hr className="margin float-left w-100" />
      <div className="float-left w-60 p_10"></div>
      <div className="float-left w-40 p_10 pr-0">
        <div className="float-left w-100 so-mg-1">
          <span className="float-left">Sub Total </span>
          <span className="float-right bold">{`${currencySymbol}${parseFloat(
            getSubtotal(values.items)
          ).toFixed(2)}`}</span>
        </div>
        <div className="float-left w-100">
          {renderTotalTax(values.items, organizationCurrency)}
        </div>
        <div className="float-left w-100">
          {renderTransactionLevelDiscount(
            values,
            organizationCurrency,
            setFieldValue,
            errors,
            touched,
            handleBlur
          )}
        </div>
        <div className="float-left w-100">
          <span className="float-left pr">Adjustment</span>
          <Field
            type="number"
            size="small"
            allowNegative
            id="adjustment"
            decimalScale={2}
            thousandSeparator=","
            removeValueOnClick
            onBlur={handleBlur}
            prefix={currencySymbol}
            placeholder={`${currencySymbol}0.00`}
            error={touched.adjustment && errors.adjustment}
            value={parseFloat(values.adjustment).toFixed(2)}
            onValueChange={(value) => handleAdjustmentChange(value)}
            className="float-right bold discount_switch_summary_table"
          />
        </div>
      </div>

      {/* <hr className='margin' /> */}

      <hr className="margin float-left w-100" />

      {/* <div className="baseline">
        <span className="semi-bold">Total</span>
        <span className="total-price--value">{`${currencySymbol}${formatNumber(getOrderTotal(values))}`}</span>
      </div> */}
      <div className="float-left w-100">
        <div className="float-left w-60 p_10"></div>
        <div className="baseline float-left w-40 px_10">
          <span className="semi-bold float-left pr_total">Total</span>
          <span className="total-price--value float-right">{`${currencySymbol}${parseFloat(
            getOrderTotal(values)
          ).toFixed(2)}`}</span>
        </div>
      </div>
      <hr className="margin float-left w-100" />
      <hr className="margin float-left w-100" />
    </div>
  );
};

const renderTotalTax = (items, organizationCurrency) => {
  let currencySymbol = organizationCurrency.symbol;
  let overallTax = getOverallTaxDetails(items);
  return overallTax.map((tax) => (
    <>
      {tax ? (
        <div className="w-100 float-left" key={tax.id}>
          <span className="semi-bold float-left">{`${tax.name} (${tax.rate}%)`}</span>
          <span className="float-right">{`${currencySymbol}${parseFloat(
            tax.total
          ).toFixed(2)}`}</span>
        </div>
      ) : null}
    </>
  ));
};

const renderTransactionLevelDiscount = (
  { transactionDiscountType, transactionDiscount },
  organizationCurrency,
  setFieldValue,
  errors,
  touched,
  handleBlur
) => {
  const handleItemDiscountChange = (value, discountType) => {
    let discount = value.floatValue;
    if (discount > 0) {
      let discountString = discount.toString();
      if (discountType === 'percent') {
        let sliceStrLen = discountString.includes('.') ? 5 : 3;
        if (discountString.length > sliceStrLen) {
          discountString = discountString.slice(0, sliceStrLen);
        }
        discount = parseFloat(discountString);
      } else {
        if (discountString.length > 10) {
          discountString = discountString.slice(0, 10);
        }
        discount = parseFloat(discountString);
      }
    }
    setFieldValue('transactionDiscount', discount);
  };

  return (
    <div className="float-left w-100">
      <span className="float-left pr">Discount</span>
      <Field
        type="number"
        decimalScale={2}
        id="transactionDiscount"
        value={parseFloat(transactionDiscount).toFixed(2)}
        className="float-right bold discount_switch_summary_table"
        rightData={
          <Switch
            options={getDiscountOptions(organizationCurrency)}
            value={transactionDiscountType}
            handleChange={(value) => {
              setFieldValue('transactionDiscountType', value.value);
              if (transactionDiscountType === 'percent') {
                setFieldValue('transaction_discount_type', true);
              } else {
                setFieldValue('transaction_discount_type', false);
              }
            }}
          />
        }
        removeValueOnClick
        onBlur={handleBlur}
        allowNegative={false}
        thousandSeparator=","
        error={touched.transactionDiscount && errors.transactionDiscount}
        onValueChange={(value) =>
          handleItemDiscountChange(value, transactionDiscountType)
        }
      />
    </div>
  );
};

const prepareRequestData = (values, type) => {
  const {
    items,
    vendorID,
    reference,
    adjustment,
    warehouseID,
    purchaseDate,
    deliveryDate,
    purchaseOrderNo,
    transactionDiscount,
    transactionDiscountType,
  } = values;

  const preparedItems = items.map((item, index) => {
    const preparedItem = {
      id: item.id,
      sort_order: index,
      tax_id: item?.tax?.id,
      rate: item.unitPrice || 0,
      account_id: item.accountID,
      quantity: item.quantity || 0,
      purchase_order_item_detail_id: item.purchaseOrderItemDetailId,
      extra_description: item.extra_description ? item.extra_description : '',
    };

    if (['backorder', 'dropship'].includes(type)) {
      preparedItem.sales_order_item_detail_id = item.salesOrderItemDetailId;
    }

    return preparedItem;
  });

  return {
    vendor_id: vendorID,
    reference: reference,
    items: preparedItems,
    adjustment: adjustment,
    purchase_order_no: purchaseOrderNo,
    clone_status: type === 'clone' ? 1 : 0,
    discount_type: transactionDiscountType,
    order_date: purchaseDate && purchaseDate,
    issue: values.saveAndIssue ? true : false,
    sendEmail: values.saveAndSend ? true : false,
    warehouse_id: warehouseID ? warehouseID : undefined,
    discount_transaction_level: transactionDiscount || 0,
    expected_delivery_date: deliveryDate && deliveryDate,
    type: ['backorder', 'dropship'].includes(type) ? type : null,
    terms_and_condition: values.hasTermsAndConditions
      ? values.termsAndConditions
      : '',
  };
};

const handleFormSubmit = (
  values,
  {
    setSubmitting,
    props: {
      type,
      history,
      setPrompt,
      requestUrl,
      requestType,
      handleToast,
      successMessage,
      warehouseFromDB,
      handleTotalCheckTrue,
      handleTotalCheckFalse,
    },
  }
) => {
  const total = getOrderTotal(values);
  if (total === 0 && !totalConfirmCheck) {
    handleTotalCheckTrue(true);
    setSubmitting(false);
    return;
  }
  if (getOrderTotal(values) >= MAX_NUMBER) {
    // Price_Quantity_Limit
    // handleToast('Total must be less than Ten Billion.', 'error');
    // setSubmitting(false);
    // return;
  }

  let inActiveWarehouse = warehouseFromDB.some((list) =>
    list.is_active === 0 && list.id === values.warehouseID ? true : false
  );
  if (inActiveWarehouse === true) {
    setSubmitting(false);
    return handleToast('Cannot use inactive warehouse', 'error');
  }
  restRequest(requestType, requestUrl, prepareRequestData(values, type))
    .then((res) => {
      setPrompt(false);
      successMessage();
      totalConfirmCheck = false;
      handleTotalCheckFalse(false);
      if (values.saveAndSend) {
        if (
          type === 'add' ||
          type === 'clone' ||
          type === 'dropship' ||
          type === 'backorder'
        ) {
          let dataFromLS = getObjFromLS('module');

          dataFromLS = {
            ...dataFromLS,
            id: res.data.id,
          };
          setObjInLS('module', dataFromLS);
          history.push(`/purchaseorders/email/${res.data.id}`);
        } else {
          var emailUrl = requestUrl.split('/');
          history.push(`/purchaseorders/email/${emailUrl[1]}`);
        }
      } else {
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            id: null,
            prevId: null,
            nextId: null,
            moduleName: 'purchaseorders',
            urlPath: '/purchaseorders',
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
          history.push('/r');
        }
        if (dataFromLS.back_drop) {
          setObjInLS('module', {
            ...dataFromLS,
            queryParam: '',
            back_drop: null,
            id: dataFromLS.back_drop.id,
            urlPath: dataFromLS.back_drop ? dataFromLS.back_drop.url : '',
          });
          history.push('/r');
          modalflag
            ? sessionStorage.setItem('fullscreen', false)
            : sessionStorage.setItem('fullscreen', false);
        } else {
          if (!window.location.pathname.includes('add')) {
            type === 'clone' &&
              setObjInLS('module', {
                ...dataFromLS,
                action: 'add',
                queryParam: '',
                id: res.data.id,
                urlPath: '/purchaseorders',
                moduleName: 'purchaseorders',
              });
            type === 'edit' &&
              setObjInLS('module', {
                ...dataFromLS,
                urlPath: '/purchaseorders',
                moduleName: 'purchaseorders',
                queryParam: dataFromLS.queryParam,
                action: type === 'add' ? 'add' : 'justedit',
              });
            history.push('/r');
            modalflag
              ? sessionStorage.setItem('fullscreen', false)
              : sessionStorage.setItem('fullscreen', false);
          } else {
            setObjInLS('module', {
              ...dataFromLS,
              queryParam: '',
              id: res.data.id,
              urlPath: '/purchaseorders',
              moduleName: 'purchaseorders',
              action: type === 'add' ? 'add' : 'justedit',
            });
            history.push('/r');
            modalflag
              ? sessionStorage.setItem('fullscreen', false)
              : sessionStorage.setItem('fullscreen', false);
          }
        }
      }
      // history.push('/purchaseorders')
    })
    .catch((error) => {
      setSubmitting(false);
      checkError(error);
      handleToast(error, 'error');
    });
};

const handleRemoveItem = (
  removeIndex,
  items,
  setFieldValue,
  status,
  setStatus,
  option
) => {
  items.length === 1 && items.push(emptyItem);
  let updatedItems = items.filter((_, index) => index !== removeIndex);
  Object.assign(option, { purchase_account_id: option.accountID });
  setFieldValue('items', updatedItems);
  if (
    option.id != null &&
    !status.itemsFromDB.find((item) => item.sku === option.sku)
  ) {
    setStatus({
      ...status,
      itemsFromDB: [...status.itemsFromDB, option],
    });
  }
};

const availableItem = (
  inventoryType,
  stocks,
  warehouseID,
  stockPreference,
  unit
) => {
  let warehouseStock = stocks.find(
    (stock) => stock.warehouse_id === warehouseID
  );
  if (inventoryType) {
    return 'un-tracked';
  } else if (!!warehouseStock && stockPreference === 'physical') {
    return `Avl: ${warehouseStock.physical_quantity} ${unit}`;
  } else if (!!warehouseStock && stockPreference === 'accounting') {
    return `Avl: ${warehouseStock.accounting_quantity} ${unit}`;
  } else return `Avl: 0 ${unit}`;
};

export const getTaxAmount = (quantity, unitPrice, rate) => {
  return parseFloat(((quantity * unitPrice * rate) / 100).toFixed(2));
};

export const getItemTotalAmount = (quantity, unitPrice) => {
  let total = quantity * unitPrice;
  return parseFloat(total.toFixed(2)) || 0;
};

const getSubtotal = (itemsList) => {
  return parseFloat(
    itemsList
      .reduce(
        (total, item) =>
          total + getItemTotalAmount(item.quantity, item.unitPrice),
        0
      )
      .toFixed(2)
  );
};

const getTotalTax = (items) => {
  let overallTax = getOverallTaxDetails(items);
  return overallTax.reduce((total, tax) => {
    return total + tax.total;
  }, 0);
};

const getOrderTotal = ({
  items,
  adjustment = 0,
  transactionDiscountType,
  transactionDiscount = 0,
}) => {
  let subTotal = getSubtotal(items);
  let taxTotal = getTotalTax(items);

  if (transactionDiscountType === 'percent') {
    let totalTransactionDiscount = (subTotal * transactionDiscount) / 100;
    return parseFloat(
      (subTotal + taxTotal + +adjustment - totalTransactionDiscount).toFixed(2)
    );
  } else if (transactionDiscountType === 'amount') {
    return parseFloat(
      (subTotal + taxTotal + +adjustment - transactionDiscount).toFixed(2)
    );
  } else return parseFloat((subTotal + taxTotal + +adjustment).toFixed(2));
};

const handleChangeprevPrice = (items, setFieldValue, prevPrice, index) => {
  let itemList = [...items];
  let currentItem = itemList[index];
  itemList[index] = {
    ...currentItem,
    unitPrice: prevPrice,
  };
  setFieldValue('items', itemList);
};

const handleItemPriceChange = (values, index, currentItems, setFieldValue) => {
  let unitPrice = values.floatValue;
  if (unitPrice > 0) {
    let unitPriceString = unitPrice.toString();

    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    if (unitPriceString.length > 10) {
      unitPriceString = unitPriceString.slice(0, 10);
    }
    unitPrice = parseFloat(unitPriceString);
  }
  let itemsList = [...currentItems];
  let currentItem = itemsList[index];
  itemsList[index] = {
    ...currentItem,
    unitPrice,
    taxAmount: !!currentItem?.tax?.id
      ? getTaxAmount(currentItem.quantity, unitPrice, currentItem.tax.rate)
      : 0,
    total: parseFloat(
      getItemTotalAmount(currentItem.quantity, unitPrice)
    ).toFixed(2),
  };
  setFieldValue('items', itemsList);
};

const handleItemQuantityChange = (
  values,
  index,
  currentItems,
  setFieldValue
) => {
  let quantity = values.floatValue;
  if (quantity > 0) {
    let quantString = quantity.toString();

    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    if (quantString.length > 10) {
      quantString = quantString.slice(0, 10);
    }
    quantity = parseFloat(quantString);
  }
  let itemsList = [...currentItems];
  let currentItem = itemsList[index];
  itemsList[index] = {
    ...currentItem,
    quantity,
    taxAmount: !!currentItem?.tax?.id
      ? getTaxAmount(quantity, currentItem.unitPrice, currentItem?.tax?.rate)
      : 0,
    total: parseFloat(
      getItemTotalAmount(quantity, currentItem.unitPrice)
    ).toFixed(2),
  };

  setFieldValue('items', itemsList);
};

const getPreviousSellingPrice = async (
  contactId,
  item,
  status,
  setStatus,
  purchaseOrder
) => {
  // return item
  if (!contactId) {
    return item;
  } else {
    let updatedItem = { ...item };
    setStatus({
      ...status,
      loadingItems: true,
    });

    let POPURL = null;
    if (purchaseOrder !== null) {
      POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${contactId}&item_id=${item.id}&object=purchaseOrder&object_id=${purchaseOrder}`;
    } else {
      POPURL = `purchaseorders/previouspurchaseprice?vendor_id=${contactId}&item_id=${item.id}`;
    }

    await restRequest('get', POPURL).then((previousPrice) => {
      updatedItem = {
        ...updatedItem,
        previousPurchasePrice: previousPrice,
      };
      setStatus({
        ...status,
        loadingItems: false,
      });
    });
    return updatedItem;
  }
};

const handleChangeAccount = (option, items, index, setFieldValue) => {
  let accountID = option.value;
  setFieldValue('items', [
    ...Object.assign([...items], {
      [index]: {
        ...items[index],
        accountID,
      },
    }),
  ]);
};

const handleWarehouseChange = (option, setFieldValue) => {
  let warehouseID = option ? option.id : null;
  setFieldValue('warehouseID', warehouseID);
};

export default formEnhancer(POForm);
