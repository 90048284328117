import React, { useEffect, useState } from 'react';
import { checkError, getObjFromLS, restRequest } from '../../../Helpers';
import { Field, Form, FormAction } from '../../../Components/Form';
import { CustomModal, Loader } from '../../../Components';
import { withFormik } from 'formik';

const handleFormSubmit = async (
  values,
  { setSubmitting, props: { handleToast, history, setIsLoading } }
) => {
  setIsLoading(true);
  const value = { value: { ...values } };
  await restRequest('put', 'old_accounts/update', value)
    .then((res) => {
      if (res) {
        handleToast('All accounts has been updated', 'success');
        localStorage.setItem('skipBtnoldAccount', JSON.parse(false));
        window.location.href = '/dashboard';
      }
    })
    .catch((error) => {
      if (error) {
        setSubmitting(false);
        checkError(error);
        handleToast(error, 'error');
        setIsLoading(false);
      }
    });
};
const MapOldAccount = (props) => {
  const [data, setData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  let permissions = getObjFromLS('role').permissions;
  const {
    values,
    touched,
    errors,
    history,
    isLoading,
    handleSubmit,
    setIsLoading,
    setFieldValue,
    setFieldTouched,
  } = props;

  useEffect(() => {
    document.title = 'Map Old Accounts';
    fetchAccounts();
  }, []);
  const fetchAccounts = () => {
    setIsLoading(true);
    restRequest('get', 'old_accounts').then((res) => {
      setData({ ...res.data });
      setIsLoading(false);
    });
  };
  useEffect(() => {
    if (data?.accounts?.length > 0) {
      data.accounts.forEach((oldAcc) => {
        setFieldValue(oldAcc?.id, oldAcc.selected_id ? oldAcc.selected_id : '');
      });
    }
  }, [data, setFieldValue]);
  const onSkip = async () => {
    setIsLoading(true);
    await restRequest(
      'post',
      `skip-mapping/${localStorage.getItem('organization')}`
    ).catch(() => setIsLoading(false));
    localStorage.setItem('skipBtnoldAccount', JSON.parse(true));
    history.push('/dashboard');
  };

  const closeAllModals = () => {
    setIsModalOpen(false);
  };
  const onSave = (event) => {
    event.preventDefault();
    const formErrors = props.errors;
    if (Object.keys(formErrors).length > 0) {
      Object.keys(formErrors).forEach((key) => {
        setFieldTouched(key, true);
      });
      return;
    }
    setIsModalOpen(true);
  };

  const confirmSave = () => {
    handleSubmit();
    closeAllModals();
  };
  return (
    <div style={{ margin: 'auto', width: '400px', marginTop: '20px' }}>
      <>
        {!isLoading && data?.accounts?.length > 0 ? (
          <>
            {!permissions.superAccess && !data?.skip_mapping ? (
              <h2>
                Currently, this organization is not available for any
                transactions contact to business owner for more information.
              </h2>
            ) : (
              <Form onSubmit={onSave}>
                <h2>Mapping Old Accounts</h2>
                {data?.accounts?.map((oldAcc, i) => (
                  <Field
                    key={i}
                    required
                    size="large"
                    type="dropdown"
                    id={oldAcc?.id}
                    clearable={false}
                    dropdownValue="id"
                    dropdownLabel="title"
                    name={oldAcc?.title}
                    value={values[oldAcc?.id]}
                    className="reg_timezone_field"
                    options={data?.account_options || []}
                    placeholder={`Select ${oldAcc.label}`}
                    handleChange={(option) => {
                      setFieldValue(oldAcc?.id, option ? option.value : null);
                    }}
                    error={
                      touched[oldAcc?.id] &&
                      errors[oldAcc?.id] && (
                        <div style={{ color: 'red', marginTop: '5px' }}>
                          {errors[oldAcc?.title]}
                        </div>
                      )
                    }
                  />
                ))}

                <FormAction
                  disabled={false}
                  onCancel={onSkip}
                  cancelLabel="Skip"
                  showCancel={data.show_skip_button}
                  className="clearfix"
                />
              </Form>
            )}
          </>
        ) : (
          <Loader />
        )}

        <CustomModal
          showModal={isModalOpen}
          renderActions={false}
          onClose={closeAllModals}
        >
          <p>Are you sure you want to save the changes? </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <button
              type="button"
              className="action-buttons--submit next-btn"
              onClick={closeAllModals}
              style={{ marginRight: '10px' }}
            >
              cancel
            </button>
            <button
              type="button"
              className="action-buttons--submit next-btn"
              onClick={confirmSave}
            >
              Confirm
            </button>
          </div>
        </CustomModal>
      </>
    </div>
  );
};
const formEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const initialValues = {};
    if (props.accounts && props.accounts.length > 0) {
      props.accounts.forEach((account) => {
        initialValues[account.id] = account.selected_id || '';
      });
    }
    return initialValues;
  },
  validate: (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        errors[key] = `Select an option for ${key}`;
      }
    });
    return errors;
  },
  handleSubmit: (values, bag) => {
    const filteredData = Object.keys(values)
      .filter(
        (key) => typeof values[key] !== 'object' && values[key] !== undefined
      )
      .reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});
    handleFormSubmit(filteredData, bag);
  },
})(MapOldAccount);
export default formEnhancer;
