import React, { Component, Fragment } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import HeaderWrapper from '../../Components/HeaderWrapper/HeaderWrapper';
// import styles from './style.module.css';
import { setObjInLS, getObjFromLS } from '../../Helpers/LocalStorage';
import {
  // SalesOrderIcon,
  restRequest,
  ListTable,
  checkError,
  Field,
  CustomModal,
  getDateFormat,
} from './';
import { SalesOrderIcon } from '../../Assets/Navigation/NavigationIcons';
import { Link } from 'react-router-dom';
import UnSycnItemsMapping from './Mapping';
import { MdClose } from '../../Common/Icons';
import '../../../src/Pages/Integrations/Integration.css';
import './UnsyncOrdersList.css';
class UnSyncOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesOrderId: null,
      loading: true,
      data: [],
      dataExpanded: [],
      allData: [],
      storeId: null,
      stores: [],
      totalPage: 1,
      currentPage: 1,
      viewPerPage: 20,
      totalRecords: 20,
      currentFilter: 'all',
      orderBy: 'asc',
      sortFilter: 'created_at',
      preference: {},
      search: '',
      accessDenied: false,
      unSyncOrderId: 0,
      slug: 'woocom',
    };
    this.getID = this.getID.bind(this);
  }

  componentDidMount() {
    document.title = 'Un Sync Orders';
    this.fetchDataWithQueryString();
  }

  componentDidUpdate() {
    this.handleOnUpdateQueryString();
  }

  handleOnUpdateQueryString() {
    const { filter, page, sort, orderBy, view } = this.parseQueryString();
    if (filter !== this.state.currentFilter) {
      this.setState(
        {
          currentFilter: filter,
        },
        () => this.getUnSyncOrders()
      );
    }
    if (sort !== this.state.sortFilter) {
      this.setState(
        {
          sortFilter: sort,
        },
        () => this.getUnSyncOrders()
      );
    }
    if (orderBy !== this.state.orderBy) {
      this.setState(
        {
          orderBy: orderBy,
        },
        () => this.getUnSyncOrders()
      );
    }
    if (page !== this.state.currentPage) {
      sessionStorage.setItem('current_page', parseInt(page));
      this.setState(
        {
          currentPage: parseInt(page),
        },
        () => this.getUnSyncOrders()
      );
    }
    if (view !== this.state.viewPerPage) {
      this.handleResetCheckbox(true);
      sessionStorage.setItem('view', parseInt(view));
      this.setState(
        {
          viewPerPage: parseInt(view),
        },
        () => this.getUnSyncOrders()
      );
    }
  }

  fetchDataWithQueryString() {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const { filter, page, sort, orderBy, view, search } =
      this.parseQueryString();
    this.setState(
      {
        sortFilter: sort,
        currentFilter: filter,
        currentPage: state?.currentPage || parseInt(page),
        orderBy: orderBy,
        viewPerPage: state?.viewPerPage || parseInt(view),
        search: search,
      },
      () => {
        this.fetchData();
      }
    );
  }

  parseQueryString() {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const query = queryString.parse(this.props.location.search);
    let filter = query.filter || 'all';
    let sort = query.sort || 'created_at';
    let orderBy = query.orderBy || 'asc';
    let page = state?.currentPage
      ? state?.currentPage
      : parseInt(query.page)
      ? parseInt(query.page)
      : 1;
    let view = state?.viewPerPage
      ? state?.viewPerPage
      : parseInt(query.view)
      ? parseInt(query.view)
      : 20;
    let search = query.search || '';
    return {
      filter,
      page,
      sort,
      orderBy,
      view,
      search,
    };
  }

  getUnSyncOrders = () => {
    this.setState({ loading: true });
    let storeid = this.state.storeId;
    //console.log('this.state.slug', this.state.slug)
    restRequest(
      'get',
      `${this.state.slug}/remaining_orders/${storeid}?view=${this.state.viewPerPage}&page=${this.state.currentPage}`
    )
      .then((response) => {
        let resData = response?.data;
        this.setState({
          dataExpanded: this.prepareDataForTable(
            resData.data,
            response?.currency?.symbol
          ),
          data: this.prepareDataForTable(
            resData?.data,
            response?.currency?.symbol
          ),
          totalRecords: resData?.total,
          totalPage: resData?.last_page,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  fetchData = () => {
    this.setState({ loading: true });
    restRequest('get', 'my_stores?slug=all')
      .then((res) => {
        const dataFromLS = getObjFromLS('store_details');
        const { store_Id = null } = dataFromLS;
        let stores = res?.wordpress?.all_stores?.filter(
          (store) => store.connected === 'connected'
        );
        let selectedStore =
          store_Id && stores.find((store) => store.id === store_Id);
        selectedStore = selectedStore ? selectedStore : stores[0].id;
        if (stores.length) {
          this.setState(
            {
              storeId: dataFromLS?.store_Id ? store_Id : stores[0].id,
              stores: stores,
              slug:
                selectedStore &&
                selectedStore?.slug &&
                selectedStore?.slug === 'woo-commerce'
                  ? 'woocom'
                  : selectedStore.slug === 'shopify'
                  ? 'shopify'
                  : stores[0].slug === 'woo-commerce'
                  ? 'woocom'
                  : 'shopify',
            },
            () => {
              this.getUnSyncOrders();
            }
          );
        } else {
          this.setState({ loading: false });
          this.props.handleToast('No Stores Found', 'error');
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        checkError(error);
        this.props.handleToast('No Stores Found', 'error');
      });
  };

  orderViewHandler = (id) => {
    this.setState({ unSyncOrderId: id }, () => {
      this.props.history.push({
        pathname: `/unsync-item-mapping/${id}/${this.state.storeId}/${this.state.slug}`,
        state: {
          currentPage: this.state.currentPage,
          viewPerPage: this.state.viewPerPage,
        },
      });
    });
  };
  prepareDataForTable(data, symbol) {
    const { currentPage, viewPerPage } = this.state;
    return data.map((salesorder) => ({
      id: salesorder.id,
      ' Date': {
        id: 'date_created',
        val: salesorder?.response?.order?.date_created,
        getValue: function () {
          return moment(this.val).format(getDateFormat());
        },
      },
      Customer: {
        id: 'display_name',
        val: salesorder?.response?.customer?.first_name
          ? salesorder?.response?.customer?.first_name +
            ' ' +
            salesorder?.response?.customer?.last_name
          : salesorder?.response?.billing?.first_name +
            ' ' +
            salesorder?.response?.billing?.last_name,
        getValue: function () {
          return this.val;
        },
      },
      'Order NO': {
        id: 'date_created',
        val: salesorder?.response?.order?.order_number
          ? salesorder?.response?.order?.order_number
          : salesorder?.response?.order?.id,
        getValue: function () {
          return `Order # ${this.val}`;
        },
      },
      'Order Total': {
        id: 'order_total',
        val: salesorder?.order_total,
        getValue: function () {
          return `${symbol}${this.val}`;
        },
      },

      Actions: {
        id: salesorder?.id,
        handler: this.orderViewHandler,
        getValue: function () {
          return (
            <div>
              <button
                className=" text-blue"
                onClick={() => this.handler(this.id)}
              >
                Map
              </button>
              &nbsp;|&nbsp;
              <Link
                to={{
                  pathname: `/unsyncorder/${this.id}/${salesorder.market_place}`,
                  state: {
                    currentPage: currentPage,
                    viewPerPage: viewPerPage,
                  },
                }}
                className="hover-undeline"
              >
                View
              </Link>
            </div>
          );
        },
      },
    }));
  }

  getID(prevId, id, nextId) {
    let obj = {
      id,
      prevId: prevId ? prevId : null,
      nextId: nextId ? nextId : null,
      moduleName: 'salesorders',
      urlPath: window.location.pathname,
      queryParam: window.location.search,
    };
    setObjInLS('module', obj);
    this.setState({ salesOrderId: id });
  }

  handleStoreSelect = (option) => {
    const obj = {
      store_Id: option.id,
      store_name: option.name,
    };
    setObjInLS('store_details', obj);
    option &&
      this.setState(
        {
          storeId: option.id,
          slug: option.slug === 'woo-commerce' ? 'woocom' : option.slug,
        },
        () => {
          this.getUnSyncOrders();
        }
      );
  };

  selectStoreId = () => {
    let allStore = this.state.stores?.map((singleStore) => {
      return {
        ...singleStore,
        storeLabel: singleStore.name || singleStore.store_url,
      };
    });
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            background: '#eaf8ec',
            zIndex: '99999999',
            padding: '18px 10px',
          }}
        >
          <Field
            className="so_feilds p-0"
            size="large"
            type="dropdown"
            name="Select store for finding unsync orders"
            autoFocus={true}
            value={this.state.storeId}
            options={allStore}
            dropdownValue="id"
            dropdownLabel="storeLabel"
            clearable={false}
            handleChange={(option) => this.handleStoreSelect(option)}
          />
        </div>
      </>
    );
  };

  renderTable() {
    const { data, orderBy, loading } = this.state;
    return (
      <>
        {this.state.stores.length !== 0 && this.selectStoreId()}
        <div className="unsync_ordersTable">
          <div className="close_icon close-icon-pos so_cross_icon">
            <MdClose
              onClick={() => {
                this.props.history.push('/salesorders');
              }}
            />
          </div>
          <ListTable
            data={data}
            className={'fullscreen'}
            linkTo="/salesorders"
            orderBy={orderBy}
            loading={loading}
            getID={this.getID}
            moduleName="salesorders"
            hasCheckbox={false}
            totalRecords={this.state.totalRecords}
            mapping
          />
        </div>
      </>
    );
  }

  render() {
    return <Fragment>{this.renderTable()}</Fragment>;
  }
}

export default HeaderWrapper(UnSyncOrders, {
  name: 'Un Sync Orders',
  Icon: SalesOrderIcon,
  baseUrl: 'UnSyncOrders',
  tableIcon: 'general_module_icon',
  deleteName: 'Sales Order',
  redirectUrl: '/salesorders',
  buttonUrl: '/salesorders/add',
  buttonLabel: 'New Sales Order',
  routeName: 'UnSyncOrders',
  showDetailsComp: false,
  bypassLoadingState: false,
  permissionName: 'salesorder',
  showNewButton: false,
  syncHistory: true,
});
